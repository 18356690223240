var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-card", { staticClass: "mt-sm-8 mt-md-5 mt-7 bg-transparent" }, [
    _c("div", { staticClass: "card-padding pb-0" }, [
      _c("h4", { staticClass: "text-h4 font-weight-bolder text-typo mb-2" }, [
        _vm._v("Sign up"),
      ]),
      _c("p", { staticClass: "mb-0 text-body font-weight-light" }, [
        _vm._v(" Enter your email and password to register "),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "card-padding pb-4" },
      [
        _c("v-text-field", {
          staticClass: "font-size-input input-style py-0",
          attrs: { label: "Name", color: "#e91e63", outlined: "", dense: "" },
        }),
        _c("v-text-field", {
          staticClass: "font-size-input input-style py-0",
          attrs: { label: "Email", color: "#e91e63", outlined: "", dense: "" },
        }),
        _c("v-text-field", {
          staticClass: "font-size-input input-style py-0",
          attrs: {
            label: "Password",
            color: "#e91e63",
            outlined: "",
            dense: "",
            type: "password",
          },
        }),
        _c("v-checkbox", {
          staticClass: "ma-0 checkbox-custom checkbox-thinner",
          attrs: { color: "#141727", ripple: false, "hide-details": "" },
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _c("span", { staticClass: "text-body text-body-2 ls-0" }, [
                    _vm._v("I agree the "),
                    _c(
                      "a",
                      {
                        staticClass:
                          "text-dark font-weight-bolder text-decoration-none",
                        attrs: { href: "javascript:;" },
                      },
                      [_vm._v("Terms and Conditions")]
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.checkbox,
            callback: function ($$v) {
              _vm.checkbox = $$v
            },
            expression: "checkbox",
          },
        }),
        _c(
          "v-btn",
          {
            staticClass:
              "\n        font-weight-bold\n        text-uppercase\n        btn-primary\n        bg-gradient-primary\n        py-2\n        px-6\n        me-2\n        mt-7\n        mb-2\n        w-100\n      ",
            attrs: {
              elevation: "0",
              ripple: false,
              height: "43",
              color: "#5e72e4",
              small: "",
            },
          },
          [_vm._v("Sign Up")]
        ),
        _c("div", { staticClass: "text-center" }, [
          _c(
            "p",
            { staticClass: "text-sm text-body font-weight-light mt-3 mb-0" },
            [
              _vm._v(" Already have an account? "),
              _c(
                "a",
                {
                  staticClass:
                    "\n            text-primary text-gradient text-decoration-none\n            font-weight-bold\n          ",
                  attrs: { href: "javascript:;" },
                },
                [_vm._v("Sign in")]
              ),
            ]
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }