var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "py-8 px-6", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { lg: "6" } }, [
                _c(
                  "h4",
                  { staticClass: "text-h4 text-typo font-weight-bold mb-3" },
                  [_vm._v(" Make the changes below ")]
                ),
                _c("p", { staticClass: "text-body font-weight-light" }, [
                  _vm._v(
                    " We’re constantly trying to express ourselves and actualize our dreams. If you have the opportunity to play. "
                  ),
                ]),
              ]),
              _c(
                "v-col",
                { staticClass: "text-end my-auto", attrs: { lg: "6" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass:
                        "\n            font-weight-bold\n            text-uppercase\n            btn-default\n            bg-gradient-primary\n            py-2\n            px-6\n            me-2\n          ",
                      attrs: {
                        elevation: "0",
                        height: "43",
                        color: "#5e72e4",
                        small: "",
                      },
                    },
                    [_vm._v("Save")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { md: "4", cols: "12" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass:
                        "card card-shadow border-radius-xl py-5 text-center mt-6",
                      attrs: { "data-animation": "true" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "mt-n11 mx-4 card-header position-relative z-index-2",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-block blur-shadow-image" },
                            [
                              _c("img", {
                                staticClass:
                                  "img-fluid shadow border-radius-lg",
                                attrs: {
                                  src: require("../../../assets/img/product-11.jpg"),
                                  alt: "",
                                },
                              }),
                            ]
                          ),
                          _c("div", {
                            staticClass: "colored-shadow",
                            staticStyle: {
                              backgroundimage:
                                "'url(' ../../../assets/img/product-11.jpg ')'",
                            },
                          }),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "mt-n12" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "\n                font-weight-bold\n                text-uppercase\n                btn-default\n                bg-gradient-primary\n                py-2\n                px-4\n              ",
                              attrs: {
                                elevation: "0",
                                height: "32",
                                color: "#5e72e4",
                                small: "",
                              },
                            },
                            [_vm._v("Edit")]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "\n                font-weight-bold\n                text-uppercase\n                btn-default btn-outline-default\n                py-2\n                px-4\n                ms-2\n                shadow-none\n              ",
                              attrs: {
                                elevation: "0",
                                height: "32",
                                color: "transparent",
                                small: "",
                              },
                            },
                            [_vm._v("Remove")]
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "mt-6 mx-5" }, [
                        _c(
                          "h5",
                          {
                            staticClass:
                              "text-h5 text-typo font-weight-normal mt-4 mb-2",
                          },
                          [_vm._v(" Product Image ")]
                        ),
                        _c(
                          "p",
                          { staticClass: "mb-0 text-body font-weight-light" },
                          [
                            _vm._v(
                              ' The place is close to Barceloneta Beach and bus stop just 2 min by walk and near to "Naviglio" where you can enjoy the main night life in Barcelona. '
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { md: "8", cols: "12" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "card-shadow border-radius-xl" },
                    [
                      _c(
                        "div",
                        { staticClass: "card-padding" },
                        [
                          _c(
                            "h5",
                            {
                              staticClass:
                                "text-h5 text-typo font-weight-bolder",
                            },
                            [_vm._v(" Product Information ")]
                          ),
                          _c(
                            "v-row",
                            { staticClass: "mt-0" },
                            [
                              _c(
                                "v-col",
                                { attrs: { sm: "6", cols: "12" } },
                                [
                                  _c("v-text-field", {
                                    staticClass: "font-size-input input-style",
                                    attrs: {
                                      label: "Name",
                                      placeholder: "Minimal Bar Stool",
                                      color: "#e91e63",
                                      required: "",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { sm: "6", cols: "12" } },
                                [
                                  _c("v-text-field", {
                                    staticClass: "font-size-input input-style",
                                    attrs: {
                                      label: "Weight",
                                      placeholder: "2",
                                      color: "#e91e63",
                                      required: "",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { staticClass: "mt-0" },
                            [
                              _c(
                                "v-col",
                                { attrs: { sm: "3", cols: "12" } },
                                [
                                  _c("v-text-field", {
                                    staticClass: "font-size-input input-style",
                                    attrs: {
                                      label: "Collection",
                                      placeholder: "Summer",
                                      color: "#e91e63",
                                      required: "",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { sm: "3", cols: "12" } },
                                [
                                  _c("v-text-field", {
                                    staticClass: "font-size-input input-style",
                                    attrs: {
                                      label: "Price",
                                      placeholder: "$90",
                                      color: "#e91e63",
                                      required: "",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { sm: "6", cols: "12" } },
                                [
                                  _c("v-text-field", {
                                    staticClass: "font-size-input input-style",
                                    attrs: {
                                      label: "Quantity",
                                      placeholder: "50",
                                      color: "#e91e63",
                                      required: "",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { sm: "6" } },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "text-body text-sm font-weight-normal ms-1",
                                    },
                                    [_vm._v("Description")]
                                  ),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "form-text text-muted text-xs ms-1 d-inline",
                                    },
                                    [_vm._v(" (optional) ")]
                                  ),
                                  _c("html-editor"),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { sm: "6" } },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "text-sm text-body" },
                                    [_vm._v("Category")]
                                  ),
                                  _c("v-select", {
                                    staticClass:
                                      "\n                    font-size-input\n                    input-style\n                    placeholder-light\n                    border-radius-md\n                    select-style\n                    mb-0\n                  ",
                                    attrs: {
                                      items: _vm.categories,
                                      value: "Furniture",
                                      color: "#e91e63",
                                      "multi-line": "",
                                      height: "36",
                                    },
                                  }),
                                  _c(
                                    "label",
                                    { staticClass: "text-sm text-body" },
                                    [_vm._v("Color")]
                                  ),
                                  _c("v-select", {
                                    staticClass:
                                      "\n                    font-size-input\n                    input-style\n                    placeholder-light\n                    border-radius-md\n                    select-style\n                    mb-0\n                  ",
                                    attrs: {
                                      items: _vm.colors,
                                      value: "Black",
                                      color: "#e91e63",
                                      "multi-line": "",
                                      height: "36",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { lg: "4", cols: "12" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "card-shadow border-radius-xl h-100" },
                    [
                      _c(
                        "div",
                        { staticClass: "card-padding" },
                        [
                          _c(
                            "h5",
                            {
                              staticClass:
                                "text-h5 text-typo font-weight-bolder",
                            },
                            [_vm._v("Socials")]
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("v-text-field", {
                                    staticClass: "font-size-input input-style",
                                    attrs: {
                                      label: "Shopify Handle",
                                      placeholder: "@soft",
                                      color: "#e91e63",
                                      required: "",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { staticClass: "mt-0" },
                            [
                              _c(
                                "v-col",
                                { staticClass: "py-0", attrs: { cols: "12" } },
                                [
                                  _c("v-text-field", {
                                    staticClass: "font-size-input input-style",
                                    attrs: {
                                      label: "Facebook Account",
                                      color: "#e91e63",
                                      required: "",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { staticClass: "mt-0" },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("v-text-field", {
                                    staticClass: "font-size-input input-style",
                                    attrs: {
                                      label: "Instagram Account",
                                      color: "#e91e63",
                                      required: "",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { lg: "8" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "card-shadow border-radius-xl" },
                    [
                      _c(
                        "div",
                        { staticClass: "card-padding" },
                        [
                          _c(
                            "h5",
                            {
                              staticClass:
                                "text-h5 text-typo font-weight-bolder",
                            },
                            [_vm._v("Pricing")]
                          ),
                          _c(
                            "v-row",
                            { staticClass: "mt-2" },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "3" } },
                                [
                                  _c("v-text-field", {
                                    staticClass: "font-size-input input-style",
                                    attrs: {
                                      label: "Price",
                                      placeholder: "99.00",
                                      color: "#e91e63",
                                      required: "",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c("v-text-field", {
                                    staticClass: "font-size-input input-style",
                                    attrs: {
                                      label: "Currency",
                                      placeholder: "USD",
                                      color: "#e91e63",
                                      required: "",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "5" } },
                                [
                                  _c("v-text-field", {
                                    staticClass: "font-size-input input-style",
                                    attrs: {
                                      label: "SKU",
                                      placeholder: "71283476591",
                                      color: "#e91e63",
                                      required: "",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { staticClass: "mt-2" },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "text-sm text-body ms-1" },
                                    [_vm._v("Tags")]
                                  ),
                                  _c("v-select", {
                                    staticClass:
                                      "font-size-input input-style mt-0",
                                    attrs: {
                                      items: _vm.tags,
                                      chips: "",
                                      multiple: "",
                                      height: "46",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "selection",
                                        fn: function (ref) {
                                          var item = ref.item
                                          return [
                                            _c(
                                              "v-chip",
                                              {
                                                staticClass: "py-1 px-2 my-0",
                                                attrs: {
                                                  label: "",
                                                  color: "bg-default",
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-white text-caption ls-0",
                                                  },
                                                  [_vm._v(_vm._s(item))]
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }