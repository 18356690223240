var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "card-shadow border-radius-xl mt-6",
      attrs: { id: "appsettings" },
    },
    [
      _c("div", { staticClass: "px-6 py-6" }, [
        _c("h5", { staticClass: "text-h5 font-weight-bold text-typo" }, [
          _vm._v("App Settings"),
        ]),
        _c("p", { staticClass: "text-sm text-body font-weight-light" }, [
          _vm._v(
            " This section contains the application settings related to the site. Adjust these settings to configure the behavior and preferences of the application specific to your site. "
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "px-6 pb-6 pt-0 overflow-scroll" },
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "12" } }, [
                _c("h6", { staticClass: "text-h6 font-weight-bold mb-4" }, [
                  _vm._v(
                    "High alert settings for the monitoring portal Dashboard"
                  ),
                ]),
              ]),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "4" } },
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Threshold for Sites",
                              readonly: "",
                              dense: "",
                            },
                            model: {
                              value: _vm.highThresholdSites,
                              callback: function ($$v) {
                                _vm.highThresholdSites = $$v
                              },
                              expression: "highThresholdSites",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-slider", {
                            attrs: { min: "0", max: "100" },
                            on: {
                              change: function ($event) {
                                return _vm.handleSliderChange(
                                  "highThresholdSites"
                                )
                              },
                            },
                            model: {
                              value: _vm.highThresholdSites,
                              callback: function ($$v) {
                                _vm.highThresholdSites = $$v
                              },
                              expression: "highThresholdSites",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "4" } },
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Threshold for Inverters",
                              readonly: "",
                              dense: "",
                            },
                            model: {
                              value: _vm.highThresholdInverters,
                              callback: function ($$v) {
                                _vm.highThresholdInverters = $$v
                              },
                              expression: "highThresholdInverters",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-slider", {
                            attrs: { min: "0", max: "100" },
                            on: {
                              change: function ($event) {
                                return _vm.handleSliderChange(
                                  "highThresholdInverters"
                                )
                              },
                            },
                            model: {
                              value: _vm.highThresholdInverters,
                              callback: function ($$v) {
                                _vm.highThresholdInverters = $$v
                              },
                              expression: "highThresholdInverters",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "4" } },
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Threshold for Insite Inverter Comparison",
                              readonly: "",
                              dense: "",
                            },
                            model: {
                              value: _vm.highThresholdInsite,
                              callback: function ($$v) {
                                _vm.highThresholdInsite = $$v
                              },
                              expression: "highThresholdInsite",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-slider", {
                            attrs: { min: "0", max: "100" },
                            on: {
                              change: function ($event) {
                                return _vm.handleSliderChange(
                                  "highThresholdInsite"
                                )
                              },
                            },
                            model: {
                              value: _vm.highThresholdInsite,
                              callback: function ($$v) {
                                _vm.highThresholdInsite = $$v
                              },
                              expression: "highThresholdInsite",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "12" } }, [
                _c("h6", { staticClass: "text-h6 font-weight-bold mb-4" }, [
                  _vm._v(
                    "Medium alert settings for the monitoring portal Dashboard"
                  ),
                ]),
              ]),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "4" } },
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Threshold for Sites",
                              readonly: "",
                              dense: "",
                            },
                            model: {
                              value: _vm.mediumThresholdSites,
                              callback: function ($$v) {
                                _vm.mediumThresholdSites = $$v
                              },
                              expression: "mediumThresholdSites",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-slider", {
                            attrs: { min: "0", max: "100" },
                            on: {
                              change: function ($event) {
                                return _vm.handleSliderChange(
                                  "mediumThresholdSites"
                                )
                              },
                            },
                            model: {
                              value: _vm.mediumThresholdSites,
                              callback: function ($$v) {
                                _vm.mediumThresholdSites = $$v
                              },
                              expression: "mediumThresholdSites",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "4" } },
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Threshold for Inverters",
                              readonly: "",
                              dense: "",
                            },
                            model: {
                              value: _vm.mediumThresholdInverters,
                              callback: function ($$v) {
                                _vm.mediumThresholdInverters = $$v
                              },
                              expression: "mediumThresholdInverters",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-slider", {
                            attrs: { min: "0", max: "100" },
                            on: {
                              change: function ($event) {
                                return _vm.handleSliderChange(
                                  "mediumThresholdInverters"
                                )
                              },
                            },
                            model: {
                              value: _vm.mediumThresholdInverters,
                              callback: function ($$v) {
                                _vm.mediumThresholdInverters = $$v
                              },
                              expression: "mediumThresholdInverters",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "4" } },
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Threshold for Insite Inverter Comparison",
                              readonly: "",
                              dense: "",
                            },
                            model: {
                              value: _vm.mediumThresholdInsite,
                              callback: function ($$v) {
                                _vm.mediumThresholdInsite = $$v
                              },
                              expression: "mediumThresholdInsite",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-slider", {
                            attrs: { min: "0", max: "100" },
                            on: {
                              change: function ($event) {
                                return _vm.handleSliderChange(
                                  "mediumThresholdInsite"
                                )
                              },
                            },
                            model: {
                              value: _vm.mediumThresholdInsite,
                              callback: function ($$v) {
                                _vm.mediumThresholdInsite = $$v
                              },
                              expression: "mediumThresholdInsite",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }