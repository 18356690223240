var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "py-6", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { lg: "6" } },
            [
              _c(
                "v-row",
                _vm._l(_vm.cards, function (card) {
                  return _c(
                    "v-col",
                    { key: card.title, attrs: { md: "6", cols: "12" } },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "mb-3 card-shadow border-radius-xl py-4",
                        },
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "px-4",
                              attrs: { "no-gutters": "" },
                            },
                            [
                              _c(
                                "v-col",
                                { attrs: { sm: "4" } },
                                [
                                  _c(
                                    "v-avatar",
                                    {
                                      staticClass: "shadow-dark",
                                      attrs: {
                                        color:
                                          "bg-gradient-info shadow border-radius-xl mt-n8",
                                        height: "64",
                                        width: "64",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass:
                                            "material-icons-round text-white",
                                          attrs: { size: "24" },
                                        },
                                        [_vm._v(_vm._s(card.icon))]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { staticClass: "text-end", attrs: { sm: "8" } },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "\n                    text-sm\n                    mb-0\n                    text-capitalize text-body\n                    font-weight-light\n                  ",
                                    },
                                    [_vm._v(" " + _vm._s(card.text) + " ")]
                                  ),
                                  _c(
                                    "h4",
                                    {
                                      staticClass:
                                        "text-h4 text-typo font-weight-bolder mb-0",
                                    },
                                    [_vm._v(" " + _vm._s(card.value) + " ")]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("hr", {
                            staticClass: "dark horizontal mt-3 mb-4",
                          }),
                          _c(
                            "v-row",
                            { staticClass: "px-4" },
                            [
                              _c("v-col", { attrs: { cols: "12" } }, [
                                _c("p", { staticClass: "mb-0 text-body" }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "text-success text-sm font-weight-bolder",
                                    },
                                    [_vm._v(_vm._s(card.percent))]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "font-weight-light ms-1" },
                                    [_vm._v("than last week")]
                                  ),
                                ]),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { lg: "6", cols: "12" } },
            [
              _c(
                "v-card",
                { staticClass: "card-shadow border-radius-xl mb-lg-0 mb-5" },
                [
                  _c("div", { staticClass: "px-4 pt-4" }, [
                    _c(
                      "h6",
                      {
                        staticClass: "mb-0 text-h6 text-typo font-weight-bold",
                      },
                      [_vm._v("Reviews")]
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "px-4 pt-4" },
                    [
                      _c(
                        "v-list",
                        [
                          _c(
                            "v-list-item-group",
                            { staticClass: "border-radius-sm" },
                            [
                              _c(
                                "v-list-item",
                                {
                                  staticClass: "px-0 border-radius-sm mb-4",
                                  attrs: { ripple: false },
                                },
                                [
                                  _c(
                                    "v-list-item-content",
                                    { staticClass: "py-0" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "d-flex mb-2" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "\n                        me-2\n                        text-sm\n                        font-weight-normal\n                        text-capitalize text-body\n                      ",
                                            },
                                            [_vm._v("Positive reviews")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "ms-auto text-sm font-weight-normal text-body",
                                            },
                                            [_vm._v("80%")]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        [
                                          _c("v-progress-linear", {
                                            attrs: {
                                              value: "80",
                                              height: "6",
                                              rounded: "",
                                              "background-color": "#e9ecef",
                                              color: "#1a73e8",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item",
                                {
                                  staticClass: "px-0 border-radius-sm mb-4",
                                  attrs: { ripple: false },
                                },
                                [
                                  _c(
                                    "v-list-item-content",
                                    { staticClass: "py-0" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "d-flex mb-2" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "\n                        me-2\n                        text-sm\n                        font-weight-normal\n                        text-capitalize text-body\n                      ",
                                            },
                                            [_vm._v("Neutral Reviews")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "ms-auto text-sm font-weight-normal text-body",
                                            },
                                            [_vm._v("17%")]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        [
                                          _c("v-progress-linear", {
                                            attrs: {
                                              value: "17",
                                              height: "6",
                                              rounded: "",
                                              "background-color": "#e9ecef",
                                              color: "#141727",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item",
                                {
                                  staticClass: "px-0 border-radius-sm mb-4",
                                  attrs: { ripple: false },
                                },
                                [
                                  _c(
                                    "v-list-item-content",
                                    { staticClass: "py-0" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "d-flex mb-2" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "\n                        me-2\n                        text-sm\n                        font-weight-normal\n                        text-capitalize text-body\n                      ",
                                            },
                                            [_vm._v("Negative reviews")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "ms-auto text-sm font-weight-normal text-body",
                                            },
                                            [_vm._v("3%")]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        [
                                          _c("v-progress-linear", {
                                            attrs: {
                                              value: "3",
                                              height: "6",
                                              rounded: "",
                                              "background-color": "#e9ecef",
                                              color: "#e53935",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "px-4 pb-4 d-flex align-center mt-5" },
                    [
                      _c("div", { staticClass: "w-60" }, [
                        _c(
                          "p",
                          {
                            staticClass:
                              "text-sm text-body font-weight-light mb-7",
                          },
                          [
                            _vm._v(" More than "),
                            _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v("1,500,000"),
                            ]),
                            _vm._v(
                              " developers used Creative Tim's products and over "
                            ),
                            _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v("700,000"),
                            ]),
                            _vm._v(" projects were created. "),
                          ]
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "w-40 text-end" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "\n                font-weight-bolder\n                btn-default\n                bg-gradient-default\n                py-5\n                px-6\n              ",
                              attrs: { color: "#cb0c9f", small: "" },
                            },
                            [_vm._v(" View all reviews ")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-0" },
        [_c("v-col", { attrs: { cols: "12" } }, [_c("reports-table")], 1)],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }