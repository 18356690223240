var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "card-shadow border-radius-xl z-index-2" },
    [
      _c(
        "div",
        { staticClass: "px-4 pt-2 pb-0 d-flex" },
        [
          _c(
            "v-avatar",
            {
              staticClass: "shadow",
              attrs: {
                color:
                  "bg-gradient-warning shadow-warning border-radius-xl mt-n6",
                height: "64",
                width: "64",
              },
            },
            [
              _c(
                "v-icon",
                {
                  staticClass: "material-icons-round text-white",
                  attrs: { size: "24" },
                },
                [_vm._v("donut_small")]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "ms-4" }, [
            _c("h6", { staticClass: "text-h6 text-typo font-weight-bold" }, [
              _vm._v("Doughnut chart"),
            ]),
            _c(
              "p",
              { staticClass: "font-weight-light text-secondary text-sm" },
              [_vm._v(" Affiliates program ")]
            ),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "card-padding py-0 d-flex" }, [
        _c("div", { staticClass: "chart w-50 pb-4" }, [
          _c("canvas", {
            staticClass: "chart-canvas",
            attrs: { id: "doughnut-chart", height: "300" },
          }),
        ]),
        _c(
          "div",
          { staticClass: "w-50 my-auto" },
          [
            _c("v-simple-table", {
              staticClass: "table",
              attrs: { height: "250px" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function () {
                    return [
                      _c(
                        "tbody",
                        _vm._l(_vm.affiliates, function (item) {
                          return _c("tr", { key: item.text }, [
                            _c("td", [
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex px-2 py-0 align-center",
                                },
                                [
                                  _c("img", {
                                    staticClass: "w-15",
                                    attrs: { src: item.image },
                                  }),
                                  _c("div", [
                                    _c(
                                      "h6",
                                      {
                                        staticClass:
                                          "mb-0 ms-2 text-sm text-typo font-weight-bold",
                                      },
                                      [_vm._v(" " + _vm._s(item.text) + " ")]
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                            _c(
                              "td",
                              {
                                staticClass: "align-middle text-center text-sm",
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "text-xs font-weight-bold text-body",
                                  },
                                  [_vm._v(" " + _vm._s(item.percent) + "% ")]
                                ),
                              ]
                            ),
                          ])
                        }),
                        0
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }