var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-card", { staticClass: "card-shadow border-radius-xl" }, [
    _c("div", { staticClass: "px-4 pt-4" }, [
      _c("h6", { staticClass: "text-h6 font-weight-bold text-typo" }, [
        _vm._v("الأحداث القادمة"),
      ]),
      _c(
        "p",
        {
          staticClass:
            "text-sm mb-0 text-capitalize font-weight-bold text-body",
        },
        [_vm._v(" انضم ")]
      ),
    ]),
    _c(
      "div",
      { staticClass: "px-4 pt-4 pb-3" },
      [
        _c(
          "v-list",
          [
            _c(
              "v-list-item-group",
              { staticClass: "border-radius-sm" },
              _vm._l(_vm.categories, function (item, i) {
                return _c(
                  "v-list-item",
                  {
                    key: item.icon,
                    staticClass: "px-0 border-radius-sm",
                    class: i != _vm.categories.length - 1 ? " mb-7" : "",
                  },
                  [
                    _c(
                      "v-avatar",
                      {
                        staticClass:
                          "\n              text-white\n              bg-gradient-default\n              border-radius-lg\n              shadow\n              px-2\n              py-2\n              me-4\n            ",
                        attrs: { size: "48" },
                      },
                      [
                        _c(
                          "v-icon",
                          {
                            staticClass: "material-icons-round text-white",
                            attrs: { size: "18" },
                          },
                          [_vm._v(_vm._s(item.icon))]
                        ),
                      ],
                      1
                    ),
                    _c("v-list-item-content", { staticClass: "py-0 me-3" }, [
                      _c("div", { staticClass: "d-flex flex-column" }, [
                        _c(
                          "h6",
                          {
                            staticClass:
                              "mb-1 text-typo text-h6 font-weight-bold",
                          },
                          [_vm._v(" " + _vm._s(item.title) + " ")]
                        ),
                        _c("span", { staticClass: "text-sm text-body" }, [
                          _vm._v(" " + _vm._s(item.description) + " "),
                        ]),
                      ]),
                    ]),
                  ],
                  1
                )
              }),
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }