var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "py-6", attrs: { fluid: "" } },
        [
          _c(
            "div",
            { staticClass: "d-sm-flex justify-between" },
            [
              _c(
                "v-btn",
                {
                  staticClass:
                    "\n          font-weight-bold\n          text-uppercase\n          btn-default\n          bg-gradient-primary\n          py-2\n          px-6\n          me-2\n          mb-sm-0 mb-2\n        ",
                  attrs: {
                    elevation: "0",
                    height: "43",
                    color: "#5e72e4",
                    small: "",
                  },
                },
                [_vm._v("New order")]
              ),
              _c(
                "div",
                { staticClass: "d-flex ms-auto" },
                [
                  _c(
                    "v-menu",
                    {
                      attrs: {
                        transition: "slide-y-transition",
                        "offset-y": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass:
                                        "\n                font-weight-bold\n                text-uppercase\n                btn-default btn-outline-default\n                shadow-none\n                py-2\n                px-6\n                me-2\n              ",
                                      attrs: {
                                        elevation: "0",
                                        height: "43",
                                        color: "transparent",
                                        small: "",
                                      },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _vm._v(" Filters "),
                                  _c("v-icon", [
                                    _vm._v("ni ni-bold-down text-xs ms-1"),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c(
                        "v-list",
                        { staticClass: "py-0" },
                        [
                          _c(
                            "v-list-item",
                            { staticClass: "list-item-hover-active py-2" },
                            [
                              _c(
                                "v-list-item-content",
                                { staticClass: "pa-0" },
                                [
                                  _c(
                                    "v-list-item-title",
                                    {
                                      staticClass:
                                        "text-body-2 ls-0 text-typo font-weight-600 mb-0",
                                    },
                                    [_vm._v(" Status: Paid ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            { staticClass: "list-item-hover-active pb-2" },
                            [
                              _c(
                                "v-list-item-content",
                                { staticClass: "pa-0" },
                                [
                                  _c(
                                    "v-list-item-title",
                                    {
                                      staticClass:
                                        "text-body-2 ls-0 text-typo font-weight-600 mb-0",
                                    },
                                    [_vm._v(" Status: Refunded ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            { staticClass: "list-item-hover-active pb-2" },
                            [
                              _c(
                                "v-list-item-content",
                                { staticClass: "pa-0" },
                                [
                                  _c(
                                    "v-list-item-title",
                                    {
                                      staticClass:
                                        "text-body-2 ls-0 text-typo font-weight-600 mb-0",
                                    },
                                    [_vm._v(" Status: Canceled ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("hr", { staticClass: "horizontal dark" }),
                          _c(
                            "v-list-item",
                            { staticClass: "list-item-hover-active py-2" },
                            [
                              _c(
                                "v-list-item-content",
                                { staticClass: "pa-0" },
                                [
                                  _c(
                                    "v-list-item-title",
                                    {
                                      staticClass:
                                        "text-danger ls-0 font-weight-600 mb-0",
                                    },
                                    [_vm._v(" Remove Filter ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass:
                        "\n            font-weight-bold\n            text-uppercase\n            btn-default btn-outline-default\n            shadow-none\n            py-2\n            px-6\n            me-2\n          ",
                      attrs: {
                        elevation: "0",
                        height: "43",
                        color: "transparent",
                        small: "",
                      },
                    },
                    [
                      _c("v-icon", [_vm._v("ni ni-archive-2 me-1")]),
                      _vm._v(" Export CSV "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "card-shadow border-radius-xl" },
                    [_c("table-orders")],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }