var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "card-shadow border-radius-xl mt-6",
      attrs: { id: "change" },
    },
    [
      _c("div", { staticClass: "px-6 py-6" }, [
        _c("h5", { staticClass: "text-h5 font-weight-bold text-typo" }, [
          _vm._v("Change Password"),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "px-6 pb-6 pt-0" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-text-field", {
                    staticClass: "font-size-input input-style py-0",
                    attrs: {
                      label: "Current password",
                      color: "#e91e63",
                      outlined: "",
                      dense: "",
                      type: "password",
                    },
                  }),
                  _c("v-text-field", {
                    staticClass: "font-size-input input-style py-0",
                    attrs: {
                      label: "New password",
                      color: "#e91e63",
                      outlined: "",
                      dense: "",
                      type: "password",
                    },
                  }),
                  _c("v-text-field", {
                    staticClass: "font-size-input input-style py-0",
                    attrs: {
                      label: "Confirm new password",
                      color: "#e91e63",
                      outlined: "",
                      dense: "",
                      type: "password",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "h5",
            { staticClass: "mt-5 mb-2 text-h5 font-weight-bold text-typo" },
            [_vm._v(" Password requirements ")]
          ),
          _c("p", { staticClass: "text-muted font-weight-light mb-2" }, [
            _vm._v(" Please follow this guide for a strong password: "),
          ]),
          _c(
            "div",
            { staticClass: "d-sm-flex" },
            [
              _c("ul", { staticClass: "text-muted ps-6 mb-0" }, [
                _c("li", [
                  _c("span", { staticClass: "text-sm" }, [
                    _vm._v("One special characters"),
                  ]),
                ]),
                _c("li", [
                  _c("span", { staticClass: "text-sm" }, [
                    _vm._v("Min 6 characters"),
                  ]),
                ]),
                _c("li", [
                  _c("span", { staticClass: "text-sm" }, [
                    _vm._v("One number (2 are recommended)"),
                  ]),
                ]),
                _c("li", [
                  _c("span", { staticClass: "text-sm" }, [
                    _vm._v("Change it often"),
                  ]),
                ]),
              ]),
              _c(
                "v-btn",
                {
                  staticClass:
                    "\n          font-weight-bolder\n          btn-default\n          bg-gradient-default\n          py-4\n          px-8\n          ms-auto\n          mt-sm-auto mt-4\n        ",
                  attrs: { color: "#cb0c9f", small: "" },
                },
                [_vm._v(" Update password ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }