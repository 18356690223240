<template>
  <v-row class="align-center d-flex h-100 mt-md-n16">
    <v-col lg="4" md="7" class="mx-auto">
      <v-card class="card-shadow border-radius-xl py-1">
        <div
          class="
            card-padding
            text-center
            mt-n7
            bg-gradient-primary
            shadow-primary
            border-radius-lg
            mx-4
          "
        >
          <h4 class="text-h4 font-weight-bolder text-white mb-2">Join us today</h4>
          <p class="mb-1 text-white font-weight-light text-sm">
            Enter your email and password to register
          </p>
        </div>
        <v-form class="card-padding text-center" ref="form" v-model="valid" lazy-validation>
          <v-text-field
            v-model="name"
            label="Name"
            color="#e91e63"
            outlined
            dense
            class="font-size-input input-style mb-5"
            :rules="nameRules"
            @blur="validateName"
          ></v-text-field>

          <v-text-field
            label="Customer ID"
            color="#e91e63"
            required
            outlined
            dense
            class="font-size-input input-style mb-5"
            v-model="customerId"
            :rules="[rules.required]"
            @blur="validateCustomer"
          ></v-text-field>

          <v-text-field
            label="Email"
            color="#e91e63"
            outlined
            dense
            class="font-size-input input-style mb-5"
            v-model="email"
            required
            :rules="emailRules"
            @blur="validateEmail"
          ></v-text-field>

          <v-text-field
            label="Password"
            color="#e91e63"
            outlined
            dense
            class="font-size-input input-style mb-5"
            v-model="password"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="passwordRules"
            :type="show1 ? 'text' : 'password'"
            required
            @click:append="show1 = !show1"
            @blur="validatePassword"
          ></v-text-field>

          <v-checkbox
            v-model="checkbox"
            color="#141727"
            class="ma-0 checkbox-custom checkbox-thinner"
            :rules="[rules.checkboxRequired]"
            @change="validateCheckbox"
          >
            <template v-slot:label>
              <span class="text-body text-sm ls-0">
                I agree to the
                <a href="javascript:;" class="text-dark font-weight-bolder text-decoration-none">
                  Terms and Conditions
                </a>
              </span>
            </template>
          </v-checkbox>

          <v-btn
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-uppercase
              btn-default
              bg-gradient-primary
              py-2
              px-6
              me-2
              mt-6
              mb-2
              w-100
            "
            color="#5e72e4"
            small
            @click="submitForm"
            :disabled="!valid || !checkbox"
          >
            Create account
          </v-btn>

          <p class="text-body font-weight-light mt-3 mb-0">
            Already have an account?
            <a href="/sign-in" class="text-primary text-decoration-none font-weight-bolder">
              Sign in
            </a>
          </p>
        </v-form>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Vue from "vue";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-default.css";
import Service from "@/services/Service.js";

Vue.use(VueToast);

export default {
  data() {
    return {
      checkbox: false,
      show1: false,
      customers: [],
      customerId: null,
      name: '',
      email: '',
      password: '',
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail must be valid',
      ],
      passwordRules: [
        (v) => !!v || 'Password is required',
        (v) => /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/.test(v) || 'Password must contain at least one lowercase letter, one number, a special character, and one uppercase letter',
      ],
      rules: {
        required: (value) => !!value || 'Required.',
        checkboxRequired: (value) => value || 'You must agree to the terms and conditions',
      },
      valid: false,
    };
  },
  created() {
    this.getCustomers();
  },
  methods: {
    getCustomers() {
      Service.getCustomers()
        .then((response) => {
          this.customers = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    validateName() {
      this.$refs.form.validate();
    },
    validateCustomer() {
      this.$refs.form.validate();
    },
    validateEmail() {
      this.$refs.form.validate();
    },
    validatePassword() {
      this.$refs.form.validate();
    },
    validateCheckbox() {
      this.$refs.form.validate();
    },
    submitForm() {
      if (this.$refs.form.validate() && this.checkbox) {
        const user_data = {
          user: {
            email: this.email,
            name: this.name,
            password: this.password,
            customer_id: this.customerId,
          },
        };
        // eslint-disable-next-line no-undef
        NProgress.start();
        Service.register(user_data)
          .then((response) => {
            console.log(response.data);
            // eslint-disable-next-line no-undef
            NProgress.done();
            Vue.$toast.success("Email verification required. Please check your email", {
              position: "top-right",
              duration: 5000,
            });
            this.$router.push("/sign-in");
          })
          .catch((error) => {
            const errorMessage =
              error.response && error.response.data && error.response.data.error
                ? error.response.data.error
                : "An error occurred during registration";
            Vue.$toast.error(errorMessage, {
              position: "top-right",
              duration: 5000,
            });
            console.log("Error with user registration:", error.response);
            // eslint-disable-next-line no-undef
            NProgress.done();
          });
      }
    },
  },
};
</script>
