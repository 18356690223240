var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "px-6 py-6", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { lg: "4", md: "7" } },
                [_c("categories-card")],
                1
              ),
              _c(
                "v-col",
                { attrs: { lg: "8" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "card-shadow border-radius-xl h-100" },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex pa-4" },
                        [
                          _c(
                            "v-avatar",
                            {
                              staticClass:
                                "\n                text-white\n                bg-gradient-primary\n                shadow\n                border-radius-lg\n                px-2\n                py-2\n                me-4\n              ",
                              attrs: { size: "48" },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass:
                                    "material-icons-round text-white",
                                  attrs: { size: "18" },
                                },
                                [_vm._v("today")]
                              ),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "me-3" }, [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "text-sm mb-0 text-capitalize text-body",
                              },
                              [_vm._v("مهام")]
                            ),
                            _c(
                              "h5",
                              {
                                staticClass:
                                  "font-weight-bolder text-h5 text-typo mb-0",
                              },
                              [_vm._v("480")]
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "ms-auto w-20 text-start" },
                            [
                              _c("v-list-item-content", [
                                _c("div", { staticClass: "d-flex mb-1" }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "text-xs font-weight-normal text-body",
                                    },
                                    [_vm._v("60%")]
                                  ),
                                ]),
                                _c(
                                  "div",
                                  [
                                    _c("v-progress-linear", {
                                      attrs: {
                                        value: "60",
                                        height: "6",
                                        rounded: "",
                                        "background-color": "#e9ecef",
                                        color: "#d81b60",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "chart" }, [
                        _c("canvas", {
                          staticClass: "chart-canvas",
                          attrs: { id: "widgetsChart", height: "200" },
                        }),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { lg: "3" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "card-shadow border-radius-xl mb-6 pa-1" },
                    [
                      _c(
                        "v-row",
                        { staticClass: "py-0 ma-0" },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "4" } },
                            [
                              _c(
                                "v-avatar",
                                {
                                  staticClass: "shadow border-radius-lg",
                                  attrs: {
                                    color: "bg-gradient-primary",
                                    width: "64",
                                    height: "64",
                                    "min-width": "64",
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass:
                                        "text-white material-icons-round",
                                      attrs: { size: "24" },
                                    },
                                    [_vm._v(" battery_charging_full ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "my-auto text-end",
                              attrs: { cols: "8" },
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "text-sm mb-0 opacity-7 text-body",
                                },
                                [_vm._v("صحة البطارية")]
                              ),
                              _c(
                                "h5",
                                {
                                  staticClass:
                                    "text-h5 text-typo font-weight-bolder mb-0",
                                },
                                [_vm._v("99 %")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card",
                    { staticClass: "card-shadow border-radius-xl pa-1" },
                    [
                      _c(
                        "v-row",
                        { staticClass: "py-0 ma-0" },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "4" } },
                            [
                              _c(
                                "v-avatar",
                                {
                                  staticClass: "shadow border-radius-lg",
                                  attrs: {
                                    color: "bg-gradient-primary",
                                    width: "64",
                                    height: "64",
                                    "min-width": "64",
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass:
                                        "text-white material-icons-round",
                                      attrs: { size: "24" },
                                    },
                                    [_vm._v(" volume_down ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "my-auto text-end",
                              attrs: { cols: "8" },
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "text-sm mb-0 opacity-7 text-body",
                                },
                                [_vm._v(" طبقة صوت الموسيقا ")]
                              ),
                              _c(
                                "h5",
                                {
                                  staticClass:
                                    "text-h5 text-typo font-weight-bolder mb-0",
                                },
                                [_vm._v(" 30/100 ")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { lg: "5" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { md: "6" } },
                        [
                          _c(
                            "v-card",
                            { staticClass: "card-shadow border-radius-xl" },
                            [
                              _c(
                                "div",
                                { staticClass: "mx-6 pa-4 text-center" },
                                [
                                  _c(
                                    "v-avatar",
                                    {
                                      staticClass: "shadow border-radius-lg",
                                      attrs: {
                                        color: "bg-gradient-primary",
                                        width: "64",
                                        height: "64",
                                        "min-width": "64",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass:
                                            "text-white material-icons-round",
                                          attrs: { size: "24" },
                                        },
                                        [_vm._v(" account_balance ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "px-4 pb-4 text-center" },
                                [
                                  _c(
                                    "h6",
                                    {
                                      staticClass:
                                        "text-center text-h6 mb-0 text-typo font-weight-bold",
                                    },
                                    [_vm._v(" مرتب ")]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "text-xs text-body" },
                                    [_vm._v("تنتمي التفاعلية")]
                                  ),
                                  _c("hr", {
                                    staticClass: "horizontal dark my-4",
                                  }),
                                  _c(
                                    "h5",
                                    {
                                      staticClass:
                                        "mb-0 text-h5 font-weight-bold text-typo",
                                    },
                                    [_vm._v(" +$2000 ")]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "6" } },
                        [
                          _c(
                            "v-card",
                            { staticClass: "card-shadow border-radius-xl" },
                            [
                              _c(
                                "div",
                                { staticClass: "mx-6 pa-4 text-center" },
                                [
                                  _c(
                                    "v-avatar",
                                    {
                                      staticClass: "shadow border-radius-lg",
                                      attrs: {
                                        color: "bg-gradient-primary",
                                        width: "64",
                                        height: "64",
                                        "min-width": "64",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass:
                                            "text-white material-icons-round",
                                          attrs: { size: "24" },
                                        },
                                        [_vm._v(" account_balance_wallet ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "px-4 pb-4 text-center" },
                                [
                                  _c(
                                    "h6",
                                    {
                                      staticClass:
                                        "text-center text-h6 mb-0 text-typo font-weight-bold",
                                    },
                                    [_vm._v(" باي بال ")]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "text-xs text-body" },
                                    [_vm._v("دفع لحسابهم الخاص")]
                                  ),
                                  _c("hr", {
                                    staticClass: "horizontal dark my-4",
                                  }),
                                  _c(
                                    "h5",
                                    {
                                      staticClass:
                                        "mb-0 text-h5 font-weight-bold text-typo",
                                    },
                                    [_vm._v(" $455.00 ")]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { lg: "4" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "card-shadow border-radius-xl" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "overflow-hidden position-relative border-radius-xl",
                        },
                        [
                          _c("img", {
                            staticClass:
                              "\n                position-absolute\n                opacity-2\n                start-0\n                top-0\n                w-100\n                z-index-1\n                h-100\n              ",
                            attrs: {
                              src: require("@/assets/img/illustrations/pattern-tree.svg"),
                            },
                          }),
                          _c("span", {
                            staticClass: "mask bg-gradient-default opacity-10",
                          }),
                          _c(
                            "div",
                            { staticClass: "px-4 py-4 position-relative" },
                            [
                              _c("i", {
                                staticClass: "fas fa-wifi text-white pa-2",
                                attrs: { "aria-hidden": "true" },
                              }),
                              _c(
                                "h5",
                                {
                                  staticClass:
                                    "text-white mt-6 mb-12 pb-2 text-h5",
                                },
                                [_vm._v(" 4562   1122   4594   7852 ")]
                              ),
                              _c("div", { staticClass: "d-flex" }, [
                                _c("div", { staticClass: "d-flex" }, [
                                  _c("div", { staticClass: "me-4" }, [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "\n                        text-white text-sm\n                        font-weight-light\n                        opacity-8\n                        mb-0\n                      ",
                                      },
                                      [_vm._v(" حامل البطاقة ")]
                                    ),
                                    _c(
                                      "h6",
                                      {
                                        staticClass: "text-white text-h6 mb-0",
                                      },
                                      [_vm._v("جاك بيترسون")]
                                    ),
                                  ]),
                                  _c("div", [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "\n                        text-white text-sm\n                        font-weight-light\n                        opacity-8\n                        mb-0\n                      ",
                                      },
                                      [_vm._v(" نتهي ")]
                                    ),
                                    _c(
                                      "h6",
                                      {
                                        staticClass: "text-white text-h6 mb-0",
                                      },
                                      [_vm._v("11/22")]
                                    ),
                                  ]),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "\n                    ms-auto\n                    w-10\n                    d-flex\n                    align-items-end\n                    justify-content-end\n                    mt-auto\n                  ",
                                  },
                                  [
                                    _c("v-img", {
                                      attrs: {
                                        src: require("@/assets/img/logos/mastercard.png"),
                                        alt: "logo",
                                        width: "22",
                                        height: "22",
                                        contain: "",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { lg: "3", md: "6" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "pa-4 card-shadow border-radius-xl h-100" },
                    [
                      _c("div", { staticClass: "d-flex" }, [
                        _c("p", { staticClass: "text-body" }, [
                          _vm._v("جسم كامل"),
                        ]),
                        _c(
                          "div",
                          { staticClass: "ms-auto" },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass:
                                  "\n                  border-radius-md\n                  font-weight-bolder\n                  px-3\n                  py-3\n                  badge-font-size\n                  ms-auto\n                  text-xs text-primary\n                ",
                                attrs: {
                                  elevation: "0",
                                  small: "",
                                  ripple: false,
                                  height: "21",
                                  color: "#f8b3ca",
                                },
                              },
                              [_vm._v("معتدل")]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("p", { staticClass: "mb-0 text-body" }, [
                        _vm._v(
                          " ما يهم هو الأشخاص الذين أوقدوه. والناس الذين يشبهونهم مستاءون منه. "
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { lg: "2", md: "4" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "card-shadow border-radius-xl pa-5 h-100" },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "v-avatar",
                            {
                              staticClass: "shadow border-radius-lg",
                              attrs: {
                                color: "bg-gradient-primary",
                                width: "48",
                                height: "48",
                                "min-width": "48",
                              },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass:
                                    "text-white material-icons-round",
                                  attrs: { size: "20" },
                                },
                                [_vm._v(" refresh ")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "h5",
                            {
                              staticClass:
                                "text-h5 text-typo font-weight-bold mt-3 mb-0",
                            },
                            [
                              _vm._v(" 754 "),
                              _c(
                                "span",
                                { staticClass: "text-secondary text-sm" },
                                [_vm._v("م")]
                              ),
                            ]
                          ),
                          _c("p", { staticClass: "text-body mb-1" }, [
                            _vm._v("مدينة نيويورك"),
                          ]),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { lg: "3", md: "4" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "card-shadow border-radius-xl h-100" },
                    [
                      _c("div", { staticClass: "px-4 pt-4" }, [
                        _c(
                          "h6",
                          {
                            staticClass:
                              "text-body text-h6 font-weight-normal mb-0",
                          },
                          [_vm._v(" سعرات حراريه ")]
                        ),
                        _c(
                          "h5",
                          {
                            staticClass:
                              "font-weight-bolder text-typo text-h5 mb-0",
                          },
                          [
                            _vm._v(" 97 "),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "text-success text-sm font-weight-bolder",
                              },
                              [_vm._v("+5%")]
                            ),
                          ]
                        ),
                      ]),
                      _c("div", [
                        _c("div", { staticClass: "chart" }, [
                          _c("canvas", {
                            staticClass: "chart-canvas",
                            attrs: { id: "lineChart", height: "100" },
                          }),
                        ]),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { lg: "2", md: "4" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "pa-4 card-shadow border-radius-xl h-100" },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex mb-4" },
                        [
                          _c(
                            "p",
                            { staticClass: "mb-0 text-body font-weight-light" },
                            [_vm._v("على")]
                          ),
                          _c("v-switch", {
                            staticClass:
                              "d-inline-flex mt-0 pt-0 switch ms-auto",
                            attrs: { ripple: false, "hide-details": "" },
                            model: {
                              value: _vm.switch1,
                              callback: function ($$v) {
                                _vm.switch1 = $$v
                              },
                              expression: "switch1",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("v-img", {
                        staticClass: "mt-6",
                        attrs: {
                          src: require("../../assets/img/small-logos/icon-bulb.svg"),
                          width: "40",
                        },
                      }),
                      _c(
                        "p",
                        {
                          staticClass: "mt-2 mb-0 text-body font-weight-normal",
                        },
                        [_vm._v("درجة حرارة")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { lg: "2", md: "4" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "card-shadow border-radius-xl pa-5 h-100" },
                    [
                      _c(
                        "div",
                        [
                          _c("p", { staticClass: "text-body" }, [
                            _vm._v("خطوات"),
                          ]),
                          _c(
                            "h3",
                            {
                              staticClass:
                                "text-h3 mb-2 text-typo font-weight-bold",
                            },
                            [_vm._v("11.4ك")]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "\n                border-radius-md\n                font-weight-bolder\n                px-3\n                py-3\n                badge-font-size\n                ms-auto\n                text-success\n              ",
                              attrs: {
                                elevation: "0",
                                small: "",
                                ripple: false,
                                height: "21",
                                color: "#bce2be",
                              },
                            },
                            [_vm._v("+4.3%")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { lg: "5" } }, [_c("calendar")], 1),
              _c(
                "v-col",
                { attrs: { lg: "3" } },
                [
                  _c("categories-card-dashboard"),
                  _c(
                    "v-card",
                    {
                      staticClass: "border-radius-xl mt-6",
                      style:
                        "background-image: url('https://images.unsplash.com/photo-1604213410393-89f141bb96b8?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTA5fHxuYXR1cmV8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60'); background-size: cover;",
                    },
                    [
                      _c("span", {
                        staticClass:
                          "mask bg-gradient-default border-radius-xl opacity-8",
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "px-6 py-6 z-index-2 position-relative",
                        },
                        [
                          _c(
                            "h5",
                            {
                              staticClass:
                                "text-white text-h5 font-weight-bold text-start mb-0",
                            },
                            [_vm._v(" نوع من البلوز ")]
                          ),
                          _c(
                            "p",
                            {
                              staticClass:
                                "text-white text-sm font-weight-light text-start",
                            },
                            [_vm._v(" ديفتونز ")]
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex mt-10 text-start" },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function (ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    icon: "",
                                                    outlined: "",
                                                    rounded: "",
                                                    color: "#fff",
                                                    width: "45px",
                                                    height: "45px",
                                                  },
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass:
                                                    "material-icons-round",
                                                  attrs: { size: "18" },
                                                },
                                                [_vm._v("skip_previous")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                },
                                [_c("span", [_vm._v("Prev")])]
                              ),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function (ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass: "mx-2",
                                                  attrs: {
                                                    icon: "",
                                                    outlined: "",
                                                    rounded: "",
                                                    color: "#fff",
                                                    width: "45px",
                                                    height: "45px",
                                                  },
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass:
                                                    "material-icons-round",
                                                  attrs: { size: "18" },
                                                },
                                                [_vm._v("play_arrow")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                },
                                [_c("span", [_vm._v("Pause")])]
                              ),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function (ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    icon: "",
                                                    outlined: "",
                                                    rounded: "",
                                                    color: "#fff",
                                                    width: "45px",
                                                    height: "45px",
                                                  },
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass:
                                                    "material-icons-round",
                                                  attrs: { size: "18" },
                                                },
                                                [_vm._v("skip_next")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                },
                                [_c("span", [_vm._v("Next")])]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { lg: "4" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "card-shadow border-radius-xl mb-30 h-100" },
                    [
                      _c("div", { staticClass: "card-header-padding" }, [
                        _c(
                          "h6",
                          {
                            staticClass:
                              "font-weight-bold text-h6 text-typo mb-2",
                          },
                          [_vm._v(" نظرة عامة على الطلبات ")]
                        ),
                        _c(
                          "p",
                          { staticClass: "text-sm text-body" },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass:
                                  "material-icons-round text-sm text-success",
                                attrs: { "aria-hidden": "true" },
                              },
                              [_vm._v("north")]
                            ),
                            _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v("24%"),
                            ]),
                            _vm._v(" هذا الشهر "),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "v-card-text",
                        { staticClass: "card-padding px-0 pt-0" },
                        [
                          _c(
                            "v-timeline",
                            {
                              staticClass: "timeline-line-color pt-0",
                              attrs: { dense: "", "align-top": "" },
                            },
                            _vm._l(_vm.timeline, function (item, i) {
                              return _c(
                                "v-timeline-item",
                                {
                                  key: item.title + i,
                                  staticClass: "timeline",
                                  attrs: { small: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "icon",
                                        fn: function () {
                                          return [
                                            _c(
                                              "v-avatar",
                                              {
                                                attrs: {
                                                  size: "30",
                                                  color: "#ffffff",
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    class:
                                                      "material-icons-round text-white bg-" +
                                                      item.btn,
                                                    attrs: { size: "14" },
                                                  },
                                                  [_vm._v(_vm._s(item.icon))]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      staticClass: "mt-n1",
                                      attrs: { width: "480" },
                                    },
                                    [
                                      _c(
                                        "v-card-title",
                                        {
                                          staticClass: "px-0 pt-0 pb-1 d-block",
                                        },
                                        [
                                          _c(
                                            "h6",
                                            {
                                              staticClass:
                                                "text-dark text-sm font-weight-bold mb-0 d-block",
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.title) + " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "text-secondary font-weight-light text-xs mb-0",
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.date) + " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }