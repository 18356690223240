var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "px-6 py-6", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { staticClass: "mt-8" },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [_c("datatable-simple")],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [_c("datatable-search")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }