var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dropzone mb-3 mt-3 dz-clickable",
      class: [_vm.multiple ? "dropzone-multiple" : "dropzone-single"],
    },
    [
      _c("div", { staticClass: "fallback" }, [
        _c("div", { staticClass: "custom-file" }, [
          _c("input", {
            staticClass: "custom-file-input",
            attrs: {
              type: "file",
              id: "projectCoverUploads",
              multiple: _vm.multiple,
            },
          }),
          _c(
            "label",
            {
              staticClass: "custom-file-label",
              attrs: { for: "projectCoverUploads" },
            },
            [_vm._v("Choose file")]
          ),
        ]),
      ]),
      !_vm.multiple
        ? _c(
            "div",
            {
              ref: "previewSingle",
              staticClass: "dz-preview dz-preview-single",
              class: _vm.previewClasses,
            },
            [_vm._m(0)]
          )
        : _c(
            "ul",
            {
              ref: "previewMultiple",
              staticClass:
                "\n      dz-preview dz-preview-multiple\n      list-group list-group-lg list-group-flush\n    ",
              class: _vm.previewClasses,
            },
            [_vm._m(1)]
          ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "dz-preview-cover" }, [
      _c("img", {
        staticClass: "dz-preview-img",
        attrs: { "data-dz-thumbnail": "" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "list-group-item px-0" }, [
      _c("div", { staticClass: "row align-items-center" }, [
        _c("div", { staticClass: "col-auto" }, [
          _c("div", { staticClass: "avatar" }, [
            _c("img", {
              staticClass: "avatar-img rounded",
              attrs: { "data-dz-thumbnail": "" },
            }),
          ]),
        ]),
        _c("div", { staticClass: "col ml--3" }, [
          _c("h4", { staticClass: "mb-1", attrs: { "data-dz-name": "" } }, [
            _vm._v("..."),
          ]),
          _c(
            "p",
            {
              staticClass: "small text-muted mb-0",
              attrs: { "data-dz-size": "" },
            },
            [_vm._v("...")]
          ),
        ]),
        _c("div", { staticClass: "col-auto" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-danger btn-sm",
              attrs: { "data-dz-remove": "true" },
            },
            [_c("i", { staticClass: "fas fa-trash" })]
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }