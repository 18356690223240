var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "py-6", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticClass: "position-relative",
              attrs: { lg: "4", md: "6", cols: "12" },
            },
            [
              _c("v-card", { staticClass: "h-100" }, [
                _c("div", { staticClass: "pt-4" }, [
                  _c("h6", { staticClass: "mb-0 text-h6 text-typo" }, [
                    _vm._v("Platform Settings"),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "py-4" },
                  [
                    _c(
                      "h6",
                      {
                        staticClass:
                          "text-uppercase text-body text-xs font-weight-bolder",
                      },
                      [_vm._v(" Account ")]
                    ),
                    _c(
                      "v-list",
                      [
                        _c(
                          "v-list-item-group",
                          { staticClass: "border-radius-sm" },
                          _vm._l(_vm.accountSettings, function (setting) {
                            return _c(
                              "v-list-item",
                              {
                                key: setting.text,
                                staticClass: "pe-2 ps-0 border-radius-sm mb-3",
                                attrs: { ripple: false },
                              },
                              [
                                _c("v-switch", {
                                  staticClass:
                                    "d-inline-flex mt-0 pt-0 switch ms-auto",
                                  attrs: { ripple: false, "hide-details": "" },
                                  model: {
                                    value: setting.switchState,
                                    callback: function ($$v) {
                                      _vm.$set(setting, "switchState", $$v)
                                    },
                                    expression: "setting.switchState",
                                  },
                                }),
                                _c(
                                  "v-list-item-content",
                                  { staticClass: "py-0" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "ms-4 text-body text-sm" },
                                      [_vm._v(" " + _vm._s(setting.text) + " ")]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "h6",
                      {
                        staticClass:
                          "text-uppercase text-body text-xs font-weight-bolder mt-4",
                      },
                      [_vm._v(" Application ")]
                    ),
                    _c(
                      "v-list",
                      [
                        _c(
                          "v-list-item-group",
                          { staticClass: "border-radius-sm" },
                          _vm._l(_vm.applicationSettings, function (setting) {
                            return _c(
                              "v-list-item",
                              {
                                key: setting.text,
                                staticClass: "px-0 border-radius-sm mb-3",
                                attrs: { ripple: false },
                              },
                              [
                                _c("v-switch", {
                                  staticClass:
                                    "d-inline-flex mt-0 pt-0 switch ms-auto",
                                  attrs: { ripple: false, "hide-details": "" },
                                  model: {
                                    value: setting.switchState,
                                    callback: function ($$v) {
                                      _vm.$set(setting, "switchState", $$v)
                                    },
                                    expression: "setting.switchState",
                                  },
                                }),
                                _c(
                                  "v-list-item-content",
                                  { staticClass: "py-0" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "ms-4 text-body text-sm" },
                                      [_vm._v(" " + _vm._s(setting.text) + " ")]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("hr", { staticClass: "vertical dark" }),
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "position-relative",
              attrs: { lg: "4", md: "6", cols: "12" },
            },
            [
              _c("v-card", { staticClass: "h-100" }, [
                _c("div", { staticClass: "px-4 pt-4" }, [
                  _c("h6", { staticClass: "mb-0 text-h6 text-typo" }, [
                    _vm._v("Profile Information"),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "px-4 py-4" },
                  [
                    _c(
                      "p",
                      { staticClass: "text-sm font-weight-light text-body" },
                      [
                        _vm._v(
                          " Hi, I’m Alec Thompson, Decisions: If you can’t decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality). "
                        ),
                      ]
                    ),
                    _c("hr", { staticClass: "horizontal dark mt-6 mb-3" }),
                    _c(
                      "v-list",
                      [
                        _c(
                          "v-list-item-group",
                          { staticClass: "border-radius-sm" },
                          [
                            _c(
                              "v-list-item",
                              {
                                staticClass: "px-0 border-radius-sm",
                                attrs: { ripple: false },
                              },
                              [
                                _c(
                                  "v-list-item-content",
                                  { staticClass: "py-0" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "text-body text-sm" },
                                      [
                                        _c(
                                          "strong",
                                          { staticClass: "text-dark" },
                                          [_vm._v("Full Name:")]
                                        ),
                                        _vm._v("   Alec M. Thompson "),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-list-item",
                              {
                                staticClass: "px-0 border-radius-sm",
                                attrs: { ripple: false },
                              },
                              [
                                _c(
                                  "v-list-item-content",
                                  { staticClass: "py-0" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "text-body text-sm" },
                                      [
                                        _c(
                                          "strong",
                                          { staticClass: "text-dark" },
                                          [_vm._v("Mobile:")]
                                        ),
                                        _vm._v("   (44) 123 1234 123 "),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-list-item",
                              {
                                staticClass: "px-0 border-radius-sm",
                                attrs: { ripple: false },
                              },
                              [
                                _c(
                                  "v-list-item-content",
                                  { staticClass: "py-0" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "text-body text-sm" },
                                      [
                                        _c(
                                          "strong",
                                          { staticClass: "text-dark" },
                                          [_vm._v("Email:")]
                                        ),
                                        _vm._v("   alecthompson@mail.com "),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-list-item",
                              {
                                staticClass: "px-0 border-radius-sm",
                                attrs: { ripple: false },
                              },
                              [
                                _c(
                                  "v-list-item-content",
                                  { staticClass: "py-0" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "text-body text-sm" },
                                      [
                                        _c(
                                          "strong",
                                          { staticClass: "text-dark" },
                                          [_vm._v("Location:")]
                                        ),
                                        _vm._v("   USA "),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-list-item",
                              {
                                staticClass: "px-0 border-radius-sm",
                                attrs: { ripple: false },
                              },
                              [
                                _c(
                                  "v-list-item-content",
                                  { staticClass: "py-0" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "text-body text-sm" },
                                      [
                                        _c(
                                          "strong",
                                          { staticClass: "text-dark" },
                                          [_vm._v("Social:")]
                                        ),
                                        _vm._v("   "),
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "mt-n1 ps-1 pe-2",
                                            attrs: { color: "#344e86" },
                                          },
                                          [_vm._v("fab fa-facebook fa-lg")]
                                        ),
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "mt-n1 ps-1 pe-2",
                                            attrs: { color: "#3ea1ec" },
                                          },
                                          [_vm._v("fab fa-twitter fa-lg")]
                                        ),
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "mt-n1 ps-1 pe-2",
                                            attrs: { color: "#0e456d" },
                                          },
                                          [_vm._v("fab fa-instagram fa-lg")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("hr", { staticClass: "vertical dark" }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { lg: "4", cols: "12" } },
            [
              _c("v-card", { staticClass: "h-100" }, [
                _c("div", { staticClass: "px-4 pt-4" }, [
                  _c("h6", { staticClass: "mb-0 text-h6 text-typo" }, [
                    _vm._v("Conversations"),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "px-4 py-4" },
                  [
                    _c(
                      "v-list",
                      [
                        _c(
                          "v-list-item-group",
                          { staticClass: "border-radius-sm" },
                          _vm._l(_vm.conversations, function (conversation) {
                            return _c(
                              "v-list-item",
                              {
                                key: conversation.text,
                                staticClass: "px-0 border-radius-sm mb-2",
                                attrs: { ripple: false },
                              },
                              [
                                _c(
                                  "v-avatar",
                                  {
                                    staticClass: "shadow border-radius-lg me-4",
                                    attrs: { width: "48", height: "48" },
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "border-radius-lg",
                                      attrs: {
                                        src: conversation.avatar,
                                        alt: "Avatar",
                                      },
                                    }),
                                  ]
                                ),
                                _c("v-list-item-content", [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex align-center" },
                                    [
                                      _c("div", [
                                        _c(
                                          "h6",
                                          {
                                            staticClass:
                                              "\n                          mb-0\n                          text-sm text-sm text-typo\n                          font-weight-bold\n                        ",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(conversation.user) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "mb-0 text-xs text-body font-weight-light",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(conversation.message) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "ms-auto" },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "pe-4 ps-0 mb-0 text-primary font-weight-bolder",
                                              attrs: {
                                                ripple: false,
                                                small: "",
                                                text: "",
                                                width: "auto",
                                              },
                                            },
                                            [_vm._v(" Reply ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("v-card", [
                _c("div", { staticClass: "px-4 pt-4" }, [
                  _c(
                    "h6",
                    { staticClass: "mb-1 text-typo text-h6 font-weight-bold" },
                    [_vm._v("Projects")]
                  ),
                  _c("p", { staticClass: "text-sm text-body" }, [
                    _vm._v("Architects design houses"),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "px-4 py-4" },
                  [
                    _c(
                      "v-row",
                      _vm._l(_vm.cards, function (card) {
                        return _c(
                          "v-col",
                          { key: card.title, attrs: { lg: "3", md: "6" } },
                          [
                            _c("v-card", [
                              _c("div", { staticClass: "position-relative" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "d-block shadow-xl border-radius-xl",
                                  },
                                  [
                                    _c("v-img", {
                                      staticClass: "shadow border-radius-xl",
                                      attrs: { src: card.image },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("div", { staticClass: "px-1 pt-6" }, [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "text-body font-weight-light mb-0 text-sm",
                                  },
                                  [_vm._v(" " + _vm._s(card.title) + " ")]
                                ),
                                _c(
                                  "a",
                                  {
                                    staticClass: "text-decoration-none",
                                    attrs: { href: "javascript:;" },
                                  },
                                  [
                                    _c(
                                      "h5",
                                      {
                                        staticClass:
                                          "text-h5 font-weight-bold text-typo mb-2",
                                      },
                                      [_vm._v(" " + _vm._s(card.style) + " ")]
                                    ),
                                  ]
                                ),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "mb-6 text-sm text-body font-weight-light",
                                  },
                                  [_vm._v(" " + _vm._s(card.description) + " ")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex align-center justify-space-between",
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass:
                                          "font-weight-bolder py-4 px-5",
                                        attrs: {
                                          outlined: "",
                                          color: "#e91e63",
                                          small: "",
                                        },
                                      },
                                      [_vm._v(" View Project ")]
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "avatar-group d-flex" },
                                      _vm._l(card.avatars, function (avatar) {
                                        return _c(
                                          "v-tooltip",
                                          {
                                            key: avatar.name,
                                            attrs: {
                                              bottom: "",
                                              color: "#212529",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function (ref) {
                                                    var on = ref.on
                                                    var attrs = ref.attrs
                                                    return [
                                                      _c(
                                                        "v-avatar",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              staticClass:
                                                                "border border-white ms-n3",
                                                              attrs: {
                                                                size: "24",
                                                              },
                                                            },
                                                            "v-avatar",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src: avatar.image,
                                                              alt: "Avatar",
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(avatar.name)),
                                            ]),
                                          ]
                                        )
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }