var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "card-shadow border-radius-xl mt-6", attrs: { id: "2fa" } },
    [
      _c(
        "div",
        { staticClass: "px-6 py-6 d-flex" },
        [
          _c("h5", { staticClass: "text-h5 font-weight-bold text-typo" }, [
            _vm._v(" Two-factor authentication "),
          ]),
          _c(
            "v-btn",
            {
              staticClass:
                "\n        border-radius-md\n        font-weight-bolder\n        px-3\n        py-3\n        badge-font-size\n        ms-auto\n        text-success\n      ",
              attrs: {
                elevation: "0",
                small: "",
                ripple: false,
                height: "21",
                color: "#bce2be",
              },
            },
            [_vm._v("Enabled")]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "px-6 py-6" }, [
        _c(
          "div",
          { staticClass: "d-flex" },
          [
            _c("p", { staticClass: "my-auto text-body font-weight-light" }, [
              _vm._v("Security keys"),
            ]),
            _c(
              "p",
              {
                staticClass:
                  "text-secondary font-weight-light text-sm ms-auto my-auto me-3",
              },
              [_vm._v(" No Security Keys ")]
            ),
            _c(
              "v-btn",
              {
                staticClass: "font-weight-bolder btn-outline-default py-4 px-4",
                attrs: { elevation: "0", color: "#fff", small: "" },
              },
              [_vm._v(" Add ")]
            ),
          ],
          1
        ),
        _c("hr", { staticClass: "horizontal dark my-4" }),
        _c(
          "div",
          { staticClass: "d-flex" },
          [
            _c("p", { staticClass: "my-auto text-body font-weight-light" }, [
              _vm._v("SMS Number"),
            ]),
            _c(
              "p",
              {
                staticClass:
                  "text-secondary font-weight-light text-sm ms-auto my-auto me-3",
              },
              [_vm._v(" +4012374423 ")]
            ),
            _c(
              "v-btn",
              {
                staticClass: "font-weight-bolder btn-outline-default py-4 px-4",
                attrs: { elevation: "0", color: "#fff", small: "" },
              },
              [_vm._v(" Edit ")]
            ),
          ],
          1
        ),
        _c("hr", { staticClass: "horizontal dark my-4" }),
        _c(
          "div",
          { staticClass: "d-flex" },
          [
            _c("p", { staticClass: "my-auto text-body font-weight-light" }, [
              _vm._v("Authenticator app"),
            ]),
            _c(
              "p",
              {
                staticClass:
                  "text-secondary font-weight-light text-sm ms-auto my-auto me-3",
              },
              [_vm._v(" Not Configured ")]
            ),
            _c(
              "v-btn",
              {
                staticClass: "font-weight-bolder btn-outline-default py-4 px-4",
                attrs: { elevation: "0", color: "#fff", small: "" },
              },
              [_vm._v(" Set Up ")]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }