var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-card", { staticClass: "card-shadow border-radius-xl" }, [
    _c("div", { staticClass: "px-4 pt-4" }, [
      _vm.$route.name != "RTL"
        ? _c("h6", { staticClass: "text-h6 font-weight-bold text-typo" }, [
            _vm._v(" Categories "),
          ])
        : _c("h6", { staticClass: "text-h6 font-weight-bold text-typo" }, [
            _vm._v("فئات"),
          ]),
    ]),
    _c(
      "div",
      { staticClass: "px-4 pt-4" },
      [
        _c(
          "v-list",
          [
            _c(
              "v-list-item-group",
              { staticClass: "border-radius-sm" },
              _vm._l(_vm.categories, function (item, i) {
                return _c(
                  "v-list-item",
                  {
                    key: item.icon,
                    staticClass: "px-0 border-radius-sm mb-3",
                    class: i != _vm.categories.length - 1 ? " mb-3" : "",
                  },
                  [
                    _c(
                      "v-avatar",
                      {
                        staticClass:
                          "\n              text-white\n              bg-gradient-default\n              border-radius-lg\n              shadow\n              px-2\n              py-2\n              me-4\n            ",
                        attrs: { size: "32" },
                      },
                      [
                        _c(
                          "v-icon",
                          { staticClass: "material-icons-round text-white" },
                          [_vm._v(_vm._s(item.icon))]
                        ),
                      ],
                      1
                    ),
                    _c("v-list-item-content", { staticClass: "py-0" }, [
                      _c("div", { staticClass: "d-flex flex-column w-70" }, [
                        _c(
                          "h6",
                          {
                            staticClass:
                              "mb-1 text-typo text-sm font-weight-bold",
                          },
                          [_vm._v(" " + _vm._s(item.title) + " ")]
                        ),
                        _c("span", { staticClass: "text-xs text-body" }, [
                          _vm._v(" " + _vm._s(item.description) + " "),
                        ]),
                      ]),
                    ]),
                    _c("v-list-item-content", { staticClass: "py-0" }, [
                      _c(
                        "div",
                        { staticClass: "d-flex" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "icon-move-right ms-auto",
                              attrs: {
                                small: "",
                                icon: "",
                                rounded: "",
                                color: "#344767",
                                width: "45px",
                                height: "45px",
                              },
                            },
                            [
                              _c("v-icon", { attrs: { size: "8" } }, [
                                _vm._v("ni ni-bold-right"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                )
              }),
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }