var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-card", { staticClass: "card-shadow border-radius-xl" }, [
    _c(
      "div",
      { staticClass: "px-4 py-4" },
      [
        _c("full-calendar", {
          ref: "fullCalendar",
          attrs: { options: _vm.calendarOptions },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }