var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "py-6 px-0", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { md: "4" } },
            [
              _c(
                "v-card",
                { staticClass: "pt-8 px-3 shadow-blur" },
                [
                  _c("h6", { staticClass: "text-h6 text-typo mb-2" }, [
                    _vm._v("Friends"),
                  ]),
                  _c(
                    "v-form",
                    {
                      staticClass:
                        "navbar-search navbar-search-light d-inline-block w-100 mb-4",
                      attrs: { id: "navbar-search-main" },
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.submit.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("v-text-field", {
                        staticClass: "font-size-input input-style",
                        attrs: {
                          "rounded-sm": "",
                          "hide-details": "",
                          outlined: "",
                          dense: "",
                          "background-color": "transparent",
                          color: "#e91e63",
                          label: "Search contact",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._l(_vm.messages, function (message, i) {
                    return _c(
                      "a",
                      {
                        key: message.name,
                        staticClass:
                          "\n            card-shadow\n            shadow-blur\n            border-radius-xl\n            mb-6\n            text-decoration-none\n          ",
                        attrs: { href: "javascript:void(0)" },
                      },
                      [
                        i == 0
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex pa-4 bg-gradient-primary border-radius-xl",
                              },
                              [
                                _c("v-avatar", { attrs: { size: "48" } }, [
                                  _c("img", {
                                    attrs: {
                                      src: message.image,
                                      alt: "Avatar",
                                    },
                                  }),
                                ]),
                                _c("div", { staticClass: "ms-3" }, [
                                  _c(
                                    "h6",
                                    { staticClass: "text-h6 text-white mb-0" },
                                    [_vm._v(_vm._s(message.name))]
                                  ),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "\n                  mb-0\n                  text-white\n                  font-weight-light\n                  text-truncate\n                  d-block\n                  text-sm\n                ",
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(message.message) + " "
                                      ),
                                    ]
                                  ),
                                ]),
                              ],
                              1
                            )
                          : _c(
                              "div",
                              { staticClass: "d-flex pa-4" },
                              [
                                _c("v-avatar", { attrs: { size: "48" } }, [
                                  _c("img", {
                                    attrs: {
                                      src: message.image,
                                      alt: "Avatar",
                                    },
                                  }),
                                ]),
                                _c("div", { staticClass: "ms-3" }, [
                                  _c(
                                    "h6",
                                    { staticClass: "text-h6 text-typo mb-0" },
                                    [_vm._v(_vm._s(message.name))]
                                  ),
                                  message.time
                                    ? _c(
                                        "p",
                                        {
                                          staticClass:
                                            "text-muted text-xs mb-2 font-weight-light",
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(message.time) + " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "\n                  mb-0\n                  text-body\n                  font-weight-light\n                  text-truncate\n                  d-block\n                  text-sm\n                ",
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(message.message) + " "
                                      ),
                                    ]
                                  ),
                                ]),
                              ],
                              1
                            ),
                      ]
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { md: "8" } },
            [
              _c("v-card", { staticClass: "pt-8 px-1 shadow-blur" }, [
                _c("div", { staticClass: "mt-n4" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "\n              bg-gradient-primary\n              shadow-primary\n              border-radius-lg\n              px-3\n              py-2\n              mx-3\n            ",
                    },
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { md: "10" } }, [
                                _c(
                                  "div",
                                  { staticClass: "d-flex align-items-center" },
                                  [
                                    _c("v-avatar", { attrs: { size: "48" } }, [
                                      _c("img", {
                                        attrs: {
                                          src: require("@/assets/img/team-2.jpg"),
                                          alt: "Avatar",
                                        },
                                      }),
                                    ]),
                                    _c("div", { staticClass: "ms-3" }, [
                                      _c(
                                        "h6",
                                        {
                                          staticClass:
                                            "mb-0 text-h6 d-block text-white",
                                        },
                                        [_vm._v(" Charlie Watson ")]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-sm text-white opacity-8",
                                        },
                                        [_vm._v("last seen today at 1:53am")]
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "v-col",
                                {
                                  staticClass: "my-auto",
                                  attrs: { cols: "1" },
                                },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function (ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass:
                                                        "material-icons-round text-white",
                                                      attrs: { size: "18" },
                                                    },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [_vm._v(" videocam ")]
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                    },
                                    [_c("span", [_vm._v("Video call")])]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "my-auto",
                                  attrs: { cols: "1" },
                                },
                                [
                                  _c(
                                    "v-menu",
                                    {
                                      attrs: {
                                        transition: "slide-y-transition",
                                        "offset-y": "",
                                        "offset-x": "",
                                        "min-width": "150",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function (ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass:
                                                        "text-secondary",
                                                      attrs: {
                                                        icon: "",
                                                        ripple: false,
                                                        small: "",
                                                      },
                                                    },
                                                    "v-btn",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass:
                                                        "material-icons-round text-white",
                                                      attrs: { size: "18" },
                                                    },
                                                    [_vm._v("settings")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                    },
                                    [
                                      _c(
                                        "v-list",
                                        { staticClass: "pa-2" },
                                        [
                                          _c(
                                            "v-list-item",
                                            {
                                              staticClass:
                                                "list-item-hover-active border-radius-md",
                                            },
                                            [
                                              _c(
                                                "v-list-item-content",
                                                { staticClass: "pa-0" },
                                                [
                                                  _c(
                                                    "v-list-item-title",
                                                    {
                                                      staticClass:
                                                        "\n                              text-body\n                              ls-0\n                              text-typo\n                              font-weight-600\n                              mb-0\n                            ",
                                                    },
                                                    [_vm._v(" Profile ")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item",
                                            {
                                              staticClass:
                                                "list-item-hover-active border-radius-md",
                                            },
                                            [
                                              _c(
                                                "v-list-item-content",
                                                { staticClass: "pa-0" },
                                                [
                                                  _c(
                                                    "v-list-item-title",
                                                    {
                                                      staticClass:
                                                        "\n                              text-body\n                              ls-0\n                              text-typo\n                              font-weight-600\n                              mb-0\n                            ",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Mute Conversation "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item",
                                            {
                                              staticClass:
                                                "list-item-hover-active border-radius-md",
                                            },
                                            [
                                              _c(
                                                "v-list-item-content",
                                                { staticClass: "pa-0" },
                                                [
                                                  _c(
                                                    "v-list-item-title",
                                                    {
                                                      staticClass:
                                                        "\n                              text-body\n                              ls-0\n                              text-typo\n                              font-weight-600\n                              mb-0\n                            ",
                                                    },
                                                    [_vm._v(" Block ")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item",
                                            {
                                              staticClass:
                                                "list-item-hover-active border-radius-md",
                                            },
                                            [
                                              _c(
                                                "v-list-item-content",
                                                { staticClass: "pa-0" },
                                                [
                                                  _c(
                                                    "v-list-item-title",
                                                    {
                                                      staticClass:
                                                        "\n                              text-body\n                              ls-0\n                              text-typo\n                              font-weight-600\n                              mb-0\n                            ",
                                                    },
                                                    [_vm._v(" Clear Chat ")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item",
                                            {
                                              staticClass:
                                                "list-item-hover-active border-radius-md",
                                            },
                                            [
                                              _c(
                                                "v-list-item-content",
                                                { staticClass: "pa-0" },
                                                [
                                                  _c(
                                                    "v-list-item-title",
                                                    {
                                                      staticClass:
                                                        "text-danger ls-0 font-weight-600 mb-0",
                                                    },
                                                    [_vm._v(" Delete Chat ")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "max-vh-50 overflow-scroll pt-6 px-4" },
                  [
                    _c(
                      "v-row",
                      { staticClass: "justify-start" },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "auto" } },
                          [
                            _c(
                              "v-card",
                              {
                                staticClass:
                                  "py-2 px-3 shadow border-radius-xl",
                              },
                              [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "mb-1 text-body font-weight-light",
                                  },
                                  [
                                    _vm._v(
                                      " It contains a lot of good lessons about effective practices "
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex align-center text-sm text-body opacity-6",
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "ni ni-check-bold text-sm me-1",
                                    }),
                                    _c("small", [_vm._v("3:14am")]),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      { staticClass: "justify-end text-end" },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "auto" } },
                          [
                            _c(
                              "v-card",
                              {
                                staticClass:
                                  "py-2 px-3 shadow bg-gradient-primary border-radius-xl",
                              },
                              [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "mb-1 font-weight-light text-white",
                                  },
                                  [
                                    _vm._v(
                                      " Can it generate daily design links that include essays and data visualizations ? "
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "\n                    d-flex\n                    align-center\n                    justify-end\n                    text-sm text-white\n                    opacity-6\n                  ",
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "ni ni-check-bold text-sm me-1",
                                    }),
                                    _c("small", [_vm._v("4:42pm")]),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { staticClass: "text-center", attrs: { cols: "12" } },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "\n                  badge\n                  text-dark text-uppercase text-xs\n                  font-weight-bold\n                ",
                              },
                              [_vm._v("Wed, 3:27pm")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._l(_vm.chats, function (chat) {
                      return _c(
                        "div",
                        { key: chat.message },
                        [
                          chat.type == "received"
                            ? _c(
                                "v-row",
                                { staticClass: "justify-start mb-3" },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "auto" } },
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          staticClass:
                                            "py-2 px-3 shadow border-radius-xl",
                                        },
                                        [
                                          chat.message
                                            ? _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "mb-1 text-body font-weight-light",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(chat.message) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          chat.image
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "w-100 position-relative",
                                                },
                                                [
                                                  _c("v-img", {
                                                    staticClass:
                                                      "img-fluid mb-2 border-radius-lg",
                                                    attrs: {
                                                      src: chat.image,
                                                      "max-width": "200",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          chat.time
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex align-center text-sm text-body opacity-6",
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "ni ni-check-bold text-sm me-1",
                                                  }),
                                                  _c("small", [
                                                    _vm._v(_vm._s(chat.time)),
                                                  ]),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _c(
                                "v-row",
                                { staticClass: "justify-end text-end mb-3" },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "auto" } },
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          staticClass:
                                            "\n                    py-2\n                    px-3\n                    shadow\n                    bg-gradient-primary\n                    border-radius-xl\n                  ",
                                        },
                                        [
                                          chat.message
                                            ? _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "mb-1 font-weight-light text-white",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(chat.message) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          chat.time
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "\n                      d-flex\n                      align-center\n                      justify-end\n                      text-sm text-white\n                      opacity-6\n                    ",
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "ni ni-check-bold text-sm me-1",
                                                  }),
                                                  _c("small", [
                                                    _vm._v(_vm._s(chat.time)),
                                                  ]),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                ),
                _c(
                  "div",
                  { staticClass: "py-4 px-4" },
                  [
                    _c(
                      "v-form",
                      {
                        staticClass: "d-flex w-100",
                        attrs: { id: "navbar-search-main" },
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return _vm.submit.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("v-text-field", {
                          staticClass: "font-size-input input-style",
                          attrs: {
                            "rounded-sm": "",
                            outlined: "",
                            dense: "",
                            "hide-details": "",
                            "background-color": "transparent",
                            color: "#e91e63",
                            label: "Type your message",
                          },
                        }),
                        _c(
                          "v-btn",
                          {
                            staticClass: "bg-gradient-primary ms-2",
                            attrs: { elevation: "0", height: "40" },
                          },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "material-icons-round text-white",
                              },
                              [_vm._v("send")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }