var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "align-center d-flex h-100" },
    [
      _c(
        "v-col",
        { staticClass: "mx-auto", attrs: { lg: "4", md: "7" } },
        [
          _c("v-card", { staticClass: "card-shadow border-radius-xl py-1" }, [
            _c(
              "div",
              {
                staticClass:
                  "\n          card-padding\n          text-center\n          mt-n7\n          bg-gradient-success\n          shadow-success\n          border-radius-lg\n          mx-4\n        ",
              },
              [
                _c(
                  "h4",
                  { staticClass: "text-h4 text-white font-weight-bold" },
                  [_vm._v("Register with")]
                ),
                _c(
                  "v-row",
                  { staticClass: "mt-3" },
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "ms-auto d-flex justify-center",
                        attrs: { cols: "2" },
                      },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "text-center border-radius-md",
                            attrs: {
                              icon: "",
                              outlined: "",
                              rounded: "",
                              color: "transparent",
                            },
                          },
                          [
                            _c("i", {
                              staticClass:
                                "fab fa-facebook-f text-white text-lg",
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      {
                        staticClass: "d-flex justify-center",
                        attrs: { cols: "2" },
                      },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "text-center border-radius-md",
                            attrs: {
                              icon: "",
                              outlined: "",
                              rounded: "",
                              color: "transparent",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "fab fa-github text-white text-lg",
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      {
                        staticClass: "me-auto d-flex justify-center",
                        attrs: { cols: "2" },
                      },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "text-center border-radius-md",
                            attrs: {
                              icon: "",
                              outlined: "",
                              rounded: "",
                              color: "transparent",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "fab fa-google text-white text-lg",
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("div", { staticClass: "mt-2 position-relative text-center" }, [
              _c(
                "p",
                {
                  staticClass:
                    "\n            text-sm\n            font-weight-bold\n            mb-2\n            text-secondary text-border\n            d-inline\n            z-index-2\n            bg-white\n            px-3\n          ",
                },
                [_vm._v(" or ")]
              ),
            ]),
            _c(
              "div",
              { staticClass: "card-padding" },
              [
                _c("v-text-field", {
                  staticClass: "font-size-input input-style",
                  attrs: {
                    label: "Name",
                    placeholder: "Name",
                    color: "#e91e63",
                    required: "",
                  },
                }),
                _c("v-text-field", {
                  staticClass: "font-size-input input-style",
                  attrs: {
                    label: "Email",
                    placeholder: "Email",
                    color: "#e91e63",
                    required: "",
                  },
                }),
                _c("v-text-field", {
                  staticClass: "font-size-input input-style",
                  attrs: {
                    label: "Password",
                    placeholder: "Password",
                    color: "#e91e63",
                    required: "",
                    type: "password",
                  },
                }),
                _c("v-checkbox", {
                  staticClass: "ma-0 checkbox-custom checkbox-thinner",
                  attrs: {
                    color: "#141727",
                    ripple: false,
                    "hide-details": "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "label",
                      fn: function () {
                        return [
                          _c(
                            "span",
                            { staticClass: "text-body text-sm ls-0" },
                            [
                              _vm._v("I agree the "),
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "text-dark font-weight-bolder text-decoration-none",
                                  attrs: { href: "javascript:;" },
                                },
                                [_vm._v("Terms and Conditions")]
                              ),
                            ]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                  model: {
                    value: _vm.checkbox,
                    callback: function ($$v) {
                      _vm.checkbox = $$v
                    },
                    expression: "checkbox",
                  },
                }),
                _c(
                  "v-btn",
                  {
                    staticClass:
                      "\n            font-weight-bold\n            text-uppercase\n            btn-default\n            bg-gradient-default\n            py-2\n            px-6\n            me-2\n            mt-6\n            mb-2\n            w-100\n          ",
                    attrs: {
                      elevation: "0",
                      ripple: false,
                      height: "43",
                      color: "#5e72e4",
                      small: "",
                    },
                  },
                  [_vm._v("Sign Up")]
                ),
                _c("p", { staticClass: "text-sm text-body mt-3 mb-0" }, [
                  _vm._v(" Already have an account? "),
                  _c(
                    "a",
                    {
                      staticClass:
                        "text-dark text-decoration-none font-weight-bolder",
                      attrs: { href: "javascript:;" },
                    },
                    [_vm._v("Sign in")]
                  ),
                ]),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }