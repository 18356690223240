var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "my-16" },
        [
          _c(
            "v-col",
            {
              staticClass: "mx-auto my-auto my-16",
              attrs: { lg: "8", cols: "12" },
            },
            [
              _c(
                "v-card",
                { staticClass: "shadow border-radius-xl my-10" },
                [
                  _c(
                    "v-stepper",
                    {
                      staticClass: "bg-transparent overflow-visible",
                      attrs: { "alt-labels": "", elevation: "0" },
                      model: {
                        value: _vm.e1,
                        callback: function ($$v) {
                          _vm.e1 = $$v
                        },
                        expression: "e1",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "pt-1" },
                        [
                          _c(
                            "v-stepper-header",
                            {
                              staticClass:
                                "\n                bg-gradient-primary\n                shadow-primary\n                border-radius-lg\n                mx-4\n                mt-n10\n              ",
                            },
                            [
                              _c(
                                "v-stepper-step",
                                { attrs: { step: "1", color: "#344767" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "\n                    text-white\n                    opacity-7\n                    text-xs\n                    font-weight-normal\n                    text-uppercase\n                  ",
                                    },
                                    [_vm._v("User Info")]
                                  ),
                                ]
                              ),
                              _c("v-divider"),
                              _c(
                                "v-stepper-step",
                                { attrs: { step: "2", color: "#344767" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "\n                    text-white\n                    opacity-7\n                    text-xs\n                    font-weight-normal\n                    text-uppercase\n                  ",
                                    },
                                    [_vm._v("Address")]
                                  ),
                                ]
                              ),
                              _c("v-divider"),
                              _c(
                                "v-stepper-step",
                                { attrs: { step: "3", color: "#344767" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "\n                    text-white\n                    opacity-7\n                    text-xs\n                    font-weight-normal\n                    text-uppercase\n                  ",
                                    },
                                    [_vm._v("Socials")]
                                  ),
                                ]
                              ),
                              _c("v-divider"),
                              _c(
                                "v-stepper-step",
                                { attrs: { step: "4", color: "#344767" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "\n                    text-white\n                    opacity-7\n                    text-xs\n                    font-weight-normal\n                    text-uppercase\n                  ",
                                    },
                                    [_vm._v("Profile")]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-stepper-items",
                        {
                          staticClass: "border-radius-xl overflow-hidden mt-5",
                        },
                        [
                          _c(
                            "v-stepper-content",
                            {
                              staticClass:
                                "bg-white border-radius-xl px-7 pt-2",
                              attrs: { step: "1" },
                            },
                            [
                              _c("v-card", [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "h5",
                                      {
                                        staticClass:
                                          "font-weight-bolder text-h5 text-typo mb-0",
                                      },
                                      [_vm._v(" About me ")]
                                    ),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "mb-0 text-sm text-body font-weight-light",
                                      },
                                      [_vm._v(" Mandatory informations ")]
                                    ),
                                    _c(
                                      "v-row",
                                      { staticClass: "mt-2" },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "py-0",
                                            attrs: { sm: "6", cols: "12" },
                                          },
                                          [
                                            _c("v-text-field", {
                                              staticClass:
                                                "font-size-input input-style",
                                              attrs: {
                                                label: "First Name",
                                                color: "#e91e63",
                                                required: "",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "py-0",
                                            attrs: { sm: "6", cols: "12" },
                                          },
                                          [
                                            _c("v-text-field", {
                                              staticClass:
                                                "font-size-input input-style",
                                              attrs: {
                                                label: "Last Name",
                                                color: "#e91e63",
                                                required: "",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      { staticClass: "mt-2" },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "py-0",
                                            attrs: { sm: "6", cols: "12" },
                                          },
                                          [
                                            _c("v-text-field", {
                                              staticClass:
                                                "font-size-input input-style",
                                              attrs: {
                                                label: "Company",
                                                color: "#e91e63",
                                                required: "",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "py-0",
                                            attrs: { sm: "6", cols: "12" },
                                          },
                                          [
                                            _c("v-text-field", {
                                              staticClass:
                                                "font-size-input input-style",
                                              attrs: {
                                                label: "Email Address",
                                                color: "#e91e63",
                                                required: "",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      { staticClass: "mt-2" },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "py-0",
                                            attrs: { sm: "6", cols: "12" },
                                          },
                                          [
                                            _c("v-text-field", {
                                              staticClass:
                                                "font-size-input input-style",
                                              attrs: {
                                                label: "Password",
                                                color: "#e91e63",
                                                required: "",
                                                type: "password",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "py-0",
                                            attrs: { sm: "6", cols: "12" },
                                          },
                                          [
                                            _c("v-text-field", {
                                              staticClass:
                                                "font-size-input input-style",
                                              attrs: {
                                                label: "Repeat Password",
                                                color: "#e91e63",
                                                required: "",
                                                type: "password",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "text-end" },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass:
                                          "\n                      font-weight-bold\n                      text-xs\n                      btn-default\n                      bg-gradient-default\n                      py-5\n                      px-6\n                      mt-6\n                      mb-2\n                      me-2\n                    ",
                                        attrs: {
                                          ripple: false,
                                          elevation: 0,
                                          color: "primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.e1 = 2
                                          },
                                        },
                                      },
                                      [_vm._v(" Next ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-stepper-content",
                            {
                              staticClass:
                                "bg-white border-radius-xl px-7 pt-4",
                              attrs: { step: "2" },
                            },
                            [
                              _c("v-card", [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "h5",
                                      {
                                        staticClass:
                                          "font-weight-bolder text-h5 text-typo",
                                      },
                                      [_vm._v(" Address ")]
                                    ),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "mb-0 text-sm text-body font-weight-light",
                                      },
                                      [_vm._v(" Tell us where are you living ")]
                                    ),
                                    _c(
                                      "v-row",
                                      { staticClass: "mt-2" },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "py-0",
                                            attrs: { cols: "12" },
                                          },
                                          [
                                            _c("v-text-field", {
                                              staticClass:
                                                "font-size-input input-style",
                                              attrs: {
                                                label: "Address 1",
                                                color: "#e91e63",
                                                required: "",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      { staticClass: "mt-2" },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "py-0",
                                            attrs: { cols: "12" },
                                          },
                                          [
                                            _c("v-text-field", {
                                              staticClass:
                                                "font-size-input input-style",
                                              attrs: {
                                                label: "Address 2",
                                                color: "#e91e63",
                                                required: "",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      { staticClass: "mt-2" },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "py-0",
                                            attrs: { sm: "6", cols: "12" },
                                          },
                                          [
                                            _c("v-text-field", {
                                              staticClass:
                                                "font-size-input input-style",
                                              attrs: {
                                                label: "City",
                                                color: "#e91e63",
                                                required: "",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "py-0",
                                            attrs: { sm: "3", cols: "12" },
                                          },
                                          [
                                            _c("v-text-field", {
                                              staticClass:
                                                "font-size-input input-style",
                                              attrs: {
                                                label: "State",
                                                color: "#e91e63",
                                                required: "",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "py-0",
                                            attrs: { sm: "3", cols: "12" },
                                          },
                                          [
                                            _c("v-text-field", {
                                              staticClass:
                                                "font-size-input input-style",
                                              attrs: {
                                                label: "Zip",
                                                color: "#e91e63",
                                                required: "",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "d-flex" },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass:
                                          "\n                      font-weight-bold\n                      placeholder-lighter\n                      text-xs text-dark\n                      btn-outline-secondary\n                      bg-transparent\n                      py-5\n                      px-6\n                      mt-6\n                      mb-2\n                      ms-2\n                    ",
                                        attrs: { ripple: false, elevation: 0 },
                                        on: {
                                          click: function ($event) {
                                            _vm.e1 = 1
                                          },
                                        },
                                      },
                                      [_vm._v(" Prev ")]
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass:
                                          "\n                      font-weight-bold\n                      text-xs\n                      btn-default\n                      bg-gradient-default\n                      py-5\n                      px-6\n                      mt-6\n                      mb-2\n                      me-2\n                      ms-auto\n                    ",
                                        attrs: {
                                          ripple: false,
                                          elevation: 0,
                                          color: "primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.e1 = 3
                                          },
                                        },
                                      },
                                      [_vm._v(" Next ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-stepper-content",
                            {
                              staticClass:
                                "bg-white border-radius-xl px-7 pt-4",
                              attrs: { step: "3" },
                            },
                            [
                              _c("v-card", [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "h5",
                                      {
                                        staticClass:
                                          "font-weight-bolder text-h5 text-typo",
                                      },
                                      [_vm._v(" Socials ")]
                                    ),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "mb-0 text-sm text-body font-weight-light",
                                      },
                                      [
                                        _vm._v(
                                          " Please provide at least one social link "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "v-row",
                                      { staticClass: "mt-2" },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "py-0",
                                            attrs: { cols: "12" },
                                          },
                                          [
                                            _c("v-text-field", {
                                              staticClass:
                                                "font-size-input input-style",
                                              attrs: {
                                                label: "Twitter Handle",
                                                color: "#e91e63",
                                                required: "",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      { staticClass: "mt-2" },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "py-0",
                                            attrs: { cols: "12" },
                                          },
                                          [
                                            _c("v-text-field", {
                                              staticClass:
                                                "font-size-input input-style",
                                              attrs: {
                                                label: "Facebook Account",
                                                color: "#e91e63",
                                                required: "",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      { staticClass: "mt-2" },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "py-0",
                                            attrs: { cols: "12" },
                                          },
                                          [
                                            _c("v-text-field", {
                                              staticClass:
                                                "font-size-input input-style",
                                              attrs: {
                                                label: "Instagram Account",
                                                color: "#e91e63",
                                                required: "",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "d-flex" },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass:
                                          "\n                      font-weight-bold\n                      placeholder-lighter\n                      text-xs text-dark\n                      btn-outline-secondary\n                      bg-transparent\n                      py-5\n                      px-6\n                      mt-6\n                      mb-2\n                      ms-2\n                    ",
                                        attrs: { ripple: false, elevation: 0 },
                                        on: {
                                          click: function ($event) {
                                            _vm.e1 = 2
                                          },
                                        },
                                      },
                                      [_vm._v(" Prev ")]
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass:
                                          "\n                      font-weight-bold\n                      text-xs\n                      btn-default\n                      bg-gradient-default\n                      py-5\n                      px-6\n                      mt-6\n                      mb-0\n                      ms-auto\n                    ",
                                        attrs: {
                                          ripple: false,
                                          elevation: 0,
                                          color: "primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.e1 = 4
                                          },
                                        },
                                      },
                                      [_vm._v(" Next ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-stepper-content",
                            {
                              staticClass:
                                "bg-white border-radius-xl px-7 pt-4",
                              attrs: { step: "4" },
                            },
                            [
                              _c("v-card", [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "h5",
                                      {
                                        staticClass:
                                          "font-weight-bolder text-h5 text-typo",
                                      },
                                      [_vm._v(" Profile ")]
                                    ),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "mb-0 text-sm text-body font-weight-light",
                                      },
                                      [_vm._v(" Optional informations ")]
                                    ),
                                    _c(
                                      "v-row",
                                      { staticClass: "mt-2" },
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12" } },
                                          [
                                            _c("v-textarea", {
                                              staticClass:
                                                "font-size-input input-style",
                                              attrs: {
                                                placeholder:
                                                  "Say a few words about who you are or what you're working on.",
                                                color: "#e91e63",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "d-flex" },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass:
                                          "\n                      font-weight-bold\n                      placeholder-lighter\n                      text-xs text-dark\n                      btn-outline-secondary\n                      bg-transparent\n                      py-5\n                      px-6\n                      mt-6\n                      mb-2\n                      ms-2\n                    ",
                                        attrs: { ripple: false, elevation: 0 },
                                        on: {
                                          click: function ($event) {
                                            _vm.e1 = 3
                                          },
                                        },
                                      },
                                      [_vm._v(" Prev ")]
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass:
                                          "\n                      font-weight-bold\n                      text-xs\n                      bg-gradient-default\n                      py-5\n                      px-6\n                      mt-6\n                      mb-2\n                      me-2\n                      ms-auto\n                      shadow-none\n                    ",
                                        attrs: {
                                          ripple: false,
                                          elevation: 0,
                                          color: "primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.e1 = 4
                                          },
                                        },
                                      },
                                      [_vm._v(" Send ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }