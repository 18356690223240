var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "py-6", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "mx-auto", attrs: { lg: "8" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "card-shadow border-radius-xl" },
                    [
                      _c("div", { staticClass: "px-4 pt-4" }, [
                        _c(
                          "div",
                          { staticClass: "d-flex align-center" },
                          [
                            _c("div", [
                              _c(
                                "h6",
                                {
                                  staticClass:
                                    "text-h6 text-typo font-weight-bold mb-2",
                                },
                                [_vm._v(" Order Details ")]
                              ),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "text-sm text-body font-weight-light mb-0",
                                },
                                [
                                  _vm._v(" Order no. "),
                                  _c(
                                    "span",
                                    { staticClass: "font-weight-bold" },
                                    [_vm._v("241342")]
                                  ),
                                  _vm._v(" from "),
                                  _c(
                                    "span",
                                    { staticClass: "font-weight-bold" },
                                    [_vm._v("23.02.2021")]
                                  ),
                                ]
                              ),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "text-sm font-weight-light text-body",
                                },
                                [
                                  _vm._v(" Code: "),
                                  _c(
                                    "span",
                                    { staticClass: "font-weight-bold" },
                                    [_vm._v("KF332")]
                                  ),
                                ]
                              ),
                            ]),
                            _c(
                              "v-btn",
                              {
                                staticClass:
                                  "\n                  font-weight-bold\n                  text-uppercase\n                  btn-default\n                  bg-gradient-default\n                  py-2\n                  px-6\n                  me-2\n                  ms-auto\n                ",
                                attrs: {
                                  elevation: "0",
                                  ripple: false,
                                  height: "43",
                                  color: "#5e72e4",
                                  small: "",
                                },
                              },
                              [_vm._v("Invoice")]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "px-4 pb-4" },
                        [
                          _c("hr", {
                            staticClass: "horizontal dark mt-0 mb-6",
                          }),
                          _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { md: "6" } }, [
                                _c(
                                  "div",
                                  { staticClass: "d-flex" },
                                  [
                                    _c(
                                      "v-avatar",
                                      {
                                        staticClass: "me-4 border-rounded",
                                        attrs: {
                                          alt: "product-image",
                                          width: "110px",
                                          height: "110px",
                                        },
                                      },
                                      [
                                        _c("v-img", {
                                          attrs: {
                                            src: require("@/assets/img/products/product-12.jpg"),
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "h6",
                                          {
                                            staticClass:
                                              "\n                        text-h6 text-typo text-lg\n                        font-weight-bold\n                        mb-0\n                        mt-2\n                        mb-1\n                      ",
                                          },
                                          [_vm._v(" Dandelion Print Shirt ")]
                                        ),
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "text-sm text-body font-weight-light mb-3",
                                          },
                                          [
                                            _vm._v(
                                              " Order was delivered 2 days ago. "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass:
                                              "\n                        border-radius-lg\n                        bg-gradient-success\n                        text-xxs\n                        font-weight-bold\n                        px-2\n                        py-2\n                        badge-font-size\n                        ms-auto\n                        text-white\n                      ",
                                            attrs: {
                                              elevation: "0",
                                              small: "",
                                              ripple: false,
                                              height: "21",
                                              color: "#cdf59b",
                                            },
                                          },
                                          [_vm._v("Delivered")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "v-col",
                                {
                                  staticClass: "text-end my-auto",
                                  attrs: { md: "6" },
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "\n                    font-weight-bold\n                    text-uppercase\n                    btn-default\n                    bg-gradient-default\n                    px-4\n                    me-2\n                    ms-auto\n                  ",
                                      attrs: {
                                        elevation: "0",
                                        ripple: false,
                                        height: "36",
                                        color: "#5e72e4",
                                        small: "",
                                      },
                                    },
                                    [_vm._v("Contact us")]
                                  ),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "text-sm text-body font-weight-light mt-2 mb-0",
                                    },
                                    [
                                      _vm._v(
                                        " Do you like the product? Leave us a review "
                                      ),
                                      _c(
                                        "a",
                                        {
                                          staticClass:
                                            "text-dark text-decoration-none",
                                          attrs: { href: "javascript:;" },
                                        },
                                        [_vm._v("here")]
                                      ),
                                      _vm._v(". "),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("hr", { staticClass: "horizontal dark my-6" }),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { lg: "3", md: "6" } },
                                [
                                  _c(
                                    "h6",
                                    {
                                      staticClass:
                                        "text-h6 text-typo font-weight-bold mb-4",
                                    },
                                    [_vm._v(" Track order ")]
                                  ),
                                  _c(
                                    "v-timeline",
                                    {
                                      staticClass: "timeline-line-color pt-0",
                                      attrs: { dense: "", "align-top": "" },
                                    },
                                    _vm._l(_vm.timeline, function (item, i) {
                                      return _c(
                                        "v-timeline-item",
                                        {
                                          key: item.title + i,
                                          staticClass: "timeline",
                                          attrs: { small: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "icon",
                                                fn: function () {
                                                  return [
                                                    _c(
                                                      "v-avatar",
                                                      {
                                                        attrs: {
                                                          size: "26",
                                                          color: "#ffffff",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            staticClass:
                                                              "material-icons-round",
                                                            class:
                                                              "text-gradient text-" +
                                                              item.btn,
                                                            attrs: {
                                                              size: "18",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(item.icon)
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _c(
                                            "v-card",
                                            { attrs: { width: "480" } },
                                            [
                                              _c(
                                                "v-card-title",
                                                {
                                                  staticClass:
                                                    "px-0 pt-0 pb-1 d-block",
                                                },
                                                [
                                                  _c(
                                                    "h6",
                                                    {
                                                      staticClass:
                                                        "\n                            text-dark text-sm\n                            font-weight-bold\n                            mb-0\n                            d-block\n                          ",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(item.title) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "\n                            text-secondary\n                            font-weight-light\n                            text-xs\n                            mb-0\n                          ",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(item.date) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("v-col", { attrs: { lg: "5", md: "6" } }, [
                                _c(
                                  "h6",
                                  {
                                    staticClass:
                                      "text-h6 text-typo font-weight-bold mb-3",
                                  },
                                  [_vm._v(" Payment details ")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "pa-5 border border-radius-lg d-flex align-center",
                                  },
                                  [
                                    _c(
                                      "v-avatar",
                                      {
                                        staticClass: "me-4",
                                        attrs: { size: "36", rounded: "" },
                                      },
                                      [
                                        _c("v-img", {
                                          attrs: {
                                            src: require("@/assets/img/logos/mastercard.png"),
                                            alt: "logo",
                                            "max-width": "80",
                                            contain: "",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "h6",
                                      {
                                        staticClass:
                                          "mb-0 text-h6 text-typo mt-2",
                                      },
                                      [_vm._v(" ****   ****   ****   7852 ")]
                                    ),
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { top: "" },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "activator",
                                            fn: function (ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        staticClass:
                                                          "\n                          font-weight-600\n                          text-capitalize\n                          btn-outline-secondary\n                          py-3\n                          px-3\n                          ms-auto\n                        ",
                                                        attrs: {
                                                          small: "",
                                                          rounded: "",
                                                          "min-width": "25",
                                                          elevation: "0",
                                                          ripple: false,
                                                          height: "25",
                                                          width: "25",
                                                          color: "transparent",
                                                        },
                                                      },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { size: "8" } },
                                                      [_vm._v("fas fa-info")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            "We do not store card details"
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "h6",
                                  {
                                    staticClass:
                                      "text-h6 text-typo font-weight-bold mb-4 mt-6",
                                  },
                                  [_vm._v(" Billing Information ")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex flex-column bg-gray-100 pa-6 border-radius-lg",
                                  },
                                  [
                                    _c(
                                      "h6",
                                      {
                                        staticClass:
                                          "text-typo font-weight-bold mb-4 text-sm",
                                      },
                                      [_vm._v(" Oliver Liam ")]
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "mb-2 text-body text-xs" },
                                      [
                                        _vm._v("Company Name: "),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-dark font-weight-bold ms-2",
                                          },
                                          [_vm._v("Viking Burrito")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "mb-2 text-body text-xs" },
                                      [
                                        _vm._v("Email Address: "),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-dark ms-2 font-weight-bold",
                                          },
                                          [_vm._v("oliver@burrito.com")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "text-xs text-body" },
                                      [
                                        _vm._v("VAT Number: "),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-dark ms-2 font-weight-bold",
                                          },
                                          [_vm._v("FRB1235476")]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                              _c(
                                "v-col",
                                { staticClass: "ms-auto", attrs: { lg: "3" } },
                                [
                                  _c(
                                    "h6",
                                    {
                                      staticClass:
                                        "text-h6 text-typo font-weight-bold mb-3",
                                    },
                                    [_vm._v(" Order Summary ")]
                                  ),
                                  _c("div", { staticClass: "d-flex" }, [
                                    _c(
                                      "span",
                                      { staticClass: "mb-2 text-body text-sm" },
                                      [_vm._v(" Product Price: ")]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-dark font-weight-bold ms-auto",
                                      },
                                      [_vm._v("$90")]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "d-flex" }, [
                                    _c(
                                      "span",
                                      { staticClass: "mb-2 text-body text-sm" },
                                      [_vm._v(" Delivery: ")]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-dark font-weight-bold ms-auto",
                                      },
                                      [_vm._v("$14")]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "d-flex" }, [
                                    _c(
                                      "span",
                                      { staticClass: "mb-2 text-body text-sm" },
                                      [_vm._v(" Taxes: ")]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-dark font-weight-bold ms-auto",
                                      },
                                      [_vm._v("$1.95")]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "d-flex mt-6" }, [
                                    _c(
                                      "span",
                                      { staticClass: "mb-2 text-body text-lg" },
                                      [_vm._v(" Total: ")]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-dark text-lg ms-2 font-weight-bold ms-auto",
                                      },
                                      [_vm._v("$105.95")]
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }