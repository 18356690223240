var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-text",
        { staticClass: "px-0 py-0" },
        [
          _c("v-simple-table", {
            staticClass: "table",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function () {
                  return [
                    _c("thead", [
                      _c("tr", [
                        _c(
                          "th",
                          {
                            staticClass:
                              "\n                text-left text-uppercase text-secondary text-xxs\n                font-weight-bolder\n                opacity-7\n              ",
                          },
                          [_vm._v(" Product ")]
                        ),
                        _c(
                          "th",
                          {
                            staticClass:
                              "\n                text-left text-uppercase text-secondary text-xxs\n                font-weight-bolder\n                opacity-7\n              ",
                          },
                          [_vm._v(" Price ")]
                        ),
                        _c(
                          "th",
                          {
                            staticClass:
                              "\n                text-center text-uppercase text-secondary text-xxs\n                font-weight-bolder\n                opacity-7\n              ",
                          },
                          [_vm._v(" Review ")]
                        ),
                        _c(
                          "th",
                          {
                            staticClass:
                              "\n                text-center text-uppercase text-secondary text-xxs\n                font-weight-bolder\n                opacity-7\n              ",
                          },
                          [_vm._v(" Availability ")]
                        ),
                        _c(
                          "th",
                          {
                            staticClass:
                              "\n                text-center text-uppercase text-secondary text-xxs\n                font-weight-bolder\n                opacity-7\n              ",
                          },
                          [_vm._v(" Id ")]
                        ),
                      ]),
                    ]),
                    _c(
                      "tbody",
                      _vm._l(_vm.projects, function (item) {
                        return _c("tr", { key: item.id }, [
                          _c(
                            "td",
                            { staticClass: "border-bottom" },
                            [
                              _c(
                                "v-list",
                                { staticClass: "py-0" },
                                [
                                  _c(
                                    "v-list-item",
                                    { staticClass: "px-0 py-4" },
                                    [
                                      _c(
                                        "v-list-item-avatar",
                                        {
                                          staticClass:
                                            "my-0 me-4 border-rounded",
                                          attrs: { size: 48 },
                                        },
                                        [
                                          _c("v-img", {
                                            attrs: {
                                              alt: item.imgSrc,
                                              src: item.imgSrc,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c(
                                            "v-list-item-title",
                                            {
                                              staticClass:
                                                "text-typo text-subtitle-2 ls-0 font-weight-bold",
                                            },
                                            [_vm._v(_vm._s(item.project))]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("td", { staticClass: "border-bottom" }, [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "text-sm text-secondary font-weight-light mb-0",
                              },
                              [_vm._v(" " + _vm._s(item.price) + " ")]
                            ),
                          ]),
                          _c(
                            "td",
                            {
                              staticClass:
                                "text-body border-bottom text-center",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex align-center" },
                                [
                                  _c("div", { staticClass: "rating mx-auto" }, [
                                    _c(
                                      "i",
                                      {
                                        staticClass:
                                          "material-icons-round text-lg text-body me-1",
                                      },
                                      [_vm._v("grade")]
                                    ),
                                    _c(
                                      "i",
                                      {
                                        staticClass:
                                          "material-icons-round text-lg text-body me-1",
                                      },
                                      [_vm._v("grade")]
                                    ),
                                    _c(
                                      "i",
                                      {
                                        staticClass:
                                          "material-icons-round text-lg text-body me-1",
                                      },
                                      [_vm._v("grade")]
                                    ),
                                    _c(
                                      "i",
                                      {
                                        staticClass:
                                          "material-icons-round text-lg text-body me-1",
                                      },
                                      [_vm._v("grade")]
                                    ),
                                    _c(
                                      "i",
                                      {
                                        staticClass:
                                          "material-icons-round text-lg text-body me-1",
                                        attrs: { "aria-hidden": "true" },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              item.lastIcon == "half"
                                                ? "grade"
                                                : "star_outline"
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                          _c("td", { staticClass: "border-bottom" }, [
                            _c(
                              "div",
                              { staticClass: "d-flex align-center" },
                              [
                                _c("v-progress-linear", {
                                  staticClass: "mx-auto",
                                  class: "bg-gradient-" + item.progressColor,
                                  attrs: {
                                    value: item.progressValue,
                                    rounded: "",
                                    height: "3",
                                    "background-color": "#f0f2f5",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c("td", { staticClass: "border-bottom" }, [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "text-secondary font-weight-light text-sm",
                              },
                              [_vm._v(_vm._s(item.id))]
                            ),
                          ]),
                        ])
                      }),
                      0
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }