var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "card-shadow border-radius-xl" },
        [
          _c("div", { staticClass: "card-header-padding" }, [
            _c(
              "div",
              { staticClass: "d-flex align-center" },
              [
                _c("div", [
                  _c(
                    "h5",
                    { staticClass: "font-weight-bold text-h5 text-typo mb-0" },
                    [_vm._v(" Datatable Simple ")]
                  ),
                  _c(
                    "p",
                    { staticClass: "text-sm font-weight-light text-body mb-0" },
                    [
                      _vm._v(
                        " Features include sorting, searching, pagination, content-editing, and row selection. "
                      ),
                    ]
                  ),
                ]),
                _c(
                  "v-dialog",
                  {
                    attrs: { "max-width": "500px" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function (ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass:
                                      "\n                font-weight-normal\n                text-capitalize\n                ms-auto\n                btn-primary\n                bg-gradient-primary\n                py-3\n                px-6\n                ms-3\n              ",
                                    attrs: {
                                      elevation: "0",
                                      ripple: false,
                                      height: "43",
                                    },
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [_vm._v("New Item")]
                            ),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.dialog,
                      callback: function ($$v) {
                        _vm.dialog = $$v
                      },
                      expression: "dialog",
                    },
                  },
                  [
                    _c(
                      "v-card",
                      { staticClass: "card-shadow border-radius-xl" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "card-header-padding card-border-bottom",
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "font-weight-bold text-h5 text-typo mb-0",
                              },
                              [_vm._v(_vm._s(_vm.formTitle))]
                            ),
                          ]
                        ),
                        _c(
                          "v-card-text",
                          { staticClass: "card-padding" },
                          [
                            _c(
                              "v-container",
                              { staticClass: "px-0" },
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("v-text-field", {
                                          staticClass:
                                            "\n                        input-style\n                        font-size-input\n                        text-light-input\n                        placeholder-light\n                        input-icon\n                      ",
                                          attrs: {
                                            "hide-details": "",
                                            dense: "",
                                            flat: "",
                                            filled: "",
                                            solo: "",
                                            height: "43",
                                            placeholder: "Name",
                                          },
                                          model: {
                                            value: _vm.editedItem.name,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.editedItem,
                                                "name",
                                                $$v
                                              )
                                            },
                                            expression: "editedItem.name",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("v-text-field", {
                                          staticClass:
                                            "\n                        input-style\n                        font-size-input\n                        text-light-input\n                        placeholder-light\n                        input-icon\n                      ",
                                          attrs: {
                                            "hide-details": "",
                                            dense: "",
                                            flat: "",
                                            filled: "",
                                            solo: "",
                                            height: "43",
                                            placeholder: "Email",
                                          },
                                          model: {
                                            value: _vm.editedItem.email,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.editedItem,
                                                "email",
                                                $$v
                                              )
                                            },
                                            expression: "editedItem.email",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("v-text-field", {
                                          staticClass:
                                            "\n                        input-style\n                        font-size-input\n                        text-light-input\n                        placeholder-light\n                        input-icon\n                      ",
                                          attrs: {
                                            "hide-details": "",
                                            dense: "",
                                            flat: "",
                                            filled: "",
                                            solo: "",
                                            height: "43",
                                            placeholder: "Age",
                                          },
                                          model: {
                                            value: _vm.editedItem.age,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.editedItem,
                                                "age",
                                                $$v
                                              )
                                            },
                                            expression: "editedItem.age",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("v-text-field", {
                                          staticClass:
                                            "\n                        input-style\n                        font-size-input\n                        text-light-input\n                        placeholder-light\n                        input-icon\n                      ",
                                          attrs: {
                                            "hide-details": "",
                                            dense: "",
                                            flat: "",
                                            filled: "",
                                            solo: "",
                                            height: "43",
                                            placeholder: "Salary",
                                          },
                                          model: {
                                            value: _vm.editedItem.salary,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.editedItem,
                                                "salary",
                                                $$v
                                              )
                                            },
                                            expression: "editedItem.salary",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-card-actions",
                          { staticClass: "card-padding d-flex justify-end" },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass:
                                  "\n                  font-weight-normal\n                  text-capitalize\n                  btn-ls btn-secondary btn-outline-secondary\n                  shadow-none\n                  bg-transparent\n                  py-3\n                  px-6\n                ",
                                attrs: {
                                  elevation: "0",
                                  ripple: false,
                                  height: "43",
                                },
                                on: { click: _vm.close },
                              },
                              [_vm._v("Cancel")]
                            ),
                            _c(
                              "v-btn",
                              {
                                staticClass:
                                  "\n                  font-weight-normal\n                  text-capitalize\n                  btn-ls btn-primary\n                  bg-gradient-primary\n                  py-3\n                  px-6\n                ",
                                attrs: {
                                  elevation: "0",
                                  ripple: false,
                                  height: "43",
                                },
                                on: { click: _vm.save },
                              },
                              [_vm._v("Save")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "v-card-text",
            { staticClass: "px-0 py-0" },
            [
              _c("v-data-table", {
                staticClass: "table",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.users,
                  search: _vm.search,
                  page: _vm.page,
                  "hide-default-footer": "",
                  "items-per-page": _vm.itemsPerPage,
                  "mobile-breakpoint": "0",
                },
                on: {
                  "update:page": function ($event) {
                    _vm.page = $event
                  },
                  "page-count": function ($event) {
                    _vm.pageCount = $event
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "top",
                    fn: function () {
                      return [
                        _c(
                          "v-toolbar",
                          { attrs: { flat: "", height: "0" } },
                          [
                            _c(
                              "v-dialog",
                              {
                                attrs: { "max-width": "500px" },
                                model: {
                                  value: _vm.dialogDelete,
                                  callback: function ($$v) {
                                    _vm.dialogDelete = $$v
                                  },
                                  expression: "dialogDelete",
                                },
                              },
                              [
                                _c(
                                  "v-card",
                                  {
                                    staticClass:
                                      "card-shadow card-padding border-radius-xl",
                                  },
                                  [
                                    _c(
                                      "v-card-title",
                                      {
                                        staticClass:
                                          "\n                    pt-0\n                    text-h5 text-typo\n                    font-weight-normal\n                    justify-center\n                  ",
                                      },
                                      [
                                        _vm._v(
                                          "Are you sure you want to delete this item?"
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "v-card-actions",
                                      { staticClass: "pb-0" },
                                      [
                                        _c("v-spacer"),
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass:
                                              "\n                      font-weight-normal\n                      text-capitalize\n                      btn-ls btn-outline-secondary\n                      bg-transparent\n                      py-3\n                      px-6\n                    ",
                                            attrs: {
                                              elevation: "0",
                                              ripple: false,
                                              height: "43",
                                            },
                                            on: { click: _vm.closeDelete },
                                          },
                                          [_vm._v("Cancel")]
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass:
                                              "\n                      font-weight-normal\n                      text-capitalize\n                      btn-ls btn-primary\n                      bg-gradient-primary\n                      py-3\n                      px-6\n                    ",
                                            attrs: {
                                              elevation: "0",
                                              ripple: false,
                                              height: "43",
                                            },
                                            on: {
                                              click: _vm.deleteItemConfirm,
                                            },
                                          },
                                          [_vm._v("OK")]
                                        ),
                                        _c("v-spacer"),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "item.name",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _c("div", { staticClass: "d-flex align-center ms-2" }, [
                          _c(
                            "span",
                            {
                              staticClass:
                                "text-sm font-weight-normal text-body",
                            },
                            [_vm._v(" " + _vm._s(item.name) + " ")]
                          ),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "item.email",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _c(
                          "span",
                          {
                            staticClass: "text-sm font-weight-normal text-body",
                          },
                          [_vm._v(" " + _vm._s(item.email) + " ")]
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.age",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _c(
                          "span",
                          {
                            staticClass: "text-sm font-weight-normal text-body",
                          },
                          [_vm._v(" " + _vm._s(item.age) + " ")]
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.salary",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _c(
                          "span",
                          {
                            staticClass: "text-sm font-weight-normal text-body",
                          },
                          [_vm._v(" " + _vm._s(item.salary) + " ")]
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.actions",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _c(
                          "v-btn",
                          {
                            staticClass: "btn-ls me-2 my-2 rounded-sm",
                            attrs: {
                              icon: "",
                              elevation: "0",
                              ripple: false,
                              height: "28",
                              "min-width": "36",
                              width: "36",
                              color: "#67748e",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.editItem(item)
                              },
                            },
                          },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "material-icons-round",
                                attrs: { size: "14" },
                              },
                              [_vm._v("construction")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "btn-ls me-2 my-2 rounded-sm",
                            attrs: {
                              icon: "",
                              elevation: "0",
                              ripple: false,
                              height: "28",
                              "min-width": "36",
                              width: "36",
                              color: "#67748e",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.deleteItem(item)
                              },
                            },
                          },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "material-icons-round",
                                attrs: { size: "14" },
                              },
                              [_vm._v("close")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "card-padding" },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex align-center",
                      attrs: { cols: "6", lg: "3" },
                    },
                    [
                      _c("span", { staticClass: "text-body me-3 text-sm" }, [
                        _vm._v("Items per page:"),
                      ]),
                      _c("v-text-field", {
                        staticClass:
                          "\n              font-size-input\n              placeholder-lighter\n              text-color-light\n              input-alternative input-focused-alternative input-icon\n            ",
                        attrs: {
                          "hide-details": "",
                          type: "number",
                          outlined: "",
                          min: "-1",
                          max: "15",
                          "background-color": "rgba(255,255,255,.9)",
                          color: "rgba(0,0,0,.6)",
                          light: "",
                          value: _vm.itemsPerPage,
                          placeholder: "Items per page",
                        },
                        on: {
                          input: function ($event) {
                            _vm.itemsPerPage = parseInt($event, 10)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "ml-auto d-flex justify-end",
                      attrs: { cols: "6" },
                    },
                    [
                      _c("v-pagination", {
                        staticClass: "pagination",
                        attrs: {
                          "prev-icon": "fa fa-angle-left",
                          "next-icon": "fa fa-angle-right",
                          color: "#D81B60",
                          length: _vm.pageCount,
                          circle: "",
                        },
                        model: {
                          value: _vm.page,
                          callback: function ($$v) {
                            _vm.page = $$v
                          },
                          expression: "page",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }