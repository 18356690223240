var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "card-shadow border-radius-xl" },
    [
      _c(
        "v-card-text",
        { staticClass: "px-0 py-0" },
        [
          _c("v-simple-table", {
            staticClass: "table border-radius-xl",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function () {
                  return [
                    _c("thead", [
                      _c("tr", [
                        _c(
                          "th",
                          {
                            staticClass:
                              "\n                text-left text-uppercase text-secondary text-xxs\n                font-weight-bolder\n                opacity-7\n              ",
                          },
                          [_vm._v(" Name ")]
                        ),
                        _c(
                          "th",
                          {
                            staticClass:
                              "\n                text-left text-uppercase text-secondary text-xxs\n                font-weight-bolder\n                opacity-7\n              ",
                          },
                          [_vm._v(" Function ")]
                        ),
                        _c(
                          "th",
                          {
                            staticClass:
                              "\n                text-left text-uppercase text-secondary text-xxs\n                font-weight-bolder\n                opacity-7\n              ",
                          },
                          [_vm._v(" Review ")]
                        ),
                        _c(
                          "th",
                          {
                            staticClass:
                              "\n                text-center text-uppercase text-secondary text-xxs\n                font-weight-bolder\n                opacity-7\n              ",
                          },
                          [_vm._v(" Email ")]
                        ),
                        _c(
                          "th",
                          {
                            staticClass:
                              "\n                text-center text-uppercase text-secondary text-xxs\n                font-weight-bolder\n                opacity-7\n              ",
                          },
                          [_vm._v(" Employed ")]
                        ),
                        _c(
                          "th",
                          {
                            staticClass:
                              "\n                text-center text-uppercase text-secondary text-xxs\n                font-weight-bolder\n                opacity-7\n              ",
                          },
                          [_vm._v(" ID ")]
                        ),
                      ]),
                    ]),
                    _c(
                      "tbody",
                      _vm._l(_vm.users, function (item, i) {
                        return _c("tr", { key: item.id }, [
                          _c(
                            "td",
                            {
                              class: {
                                "border-bottom": i != _vm.users.length - 1,
                              },
                            },
                            [
                              _c(
                                "v-list",
                                { staticClass: "py-0" },
                                [
                                  _c(
                                    "v-list-item",
                                    { staticClass: "px-0 py-1" },
                                    [
                                      _c(
                                        "v-list-item-avatar",
                                        {
                                          staticClass: "my-0 me-4",
                                          attrs: { rounded: "", size: 36 },
                                        },
                                        [
                                          _c("v-img", {
                                            staticClass: "rounded-circle",
                                            attrs: {
                                              alt: item.avatar,
                                              src: item.avatar,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c(
                                            "v-list-item-title",
                                            {
                                              staticClass:
                                                "mb-0 text-sm text-typo font-weight-normal",
                                            },
                                            [_vm._v(_vm._s(item.name))]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            {
                              staticClass: "text-sm text-secondary mb-0",
                              class: {
                                "border-bottom": i != _vm.users.length - 1,
                              },
                            },
                            [_vm._v(" " + _vm._s(item.function) + " ")]
                          ),
                          _c(
                            "td",
                            {
                              staticClass: "text-h5",
                              class: {
                                "border-bottom": i != _vm.users.length - 1,
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex align-center" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "me-2 text-sm mt-n1",
                                      class: "text-" + item.reviewColor,
                                    },
                                    [_vm._v("●")]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "text-dark text-xs" },
                                    [_vm._v(_vm._s(item.review))]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "td",
                            {
                              staticClass: "text-center",
                              class: {
                                "border-bottom": i != _vm.users.length - 1,
                              },
                            },
                            [
                              _c(
                                "p",
                                { staticClass: "text-secondary mb-0 text-sm" },
                                [_vm._v(_vm._s(item.email))]
                              ),
                            ]
                          ),
                          _c(
                            "td",
                            {
                              staticClass: "text-center",
                              class: {
                                "border-bottom": i != _vm.users.length - 1,
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "text-secondary text-sm" },
                                [_vm._v(_vm._s(item.employed))]
                              ),
                            ]
                          ),
                          _c(
                            "td",
                            {
                              staticClass: "text-center",
                              class: {
                                "border-bottom": i != _vm.users.length - 1,
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "text-secondary text-sm" },
                                [_vm._v(_vm._s(item.id))]
                              ),
                            ]
                          ),
                        ])
                      }),
                      0
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }