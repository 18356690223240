var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "mx-auto",
                  attrs: { lg: "8", md: "10", cols: "12" },
                },
                [
                  _c("div", { staticClass: "text-center mb-16" }, [
                    _c(
                      "h3",
                      {
                        staticClass: "text-h3 text-typo font-weight-bold mt-9",
                      },
                      [_vm._v(" Add new Product ")]
                    ),
                    _c(
                      "h5",
                      {
                        staticClass:
                          "text-h5 text-secondary font-weight-normal",
                      },
                      [
                        _vm._v(
                          " This information will let us know more about you. "
                        ),
                      ]
                    ),
                  ]),
                  _c(
                    "v-card",
                    {
                      staticClass:
                        "shadow border-radius-xl mt-16 mb-10 mx-auto",
                    },
                    [
                      _c(
                        "v-stepper",
                        {
                          staticClass: "bg-transparent overflow-visible",
                          attrs: { "alt-labels": "", elevation: "0" },
                          model: {
                            value: _vm.e1,
                            callback: function ($$v) {
                              _vm.e1 = $$v
                            },
                            expression: "e1",
                          },
                        },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "mx-auto pt-1",
                                  attrs: { cols: "12" },
                                },
                                [
                                  _c(
                                    "v-stepper-header",
                                    {
                                      staticClass:
                                        "\n                    bg-gradient-primary\n                    shadow-primary\n                    border-radius-lg\n                    mx-4\n                    mt-n6\n                  ",
                                    },
                                    [
                                      _c(
                                        "v-stepper-step",
                                        {
                                          attrs: {
                                            step: "1",
                                            color: "#344767",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "\n                        text-white\n                        opacity-7\n                        text-xs\n                        font-weight-normal\n                        text-uppercase\n                      ",
                                            },
                                            [_vm._v("1. Product Info")]
                                          ),
                                        ]
                                      ),
                                      _c("v-divider"),
                                      _c(
                                        "v-stepper-step",
                                        {
                                          attrs: {
                                            step: "2",
                                            color: "#344767",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "\n                        text-white\n                        opacity-7\n                        text-xs\n                        font-weight-normal\n                        text-uppercase\n                      ",
                                            },
                                            [_vm._v("2. Media")]
                                          ),
                                        ]
                                      ),
                                      _c("v-divider"),
                                      _c(
                                        "v-stepper-step",
                                        {
                                          attrs: {
                                            step: "3",
                                            color: "#344767",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "\n                        text-white\n                        opacity-7\n                        text-xs\n                        font-weight-normal\n                        text-uppercase\n                      ",
                                            },
                                            [_vm._v("3. Socials")]
                                          ),
                                        ]
                                      ),
                                      _c("v-divider"),
                                      _c(
                                        "v-stepper-step",
                                        {
                                          attrs: {
                                            step: "4",
                                            color: "#344767",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "\n                        text-white\n                        opacity-7\n                        text-xs\n                        font-weight-normal\n                        text-uppercase\n                      ",
                                            },
                                            [_vm._v("4. Pricing")]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "mx-auto",
                                  attrs: { cols: "12" },
                                },
                                [
                                  _c(
                                    "v-stepper-items",
                                    {
                                      staticClass:
                                        "border-radius-xl overflow-hidden",
                                    },
                                    [
                                      _c(
                                        "v-stepper-content",
                                        {
                                          staticClass:
                                            "bg-white border-radius-xl px-6 pt-0",
                                          attrs: { step: "1" },
                                        },
                                        [
                                          _c("v-card", [
                                            _c(
                                              "div",
                                              [
                                                _c(
                                                  "h5",
                                                  {
                                                    staticClass:
                                                      "font-weight-bolder text-h5 text-typo mb-0",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " Product Information "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "v-row",
                                                  { staticClass: "mt-0" },
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          sm: "6",
                                                          cols: "12",
                                                        },
                                                      },
                                                      [
                                                        _c("v-text-field", {
                                                          staticClass:
                                                            "font-size-input input-style",
                                                          attrs: {
                                                            label: "Name",
                                                            placeholder:
                                                              "eg. Off-White",
                                                            color: "#e91e63",
                                                            required: "",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          sm: "6",
                                                          cols: "12",
                                                        },
                                                      },
                                                      [
                                                        _c("v-text-field", {
                                                          staticClass:
                                                            "font-size-input input-style",
                                                          attrs: {
                                                            label: "Weight",
                                                            placeholder:
                                                              "eg. 42",
                                                            color: "#e91e63",
                                                            required: "",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-row",
                                                  { staticClass: "mt-0" },
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticClass: "mb-2",
                                                        attrs: {
                                                          sm: "6",
                                                          cols: "12",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "text-sm text-body ms-1",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Description"
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "\n                                form-text\n                                text-muted text-xs\n                                ms-1\n                                d-inline\n                              ",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " (optional) "
                                                            ),
                                                          ]
                                                        ),
                                                        _c("html-editor"),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          sm: "6",
                                                          cols: "12",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "text-sm text-body",
                                                          },
                                                          [_vm._v("Category")]
                                                        ),
                                                        _c("v-select", {
                                                          staticClass:
                                                            "\n                                font-size-input\n                                input-style\n                                placeholder-light\n                                border-radius-md\n                                select-style\n                                mt-0\n                              ",
                                                          attrs: {
                                                            items:
                                                              _vm.categories,
                                                            value: "Clothing",
                                                            color: "#e91e63",
                                                            "single-line": "",
                                                            height: "38",
                                                          },
                                                        }),
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "text-sm text-body",
                                                          },
                                                          [_vm._v("Sizes")]
                                                        ),
                                                        _c("v-select", {
                                                          staticClass:
                                                            "\n                                font-size-input\n                                input-style\n                                placeholder-light\n                                border-radius-md\n                                select-style\n                                mt-0\n                              ",
                                                          attrs: {
                                                            items: _vm.sizes,
                                                            value: "Medium",
                                                            color: "#e91e63",
                                                            "single-line": "",
                                                            height: "38",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "text-end" },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    staticClass:
                                                      "\n                            font-weight-bold\n                            text-xs\n                            btn-default\n                            bg-gradient-default\n                            py-5\n                            px-6\n                            mt-6\n                            mb-2\n                            me-2\n                          ",
                                                    attrs: {
                                                      ripple: false,
                                                      elevation: 0,
                                                      color: "primary",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.e1 = 2
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" Next ")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-stepper-content",
                                        {
                                          staticClass:
                                            "bg-white border-radius-xl px-6 pt-4",
                                          attrs: { step: "2" },
                                        },
                                        [
                                          _c("v-card", [
                                            _c(
                                              "div",
                                              [
                                                _c(
                                                  "h5",
                                                  {
                                                    staticClass:
                                                      "font-weight-bolder text-h5 text-typo mb-0",
                                                  },
                                                  [_vm._v(" Media ")]
                                                ),
                                                _c(
                                                  "v-row",
                                                  { staticClass: "mt-2" },
                                                  [
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "12" } },
                                                      [
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "text-sm text-body ms-1",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Product images"
                                                            ),
                                                          ]
                                                        ),
                                                        _c("dropzone", {
                                                          model: {
                                                            value:
                                                              _vm.fileSingle,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.fileSingle =
                                                                $$v
                                                            },
                                                            expression:
                                                              "fileSingle",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "d-flex" },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    staticClass:
                                                      "\n                            font-weight-bold\n                            placeholder-lighter\n                            text-xs text-dark\n                            shadow-0\n                            btn-outline-secondary\n                            bg-transparent\n                            py-5\n                            px-6\n                            mt-6\n                            ma-2\n                          ",
                                                    attrs: {
                                                      ripple: false,
                                                      elevation: 0,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.e1 = 1
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" Prev ")]
                                                ),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    staticClass:
                                                      "\n                            font-weight-bold\n                            text-xs\n                            btn-default\n                            bg-gradient-default\n                            py-5\n                            px-6\n                            mt-6\n                            mb-2\n                            me-2\n                            ms-auto\n                          ",
                                                    attrs: {
                                                      ripple: false,
                                                      elevation: 0,
                                                      color: "primary",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.e1 = 3
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" Next ")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-stepper-content",
                                        {
                                          staticClass:
                                            "bg-white border-radius-xl px-6 pt-4",
                                          attrs: { step: "3" },
                                        },
                                        [
                                          _c("v-card", [
                                            _c(
                                              "div",
                                              [
                                                _c(
                                                  "h5",
                                                  {
                                                    staticClass:
                                                      "font-weight-bolder text-h5 text-typo mb-0",
                                                  },
                                                  [_vm._v(" Socials ")]
                                                ),
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "12" } },
                                                      [
                                                        _c("v-text-field", {
                                                          staticClass:
                                                            "font-size-input input-style",
                                                          attrs: {
                                                            label:
                                                              "Shopify Handle",
                                                            placeholder:
                                                              "@soft",
                                                            color: "#e91e63",
                                                            required: "",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-row",
                                                  { staticClass: "mt-0" },
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticClass: "py-0",
                                                        attrs: { cols: "12" },
                                                      },
                                                      [
                                                        _c("v-text-field", {
                                                          staticClass:
                                                            "font-size-input input-style",
                                                          attrs: {
                                                            label:
                                                              "Facebook Account",
                                                            color: "#e91e63",
                                                            required: "",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-row",
                                                  { staticClass: "mt-0" },
                                                  [
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "12" } },
                                                      [
                                                        _c("v-text-field", {
                                                          staticClass:
                                                            "font-size-input input-style",
                                                          attrs: {
                                                            label:
                                                              "Instagram Account",
                                                            color: "#e91e63",
                                                            required: "",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "d-flex" },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    staticClass:
                                                      "\n                            font-weight-bold\n                            placeholder-lighter\n                            text-xs text-dark\n                            shadow-0\n                            btn-outline-secondary\n                            bg-transparent\n                            py-5\n                            px-6\n                            mt-6\n                            ma-2\n                          ",
                                                    attrs: {
                                                      ripple: false,
                                                      elevation: 0,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.e1 = 2
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" Prev ")]
                                                ),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    staticClass:
                                                      "\n                            font-weight-bold\n                            text-xs\n                            btn-default\n                            bg-gradient-default\n                            py-5\n                            px-6\n                            mt-6\n                            mb-2\n                            me-2\n                            ms-auto\n                          ",
                                                    attrs: {
                                                      ripple: false,
                                                      elevation: 0,
                                                      color: "primary",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.e1 = 4
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" Next ")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-stepper-content",
                                        {
                                          staticClass:
                                            "bg-white border-radius-xl px-6 pt-4",
                                          attrs: { step: "4" },
                                        },
                                        [
                                          _c("v-card", [
                                            _c(
                                              "div",
                                              [
                                                _c(
                                                  "h5",
                                                  {
                                                    staticClass:
                                                      "font-weight-bolder text-h5 text-typo mb-0",
                                                  },
                                                  [_vm._v(" Pricing ")]
                                                ),
                                                _c(
                                                  "v-row",
                                                  { staticClass: "mt-0" },
                                                  [
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "3" } },
                                                      [
                                                        _c("v-text-field", {
                                                          staticClass:
                                                            "font-size-input input-style",
                                                          attrs: {
                                                            label: "Price",
                                                            placeholder:
                                                              "99.00",
                                                            color: "#e91e63",
                                                            required: "",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "4" } },
                                                      [
                                                        _c("v-select", {
                                                          staticClass:
                                                            "\n                                font-size-input\n                                input-style\n                                placeholder-light\n                                border-radius-md\n                              ",
                                                          attrs: {
                                                            items: _vm.coins,
                                                            value: "USD",
                                                            color: "#e91e63",
                                                            "single-line": "",
                                                            height: "33",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "5" } },
                                                      [
                                                        _c("v-text-field", {
                                                          staticClass:
                                                            "font-size-input input-style",
                                                          attrs: {
                                                            label: "SKU",
                                                            placeholder:
                                                              "71283476591",
                                                            color: "#e91e63",
                                                            required: "",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-row",
                                                  { staticClass: "mt-2" },
                                                  [
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "12" } },
                                                      [
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "text-sm text-body ms-1 mb-0",
                                                          },
                                                          [_vm._v("Tags")]
                                                        ),
                                                        _c("v-select", {
                                                          staticClass:
                                                            "\n                                font-size-input\n                                input-style\n                                placeholder-light\n                                select-style\n                              ",
                                                          attrs: {
                                                            items: _vm.tags,
                                                            chips: "",
                                                            multiple: "",
                                                            height: "46",
                                                          },
                                                          scopedSlots: _vm._u([
                                                            {
                                                              key: "selection",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var item =
                                                                  ref.item
                                                                return [
                                                                  _c(
                                                                    "v-chip",
                                                                    {
                                                                      staticClass:
                                                                        "py-1 px-2 my-0",
                                                                      attrs: {
                                                                        label:
                                                                          "",
                                                                        color:
                                                                          "bg-default",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "text-white text-caption ls-0",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              item
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ]),
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "d-flex" },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    staticClass:
                                                      "\n                            font-weight-bold\n                            placeholder-lighter\n                            text-xs text-dark\n                            shadow-0\n                            btn-outline-secondary\n                            bg-transparent\n                            py-5\n                            px-6\n                            mt-6\n                            ma-2\n                          ",
                                                    attrs: { ripple: false },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.e1 = 3
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" Prev ")]
                                                ),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    staticClass:
                                                      "\n                            font-weight-bold\n                            text-xs\n                            btn-default\n                            bg-gradient-default\n                            py-5\n                            px-6\n                            mt-6\n                            mb-0\n                            ms-auto\n                          ",
                                                    attrs: {
                                                      ripple: false,
                                                      color: "primary",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.e1 = 4
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" Send ")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }