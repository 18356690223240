var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    {
      staticClass:
        "\n    justify-center\n    d-flex\n    mt-n16\n    bg-white\n    position-relative\n    border-radius-xl\n    shadow\n    px-4\n  ",
    },
    [
      _c(
        "v-col",
        { staticClass: "mx-auto text-center mb-6", attrs: { cols: "12" } },
        [
          _c(
            "v-tabs",
            {
              staticClass: "border-radius-lg mt-8",
              attrs: { "hide-slider": "" },
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            },
            [
              _c("v-tabs-slider"),
              _c(
                "v-tab",
                {
                  staticClass: "ms-auto",
                  attrs: { ripple: false, href: "#tab-1" },
                },
                [_vm._v(" Monthly ")]
              ),
              _c(
                "v-tab",
                {
                  staticClass: "me-auto",
                  attrs: { ripple: false, href: "#tab-2" },
                },
                [_vm._v(" Annual ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-container",
        [
          _c(
            "v-row",
            _vm._l(_vm.plans, function (plan, i) {
              return _c(
                "v-col",
                { key: plan.title, attrs: { lg: "4" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "shadow-lg border-radius-xl",
                      class: i == 1 ? "bg-gradient-default" : "",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "px-6 pb-4 text-center" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "\n                border-radius-xl\n                font-weight-bolder\n                px-8\n                py-3\n                badge-font-size\n                ms-auto\n                mt-n6\n              ",
                              class: i == 1 ? "text-white" : "text-dark",
                              attrs: {
                                elevation: "0",
                                small: "",
                                ripple: false,
                                height: "21",
                                color: i == 1 ? "#e91e63" : "#e9ecef",
                              },
                            },
                            [_vm._v(_vm._s(plan.title))]
                          ),
                          _c(
                            "h1",
                            {
                              staticClass: "text-h1 font-weight-bold mt-4",
                              class: i == 1 ? "text-white" : "text-typo",
                            },
                            [
                              _c(
                                "small",
                                { staticClass: "align-top text-lg mt-2" },
                                [_vm._v("$")]
                              ),
                              _vm._v(_vm._s(plan.price)),
                              _c(
                                "small",
                                {
                                  staticClass: "text-h6 font-weight-bold mt-2",
                                },
                                [_vm._v("/mo")]
                              ),
                            ]
                          ),
                          _c(
                            "v-list",
                            {
                              staticClass: "py-6 text-lg-start text-center",
                              attrs: { color: "transparent" },
                            },
                            _vm._l(plan.features, function (item) {
                              return _c(
                                "v-list-item",
                                { key: item.title, staticClass: "px-2" },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "material-icons-round",
                                      class:
                                        i == 1 ? "text-white" : "text-muted",
                                      attrs: { size: "20" },
                                    },
                                    [_vm._v(" " + _vm._s(item.icon) + " ")]
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    { staticClass: "text-left" },
                                    [
                                      _c(
                                        "v-list-item-title",
                                        {
                                          staticClass:
                                            "ps-0 font-weight-light ms-4",
                                          class:
                                            i == 1
                                              ? "text-white"
                                              : "text-muted",
                                          domProps: {
                                            textContent: _vm._s(item.title),
                                          },
                                        },
                                        [_vm._v(" >")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "\n                font-weight-bold\n                btn-primary\n                py-3\n                text-xs\n                px-6\n                ls-0\n                w-100\n                border-radius-md\n              ",
                              class: "bg-gradient-" + plan.buttonColor,
                              attrs: {
                                elevation: "0",
                                ripple: false,
                                height: "43",
                              },
                            },
                            [
                              _vm._v(" " + _vm._s(plan.buttonText) + " "),
                              _c("v-icon", { attrs: { size: "12" } }, [
                                _vm._v("fas fa-arrow-right ms-2"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "v-col",
        { staticClass: "mx-auto text-center mt-10", attrs: { cols: "8" } },
        [
          _c(
            "h6",
            { staticClass: "opacity-5 text-h6 text-typo font-weight-bold" },
            [_vm._v(" More than 50+ brands trust Material ")]
          ),
        ]
      ),
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "v-row",
            { staticClass: "mt-1 mx-7" },
            _vm._l(_vm.brands, function (brand) {
              return _c(
                "v-col",
                {
                  key: brand.name,
                  staticClass: "mb-6",
                  attrs: { lg: "2", md: "4", cols: "6" },
                },
                [
                  _c("v-img", {
                    staticClass: "opacity-9",
                    attrs: {
                      alt: brand.name,
                      src: brand.image,
                      width: "200px",
                    },
                  }),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "v-col",
        { staticClass: "mt-5 mx-auto text-center", attrs: { cols: "12" } },
        [
          _c(
            "h2",
            { staticClass: "text-typo text-h2 font-weight-bolder mb-2" },
            [_vm._v(" Frequently Asked Questions ")]
          ),
          _c("p", { staticClass: "text-body font-weight-light" }, [
            _vm._v(
              " A lot of people don't appreciate the moment until it’s passed. I'm not trying my hardest, and I'm not trying to do "
            ),
          ]),
        ]
      ),
      _c(
        "v-col",
        { staticClass: "mx-auto", attrs: { lg: "8" } },
        [
          _c(
            "v-expansion-panels",
            { staticClass: "mb-8", attrs: { flat: "", color: "#ddd" } },
            _vm._l(_vm.panels, function (panel) {
              return _c(
                "v-expansion-panel",
                { key: panel.title, staticClass: "bg-transparent" },
                [
                  _c(
                    "v-expansion-panel-header",
                    { staticClass: "border-bottom px-0" },
                    [
                      _c(
                        "h5",
                        { staticClass: "text-h5 font-weight-bold text-body" },
                        [_vm._v(" " + _vm._s(panel.title) + " ")]
                      ),
                    ]
                  ),
                  _c("v-expansion-panel-content", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "text-body font-weight-light text-sm opacity-8",
                      },
                      [_vm._v(" " + _vm._s(panel.description) + " ")]
                    ),
                  ]),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }