var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "card-shadow border-radius-xl mt-6",
      attrs: { id: "basic" },
    },
    [
      _c("div", { staticClass: "px-6 py-6" }, [
        _c("h5", { staticClass: "text-h5 font-weight-bold text-typo" }, [
          _vm._v("Basic Info"),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "px-6 pb-6 pt-0" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("v-text-field", {
                    staticClass: "font-size-input input-style",
                    attrs: { color: "#e91e63", label: "Name" },
                    model: {
                      value: _vm.userFirstName,
                      callback: function ($$v) {
                        _vm.userFirstName = $$v
                      },
                      expression: "userFirstName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("v-text-field", {
                    staticClass: "font-size-input input-style",
                    attrs: { color: "#e91e63", label: "Company" },
                    model: {
                      value: _vm.customer_name,
                      callback: function ($$v) {
                        _vm.customer_name = $$v
                      },
                      expression: "customer_name",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mt-0" },
            [
              _c(
                "v-col",
                { attrs: { sm: "4", cols: "12" } },
                [
                  _c("label", { staticClass: "text-sm text-body" }, [
                    _vm._v("I'm"),
                  ]),
                  _c("v-select", {
                    staticClass: "font-size-input input-style",
                    attrs: {
                      items: _vm.gender,
                      label: "Male",
                      color: "#e91e63",
                      "single-line": "",
                      height: "36",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { sm: "8" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "5" } },
                        [
                          _c("label", { staticClass: "text-sm text-body" }, [
                            _vm._v("Birth Date"),
                          ]),
                          _c("v-select", {
                            staticClass: "font-size-input input-style",
                            attrs: {
                              items: _vm.months,
                              label: "February",
                              color: "#e91e63",
                              "single-line": "",
                              height: "36",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { sm: "4", cols: "3" } },
                        [
                          _c("v-select", {
                            staticClass: "font-size-input input-style mt-7",
                            attrs: {
                              items: _vm.days,
                              label: "1",
                              color: "#e91e63",
                              "single-line": "",
                              height: "36",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { sm: "3", cols: "4" } },
                        [
                          _c("v-select", {
                            staticClass: "font-size-input input-style mt-7",
                            attrs: {
                              items: _vm.years,
                              label: "2022",
                              color: "#e91e63",
                              "single-line": "",
                              height: "36",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "py-0", attrs: { cols: "6" } },
                [
                  _c("v-text-field", {
                    staticClass: "font-size-input input-style",
                    attrs: { color: "#e91e63", label: "Email" },
                    model: {
                      value: _vm.user.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.user, "email", $$v)
                      },
                      expression: "user.email",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "py-0", attrs: { cols: "6" } },
                [
                  _c("v-text-field", {
                    staticClass: "font-size-input input-style",
                    attrs: { color: "#e91e63", label: "Confirmation Email" },
                    model: {
                      value: _vm.user.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.user, "email", $$v)
                      },
                      expression: "user.email",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "py-0", attrs: { cols: "6" } },
                [
                  _c("v-text-field", {
                    staticClass: "font-size-input input-style",
                    attrs: {
                      color: "#e91e63",
                      label: "Your Location",
                      placeholder: "Sydney, A",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "py-0", attrs: { cols: "6" } },
                [
                  _c("v-text-field", {
                    staticClass: "font-size-input input-style",
                    attrs: {
                      color: "#e91e63",
                      label: "Phone Number",
                      placeholder: "+40 735 631 620",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pb-0", attrs: { cols: "6" } },
                [
                  _c("label", { staticClass: "text-sm text-body" }, [
                    _vm._v("Language"),
                  ]),
                  _c("v-select", {
                    staticClass: "font-size-input input-style",
                    attrs: {
                      items: _vm.languages,
                      label: "English",
                      color: "#e91e63",
                      "single-line": "",
                      height: "36",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("label", { staticClass: "text-sm text-body" }, [
                    _vm._v("Skills"),
                  ]),
                  _c("v-select", {
                    staticClass: "font-size-input input-style",
                    attrs: {
                      items: _vm.skills,
                      color: "#e91e63",
                      "single-line": "",
                      chips: "",
                      multiple: "",
                      height: "36",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }