var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "card-shadow border-radius-xl z-index-2" },
    [
      _c(
        "div",
        { staticClass: "px-4 pt-2 pb-0 d-flex" },
        [
          _c(
            "v-avatar",
            {
              staticClass: "shadow",
              attrs: {
                color: "bg-gradient-info shadow-info border-radius-xl mt-n6",
                height: "64",
                width: "64",
              },
            },
            [
              _c(
                "v-icon",
                {
                  staticClass: "material-icons-round text-white",
                  attrs: { size: "24" },
                },
                [_vm._v("insights")]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "ms-4" }, [
            _c("h6", { staticClass: "text-h6 text-typo font-weight-bold" }, [
              _vm._v(" Line chart without dots "),
            ]),
            _c(
              "p",
              { staticClass: "font-weight-light text-secondary text-sm" },
              [_vm._v(" Visits from devices ")]
            ),
          ]),
          _c(
            "div",
            { staticClass: "my-2 ms-auto" },
            [
              _c("v-badge", {
                attrs: {
                  bottom: "",
                  bordered: "",
                  color: "#e91e63",
                  dot: "",
                  "offset-x": "4",
                  "offset-y": "9",
                },
              }),
              _c("span", { staticClass: "text-dark text-sm ms-3" }, [
                _vm._v("Mobile Apps"),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "my-2 ms-5" },
            [
              _c("v-badge", {
                attrs: {
                  bottom: "",
                  bordered: "",
                  color: "#344767",
                  dot: "",
                  "offset-x": "4",
                  "offset-y": "9",
                },
              }),
              _c("span", { staticClass: "text-dark text-sm ms-3" }, [
                _vm._v("Websites"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "card-padding pt-0 px-4" }, [
        _c("div", { staticClass: "chart" }, [
          _c("canvas", {
            staticClass: "chart-canvas",
            attrs: { id: "line-chart-without-dots", height: "300" },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }