var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "py-6", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { lg: "4", sm: "8" } },
            [
              _c(
                "v-tabs",
                {
                  staticClass: "text-left",
                  attrs: { "background-color": "transparent" },
                },
                [
                  _c("v-tabs-slider"),
                  _c("v-tab", { attrs: { ripple: false, href: "#tab-1" } }, [
                    _c("span", { staticClass: "ms-1" }, [_vm._v("Messages")]),
                  ]),
                  _c("v-tab", { attrs: { ripple: false, href: "#tab-2" } }, [
                    _c("span", { staticClass: "ms-1" }, [_vm._v("Social")]),
                  ]),
                  _c("v-tab", { attrs: { ripple: false, href: "#tab-3" } }, [
                    _c("span", { staticClass: "ms-1" }, [
                      _vm._v("Notifications"),
                    ]),
                  ]),
                  _c("v-tab", { attrs: { ripple: false, href: "#tab-4" } }, [
                    _c("span", { staticClass: "ms-1" }, [_vm._v("Backup")]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "px-4" },
        [
          _c(
            "v-col",
            { attrs: { lg: "8" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { lg: "6" } },
                    [
                      _c(
                        "v-card",
                        { staticClass: "card-shadow border-radius-xl" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "overflow-hidden position-relative border-radius-xl",
                            },
                            [
                              _c("img", {
                                staticClass:
                                  "\n                  position-absolute\n                  opacity-2\n                  start-0\n                  top-0\n                  w-100\n                  z-index-1\n                  h-100\n                ",
                                attrs: {
                                  src: require("@/assets/img/illustrations/pattern-tree.svg"),
                                },
                              }),
                              _c("span", {
                                staticClass:
                                  "mask bg-gradient-default opacity-10",
                              }),
                              _c(
                                "div",
                                { staticClass: "px-4 py-4 position-relative" },
                                [
                                  _c("i", {
                                    staticClass: "fas fa-wifi text-white pa-2",
                                    attrs: { "aria-hidden": "true" },
                                  }),
                                  _c(
                                    "h5",
                                    {
                                      staticClass:
                                        "text-white mt-6 mb-12 pb-2 text-h5",
                                    },
                                    [_vm._v(" 4562   1122   4594   7852 ")]
                                  ),
                                  _c("div", { staticClass: "d-flex" }, [
                                    _c("div", { staticClass: "d-flex" }, [
                                      _c("div", { staticClass: "me-4" }, [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "\n                          text-white text-sm\n                          font-weight-light\n                          opacity-8\n                          mb-0\n                        ",
                                          },
                                          [_vm._v(" Card Holder ")]
                                        ),
                                        _c(
                                          "h6",
                                          {
                                            staticClass:
                                              "text-white text-h6 mb-0",
                                          },
                                          [_vm._v("Jack Peterson")]
                                        ),
                                      ]),
                                      _c("div", [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "\n                          text-white text-sm\n                          font-weight-light\n                          opacity-8\n                          mb-0\n                        ",
                                          },
                                          [_vm._v(" Expires ")]
                                        ),
                                        _c(
                                          "h6",
                                          {
                                            staticClass:
                                              "text-white text-h6 mb-0",
                                          },
                                          [_vm._v("11/22")]
                                        ),
                                      ]),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "\n                      ms-auto\n                      w-10\n                      d-flex\n                      align-items-end\n                      justify-content-end\n                      mt-auto\n                    ",
                                      },
                                      [
                                        _c("v-img", {
                                          attrs: {
                                            src: require("@/assets/img/logos/mastercard.png"),
                                            alt: "logo",
                                            width: "22",
                                            height: "22",
                                            contain: "",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { lg: "6" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { md: "6" } },
                            [
                              _c(
                                "v-card",
                                { staticClass: "card-shadow border-radius-xl" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "mx-6 pa-4 text-center" },
                                    [
                                      _c(
                                        "v-avatar",
                                        {
                                          staticClass:
                                            "shadow border-radius-lg",
                                          attrs: {
                                            color: "bg-gradient-primary",
                                            width: "64",
                                            height: "64",
                                            "min-width": "64",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass:
                                                "text-white material-icons-round",
                                              attrs: { size: "24" },
                                            },
                                            [_vm._v(" account_balance ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "px-4 pb-4 text-center" },
                                    [
                                      _c(
                                        "h6",
                                        {
                                          staticClass:
                                            "\n                      text-center text-h6\n                      mb-0\n                      text-typo\n                      font-weight-bold\n                    ",
                                        },
                                        [_vm._v(" Salary ")]
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "text-xs text-body" },
                                        [_vm._v("Belong Interactive")]
                                      ),
                                      _c("hr", {
                                        staticClass: "horizontal dark my-4",
                                      }),
                                      _c(
                                        "h5",
                                        {
                                          staticClass:
                                            "mb-0 text-h5 font-weight-bold text-typo",
                                        },
                                        [_vm._v(" +$2000 ")]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { md: "6" } },
                            [
                              _c(
                                "v-card",
                                { staticClass: "card-shadow border-radius-xl" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "mx-6 pa-4 text-center" },
                                    [
                                      _c(
                                        "v-avatar",
                                        {
                                          staticClass:
                                            "shadow border-radius-lg",
                                          attrs: {
                                            color: "bg-gradient-primary",
                                            width: "64",
                                            height: "64",
                                            "min-width": "64",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass:
                                                "text-white material-icons-round",
                                              attrs: { size: "24" },
                                            },
                                            [_vm._v(" account_balance_wallet ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "px-4 pb-4 text-center" },
                                    [
                                      _c(
                                        "h6",
                                        {
                                          staticClass:
                                            "\n                      text-center text-h6\n                      mb-0\n                      text-typo\n                      font-weight-bold\n                    ",
                                        },
                                        [_vm._v(" Paypal ")]
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "text-xs text-body" },
                                        [_vm._v("Freelance Payment")]
                                      ),
                                      _c("hr", {
                                        staticClass: "horizontal dark my-4",
                                      }),
                                      _c(
                                        "h5",
                                        {
                                          staticClass:
                                            "mb-0 text-h5 font-weight-bold text-typo",
                                        },
                                        [_vm._v(" $455.00 ")]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { md: "12" } },
                    [
                      _c(
                        "v-card",
                        { staticClass: "card-shadow border-radius-xl" },
                        [
                          _c(
                            "div",
                            { staticClass: "px-4 py-4 d-flex align-center" },
                            [
                              _c(
                                "h6",
                                {
                                  staticClass:
                                    "mb-0 text-typo text-h6 font-weight-bold",
                                },
                                [_vm._v(" Payment Method ")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "\n                  font-weight-bolder\n                  btn-default\n                  bg-gradient-default\n                  py-5\n                  px-6\n                  ms-auto\n                ",
                                  attrs: {
                                    ripple: false,
                                    elevation: "0",
                                    color: "#fff",
                                    small: "",
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "me-2",
                                      attrs: { size: "12" },
                                    },
                                    [_vm._v("fa fa-plus")]
                                  ),
                                  _vm._v(" Add new card "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "px-4 pb-4" },
                            [
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { md: "6" } }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "pa-5 border border-radius-lg d-flex",
                                      },
                                      [
                                        _c(
                                          "v-avatar",
                                          {
                                            staticClass: "me-4",
                                            attrs: { size: "36", rounded: "" },
                                          },
                                          [
                                            _c("v-img", {
                                              attrs: {
                                                src: require("@/assets/img/logos/mastercard.png"),
                                                alt: "logo",
                                                "max-width": "80",
                                                contain: "",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "h6",
                                          {
                                            staticClass:
                                              "mb-0 text-h6 text-typo mt-2",
                                          },
                                          [
                                            _vm._v(
                                              " ****   ****   ****   7852 "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { top: "" },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "activator",
                                                fn: function (ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "v-icon",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            attrs: {
                                                              size: "16",
                                                            },
                                                          },
                                                          "v-icon",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          " fas fa-pencil-alt ms-auto text-dark cursor-pointer "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ]),
                                          },
                                          [_c("span", [_vm._v("Edit Card")])]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c("v-col", { attrs: { md: "6" } }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "pa-5 border border-radius-lg d-flex",
                                      },
                                      [
                                        _c(
                                          "v-avatar",
                                          {
                                            staticClass: "me-4",
                                            attrs: { size: "36", rounded: "" },
                                          },
                                          [
                                            _c("v-img", {
                                              attrs: {
                                                src: require("@/assets/img/logos/visa.png"),
                                                alt: "logo",
                                                "max-width": "80",
                                                contain: "",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "h6",
                                          {
                                            staticClass:
                                              "mb-0 text-h6 text-typo mt-2",
                                          },
                                          [
                                            _vm._v(
                                              " ****   ****   ****   5248 "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { top: "" },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "activator",
                                                fn: function (ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "v-icon",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            attrs: {
                                                              size: "16",
                                                            },
                                                          },
                                                          "v-icon",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          " fas fa-pencil-alt ms-auto text-dark cursor-pointer "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ]),
                                          },
                                          [_c("span", [_vm._v("Edit Card")])]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { lg: "4" } },
            [
              _c("v-card", { staticClass: "card-shadow border-radius-xl" }, [
                _c(
                  "div",
                  { staticClass: "px-4 pt-4 d-flex align-center" },
                  [
                    _c(
                      "h6",
                      {
                        staticClass: "mb-0 text-h6 font-weight-bold text-typo",
                      },
                      [_vm._v("Invoices")]
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass:
                          "font-weight-bolder btn-outline-primary py-4 px-4 ms-auto",
                        attrs: {
                          ripple: false,
                          elevation: "0",
                          color: "#fff",
                          small: "",
                        },
                      },
                      [_vm._v(" View all ")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "px-4 pb-3 pt-3" },
                  _vm._l(_vm.invoices, function (invoice) {
                    return _c(
                      "div",
                      { key: invoice.date },
                      [
                        _c(
                          "v-list-item",
                          {
                            key: invoice.id,
                            staticClass: "px-0 pt-2 pb-3 justify-between",
                          },
                          [
                            _c("v-list-item-content", { staticClass: "py-0" }, [
                              _c("div", { staticClass: "d-flex flex-column" }, [
                                _c(
                                  "h6",
                                  {
                                    staticClass:
                                      "mb-1 text-dark font-weight-bold text-sm",
                                  },
                                  [_vm._v(" " + _vm._s(invoice.date) + " ")]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "text-xs text-body" },
                                  [_vm._v(_vm._s(invoice.id))]
                                ),
                              ]),
                            ]),
                            _c(
                              "v-list-item-content",
                              { staticClass: "py-0 text-end" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex align-center text-sm text-body",
                                  },
                                  [
                                    _c("span", { staticClass: "ms-auto" }, [
                                      _vm._v(_vm._s(invoice.value)),
                                    ]),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass:
                                          "text-dark font-weight-bolder py-6 px-5 shadow-0",
                                        attrs: {
                                          color: "#fff",
                                          small: "",
                                          simple: "",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass:
                                              "rotate-180 material-icons-round text-lg me-2",
                                            attrs: { size: "18" },
                                          },
                                          [_vm._v("picture_as_pdf")]
                                        ),
                                        _vm._v(" Pdf "),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "px-4" },
        [
          _c(
            "v-col",
            { attrs: { md: "7" } },
            [
              _c("v-card", { staticClass: "card-shadow border-radius-xl" }, [
                _c("div", { staticClass: "px-4 pt-5" }, [
                  _c(
                    "h6",
                    { staticClass: "mb-0 text-typo text-h6 font-weight-bold" },
                    [_vm._v(" Billing Information ")]
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "px-4 pt-6 pb-1" },
                  _vm._l(_vm.billings, function (billing) {
                    return _c(
                      "div",
                      { key: billing.name },
                      [
                        _c(
                          "v-list-item",
                          {
                            key: billing.vat,
                            staticClass:
                              "px-0 py-3 bg-gray-100 border-radius-lg p-6 mb-6",
                          },
                          [
                            _c("v-list-item-content", { staticClass: "px-6" }, [
                              _c("div", { staticClass: "d-flex flex-column" }, [
                                _c(
                                  "h6",
                                  {
                                    staticClass:
                                      "mb-3 text-sm text-typo font-weight-bold",
                                  },
                                  [_vm._v(" " + _vm._s(billing.name) + " ")]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "mb-2 text-xs text-body" },
                                  [
                                    _vm._v("Company Name: "),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-dark font-weight-bold ms-sm-2",
                                      },
                                      [_vm._v(_vm._s(billing.company))]
                                    ),
                                  ]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "mb-2 text-xs text-body" },
                                  [
                                    _vm._v("Email Address: "),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-dark ms-sm-2 font-weight-bold",
                                      },
                                      [_vm._v(_vm._s(billing.email))]
                                    ),
                                  ]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "text-xs text-body" },
                                  [
                                    _vm._v("VAT Number: "),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-dark ms-sm-2 font-weight-bold",
                                      },
                                      [_vm._v(_vm._s(billing.vat))]
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                            _c(
                              "v-list-item-content",
                              { staticClass: "py-0 text-end" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex align-center text-sm text-body",
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass:
                                          "\n                      text-danger\n                      font-weight-bolder\n                      py-6\n                      px-5\n                      shadow-0\n                      ms-auto\n                    ",
                                        attrs: {
                                          ripple: false,
                                          color: "transparent",
                                          small: "",
                                          simple: "",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass:
                                              "me-2 material-icons-round",
                                            attrs: { size: "12" },
                                          },
                                          [_vm._v("delete")]
                                        ),
                                        _vm._v(" Delete "),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass:
                                          "text-dark font-weight-bolder py-6 px-5 shadow-0",
                                        attrs: {
                                          ripple: false,
                                          color: "transparent",
                                          small: "",
                                          simple: "",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass:
                                              "me-2 material-icons-round",
                                            attrs: { size: "12" },
                                          },
                                          [_vm._v("edit")]
                                        ),
                                        _vm._v(" Edit "),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { md: "5" } },
            [
              _c(
                "v-card",
                { staticClass: "card-shadow border-radius-xl h-100" },
                [
                  _c("div", { staticClass: "px-4 pt-5 d-flex align-center" }, [
                    _c(
                      "h6",
                      {
                        staticClass: "mb-0 text-typo text-h6 font-weight-bold",
                      },
                      [_vm._v(" Your Transaction's ")]
                    ),
                    _c(
                      "div",
                      { staticClass: "text-body ms-auto" },
                      [
                        _c(
                          "v-icon",
                          { staticClass: "text-body", attrs: { size: "16" } },
                          [_vm._v("far fa-calendar-alt me-2")]
                        ),
                        _c("small", [_vm._v("23 - 30 March 2020")]),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "px-4 pt-6" },
                    [
                      _c(
                        "h6",
                        {
                          staticClass:
                            "text-uppercase text-body text-xs font-weight-bolder mb-3",
                        },
                        [_vm._v(" Newest ")]
                      ),
                      _vm._l(_vm.transactions1, function (transaction) {
                        return _c(
                          "div",
                          { key: transaction.title },
                          [
                            _c(
                              "v-list-item",
                              {
                                key: transaction.title,
                                staticClass: "px-0 py-3",
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "me-4",
                                    attrs: {
                                      ripple: false,
                                      icon: "",
                                      outlined: "",
                                      rounded: "",
                                      color: transaction.color,
                                      width: "34px",
                                      height: "34px",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "material-icons-round",
                                        attrs: { size: "18" },
                                      },
                                      [_vm._v(_vm._s(transaction.icon))]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-item-content",
                                  { staticClass: "py-0" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "d-flex flex-column" },
                                      [
                                        _c(
                                          "h6",
                                          {
                                            staticClass:
                                              "mb-1 text-typo text-sm font-weight-bold",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(transaction.title) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "text-xs text-body" },
                                          [_vm._v(_vm._s(transaction.date))]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "v-list-item-content",
                                  { staticClass: "py-0 text-end" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-sm font-weight-bold text-gradient",
                                        class: transaction.gradient,
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(transaction.value) + " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                      _c(
                        "h6",
                        {
                          staticClass:
                            "\n              text-uppercase text-body text-xs\n              font-weight-bolder\n              mt-4\n              mb-3\n            ",
                        },
                        [_vm._v(" Yesterday ")]
                      ),
                      _vm._l(_vm.transactions2, function (transaction) {
                        return _c(
                          "div",
                          { key: transaction.title },
                          [
                            _c(
                              "v-list-item",
                              {
                                key: transaction.title,
                                staticClass: "px-0 py-3",
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "me-4",
                                    attrs: {
                                      ripple: false,
                                      icon: "",
                                      outlined: "",
                                      rounded: "",
                                      color: transaction.color,
                                      width: "34px",
                                      height: "34px",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "material-icons-round",
                                        attrs: { size: "18" },
                                      },
                                      [_vm._v(_vm._s(transaction.icon))]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-item-content",
                                  { staticClass: "py-0" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "d-flex flex-column" },
                                      [
                                        _c(
                                          "h6",
                                          {
                                            staticClass:
                                              "mb-1 text-typo text-sm font-weight-bold",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(transaction.title) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "text-xs text-body" },
                                          [_vm._v(_vm._s(transaction.date))]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "v-list-item-content",
                                  { staticClass: "py-0 text-end" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-sm font-weight-bold text-gradient",
                                        class: transaction.gradient,
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(transaction.value) + " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }