var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "card-shadow border-radius-xl z-index-2" },
    [
      _c(
        "div",
        { staticClass: "px-4 pt-2 pb-0 d-flex" },
        [
          _c(
            "v-avatar",
            {
              staticClass: "shadow",
              attrs: {
                color:
                  "bg-gradient-warning shadow-warning border-radius-xl mt-n6",
                height: "64",
                width: "64",
              },
            },
            [
              _c(
                "v-icon",
                {
                  staticClass: "material-icons-round text-white",
                  attrs: { size: "24" },
                },
                [_vm._v("donut_small")]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "ms-4" }, [
            _c("h6", { staticClass: "text-h6 text-typo font-weight-bold" }, [
              _vm._v("Pie chart"),
            ]),
            _c(
              "p",
              { staticClass: "font-weight-light text-secondary text-sm" },
              [_vm._v(" Analytics Insights ")]
            ),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "card-padding py-0 d-flex" }, [
        _c("div", { staticClass: "chart w-50 pb-4" }, [
          _c("canvas", {
            staticClass: "chart-canvas",
            attrs: { id: "pie-chart", height: "300" },
          }),
        ]),
        _c(
          "div",
          { staticClass: "w-50 ms-auto" },
          [
            _c(
              "div",
              { staticClass: "mt-2 mb-3 ms-10" },
              [
                _c("v-badge", {
                  attrs: {
                    bottom: "",
                    bordered: "",
                    color: "#1a73e8",
                    dot: "",
                    "offset-x": "4",
                    "offset-y": "9",
                  },
                }),
                _c("span", { staticClass: "text-dark text-sm ms-3" }, [
                  _vm._v("Facebook"),
                ]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "mt-2 mb-3 ms-10" },
              [
                _c("v-badge", {
                  attrs: {
                    bottom: "",
                    bordered: "",
                    color: "#e91e63",
                    dot: "",
                    "offset-x": "4",
                    "offset-y": "9",
                  },
                }),
                _c("span", { staticClass: "text-dark text-sm ms-3" }, [
                  _vm._v("Direct"),
                ]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "mt-2 mb-3 ms-10" },
              [
                _c("v-badge", {
                  attrs: {
                    bottom: "",
                    bordered: "",
                    color: "#344767",
                    dot: "",
                    "offset-x": "4",
                    "offset-y": "9",
                  },
                }),
                _c("span", { staticClass: "text-dark text-sm ms-3" }, [
                  _vm._v("Organic"),
                ]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "mt-2 mb-3 ms-10" },
              [
                _c("v-badge", {
                  attrs: {
                    bottom: "",
                    bordered: "",
                    color: "#7b809a",
                    dot: "",
                    "offset-x": "4",
                    "offset-y": "9",
                  },
                }),
                _c("span", { staticClass: "text-dark text-sm ms-3" }, [
                  _vm._v("Referral"),
                ]),
              ],
              1
            ),
            _c(
              "p",
              {
                staticClass:
                  "text-sm font-weight-light text-body mb-3 mt-5 w-80 ms-10",
              },
              [
                _vm._v(" More than "),
                _c("span", { staticClass: "font-weight-bold" }, [
                  _vm._v("1,200,000"),
                ]),
                _vm._v(" sales are made using referral marketing, and "),
                _c("span", { staticClass: "font-weight-bold" }, [
                  _vm._v("700,000"),
                ]),
                _vm._v(" are from social media. "),
              ]
            ),
            _c(
              "v-btn",
              {
                staticClass:
                  "\n          font-weight-bold\n          text-white\n          bg-gradient-secondary\n          py-5\n          px-6\n          my-auto\n          ms-10\n        ",
                attrs: { elevation: 0, small: "" },
              },
              [_vm._v(" Read more ")]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }