var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "align-center d-flex h-100 mt-md-n16" },
    [
      _c(
        "v-col",
        { staticClass: "mx-auto", attrs: { lg: "4", md: "7" } },
        [
          _c(
            "v-card",
            { staticClass: "card-shadow border-radius-xl py-1" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "\n          card-padding\n          text-center\n          mt-n7\n          bg-gradient-primary\n          shadow-primary\n          border-radius-lg\n          mx-4\n        ",
                },
                [
                  _c(
                    "h4",
                    {
                      staticClass: "text-h4 font-weight-bolder text-white mb-2",
                    },
                    [_vm._v("Join us today")]
                  ),
                  _c(
                    "p",
                    {
                      staticClass: "mb-1 text-white font-weight-light text-sm",
                    },
                    [_vm._v(" Enter your email and password to register ")]
                  ),
                ]
              ),
              _c(
                "v-form",
                {
                  ref: "form",
                  staticClass: "card-padding text-center",
                  attrs: { "lazy-validation": "" },
                  model: {
                    value: _vm.valid,
                    callback: function ($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid",
                  },
                },
                [
                  _c("v-text-field", {
                    staticClass: "font-size-input input-style mb-5",
                    attrs: {
                      label: "Name",
                      color: "#e91e63",
                      outlined: "",
                      dense: "",
                      rules: _vm.nameRules,
                    },
                    on: { blur: _vm.validateName },
                    model: {
                      value: _vm.name,
                      callback: function ($$v) {
                        _vm.name = $$v
                      },
                      expression: "name",
                    },
                  }),
                  _c("v-text-field", {
                    staticClass: "font-size-input input-style mb-5",
                    attrs: {
                      label: "Customer ID",
                      color: "#e91e63",
                      required: "",
                      outlined: "",
                      dense: "",
                      rules: [_vm.rules.required],
                    },
                    on: { blur: _vm.validateCustomer },
                    model: {
                      value: _vm.customerId,
                      callback: function ($$v) {
                        _vm.customerId = $$v
                      },
                      expression: "customerId",
                    },
                  }),
                  _c("v-text-field", {
                    staticClass: "font-size-input input-style mb-5",
                    attrs: {
                      label: "Email",
                      color: "#e91e63",
                      outlined: "",
                      dense: "",
                      required: "",
                      rules: _vm.emailRules,
                    },
                    on: { blur: _vm.validateEmail },
                    model: {
                      value: _vm.email,
                      callback: function ($$v) {
                        _vm.email = $$v
                      },
                      expression: "email",
                    },
                  }),
                  _c("v-text-field", {
                    staticClass: "font-size-input input-style mb-5",
                    attrs: {
                      label: "Password",
                      color: "#e91e63",
                      outlined: "",
                      dense: "",
                      "append-icon": _vm.show1 ? "mdi-eye" : "mdi-eye-off",
                      rules: _vm.passwordRules,
                      type: _vm.show1 ? "text" : "password",
                      required: "",
                    },
                    on: {
                      "click:append": function ($event) {
                        _vm.show1 = !_vm.show1
                      },
                      blur: _vm.validatePassword,
                    },
                    model: {
                      value: _vm.password,
                      callback: function ($$v) {
                        _vm.password = $$v
                      },
                      expression: "password",
                    },
                  }),
                  _c("v-checkbox", {
                    staticClass: "ma-0 checkbox-custom checkbox-thinner",
                    attrs: {
                      color: "#141727",
                      rules: [_vm.rules.checkboxRequired],
                    },
                    on: { change: _vm.validateCheckbox },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c(
                              "span",
                              { staticClass: "text-body text-sm ls-0" },
                              [
                                _vm._v(" I agree to the "),
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "text-dark font-weight-bolder text-decoration-none",
                                    attrs: { href: "javascript:;" },
                                  },
                                  [_vm._v(" Terms and Conditions ")]
                                ),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.checkbox,
                      callback: function ($$v) {
                        _vm.checkbox = $$v
                      },
                      expression: "checkbox",
                    },
                  }),
                  _c(
                    "v-btn",
                    {
                      staticClass:
                        "\n            font-weight-bold\n            text-uppercase\n            btn-default\n            bg-gradient-primary\n            py-2\n            px-6\n            me-2\n            mt-6\n            mb-2\n            w-100\n          ",
                      attrs: {
                        elevation: "0",
                        ripple: false,
                        height: "43",
                        color: "#5e72e4",
                        small: "",
                        disabled: !_vm.valid || !_vm.checkbox,
                      },
                      on: { click: _vm.submitForm },
                    },
                    [_vm._v(" Create account ")]
                  ),
                  _c(
                    "p",
                    { staticClass: "text-body font-weight-light mt-3 mb-0" },
                    [
                      _vm._v(" Already have an account? "),
                      _c(
                        "a",
                        {
                          staticClass:
                            "text-primary text-decoration-none font-weight-bolder",
                          attrs: { href: "/sign-in" },
                        },
                        [_vm._v(" Sign in ")]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }