var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "card-shadow border-radius-xl z-index-2" },
    [
      _c(
        "div",
        { staticClass: "px-4 pt-2 pb-0 d-flex" },
        [
          _c(
            "v-avatar",
            {
              staticClass: "shadow",
              attrs: {
                color:
                  "bg-gradient-primary shadow-primary border-radius-xl mt-n6",
                height: "64",
                width: "64",
              },
            },
            [
              _c(
                "v-icon",
                {
                  staticClass: "material-icons-round text-white",
                  attrs: { size: "24" },
                },
                [_vm._v("multiline_chart")]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "ms-4" }, [
            _c("h6", { staticClass: "text-h6 text-typo font-weight-bold" }, [
              _vm._v("Bubble chart"),
            ]),
            _c(
              "p",
              { staticClass: "font-weight-light text-secondary text-sm" },
              [_vm._v(" Users divided by regions ")]
            ),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "card-padding pt-0 px-4" }, [
        _c("div", { staticClass: "chart" }, [
          _c("canvas", {
            staticClass: "chart-canvas",
            attrs: { id: "bubble-chart", height: "145" },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }