var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "py-8 px-6", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "card-shadow border-radius-xl" },
                    [
                      _c(
                        "div",
                        { staticClass: "card-padding" },
                        [
                          _c(
                            "h5",
                            {
                              staticClass:
                                "text-h5 text-typo font-weight-bold mb-6",
                            },
                            [_vm._v(" Product Details ")]
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "text-center",
                                  attrs: { lg: "5", md: "6" },
                                },
                                [
                                  _c("v-img", {
                                    staticClass:
                                      "w-100 border-radius-lg shadow-lg mx-auto",
                                    attrs: {
                                      src: require("../../../assets/img/products/product-details-1.jpg"),
                                    },
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "mt-8 overflow-scroll" },
                                    [
                                      _c("Photoswipe", [
                                        _c("img", {
                                          directives: [
                                            {
                                              name: "pswp",
                                              rawName: "v-pswp",
                                              value: require("@/assets/img/products/product-details-2.jpg"),
                                              expression:
                                                "\n                        require('@/assets/img/products/product-details-2.jpg')\n                      ",
                                            },
                                          ],
                                          staticClass: "me-2",
                                          attrs: {
                                            src: require("../../../assets/img/products/product-details-2.jpg"),
                                          },
                                        }),
                                        _c("img", {
                                          directives: [
                                            {
                                              name: "pswp",
                                              rawName: "v-pswp",
                                              value: require("@/assets/img/products/product-details-3.jpg"),
                                              expression:
                                                "\n                        require('@/assets/img/products/product-details-3.jpg')\n                      ",
                                            },
                                          ],
                                          staticClass: "me-2",
                                          attrs: {
                                            src: require("../../../assets/img/products/product-details-3.jpg"),
                                          },
                                        }),
                                        _c("img", {
                                          directives: [
                                            {
                                              name: "pswp",
                                              rawName: "v-pswp",
                                              value: require("@/assets/img/products/product-details-4.jpg"),
                                              expression:
                                                "\n                        require('@/assets/img/products/product-details-4.jpg')\n                      ",
                                            },
                                          ],
                                          staticClass: "me-2",
                                          attrs: {
                                            src: require("../../../assets/img/products/product-details-4.jpg"),
                                          },
                                        }),
                                        _c("img", {
                                          directives: [
                                            {
                                              name: "pswp",
                                              rawName: "v-pswp",
                                              value: require("@/assets/img/products/product-details-5.jpg"),
                                              expression:
                                                "\n                        require('@/assets/img/products/product-details-5.jpg')\n                      ",
                                            },
                                          ],
                                          staticClass: "me-2",
                                          attrs: {
                                            src: require("../../../assets/img/products/product-details-5.jpg"),
                                          },
                                        }),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { staticClass: "mx-auto", attrs: { lg: "5" } },
                                [
                                  _c(
                                    "h3",
                                    {
                                      staticClass:
                                        "text-h3 text-typo font-weight-bold mt-lg-0 mt-4 mb-2",
                                    },
                                    [_vm._v(" Minimal Bar Stool ")]
                                  ),
                                  _c("div", { staticClass: "rating" }, [
                                    _c(
                                      "i",
                                      {
                                        staticClass:
                                          "material-icons-round text-lg text-body",
                                      },
                                      [_vm._v("grade")]
                                    ),
                                    _c(
                                      "i",
                                      {
                                        staticClass:
                                          "material-icons-round text-lg text-body ms-1",
                                      },
                                      [_vm._v("grade")]
                                    ),
                                    _c(
                                      "i",
                                      {
                                        staticClass:
                                          "material-icons-round text-lg text-body ms-1",
                                      },
                                      [_vm._v("grade")]
                                    ),
                                    _c(
                                      "i",
                                      {
                                        staticClass:
                                          "material-icons-round text-lg text-body ms-1",
                                      },
                                      [_vm._v("grade")]
                                    ),
                                    _c(
                                      "i",
                                      {
                                        staticClass:
                                          "material-icons-round text-lg text-body ms-1",
                                      },
                                      [_vm._v("star_outline")]
                                    ),
                                  ]),
                                  _c("br"),
                                  _c(
                                    "h6",
                                    {
                                      staticClass:
                                        "text-h6 text-typo mb-0 mt-4 font-weight-bold",
                                    },
                                    [_vm._v(" Price ")]
                                  ),
                                  _c(
                                    "h5",
                                    {
                                      staticClass:
                                        "text-h5 text-typo mb-1 font-weight-bold",
                                    },
                                    [_vm._v(" $1,419 ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "\n                    border-radius-md\n                    font-weight-bolder\n                    px-3\n                    py-3\n                    badge-font-size\n                    ms-auto\n                    text-success\n                  ",
                                      attrs: {
                                        elevation: "0",
                                        small: "",
                                        ripple: false,
                                        height: "21",
                                        color: "#bce2be",
                                      },
                                    },
                                    [_vm._v("IN STOCK")]
                                  ),
                                  _c("br"),
                                  _c("br"),
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "text-sm text-body ms-1 mt-6",
                                    },
                                    [_vm._v("Description")]
                                  ),
                                  _c(
                                    "ul",
                                    {
                                      staticClass:
                                        "text-body font-weight-light mt-2",
                                    },
                                    [
                                      _c("li", [
                                        _vm._v(
                                          " The most beautiful curves of this swivel stool adds an elegant touch to any environment "
                                        ),
                                      ]),
                                      _c("li", [
                                        _vm._v(
                                          " Memory swivel seat returns to original seat position "
                                        ),
                                      ]),
                                      _c("li", [
                                        _vm._v(
                                          " Comfortable integrated layered chair seat cushion design "
                                        ),
                                      ]),
                                      _c("li", [
                                        _vm._v(
                                          "Fully assembled! No assembly required"
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "v-row",
                                    { staticClass: "mt-6" },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { lg: "5" } },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "text-sm text-body mb-0",
                                            },
                                            [_vm._v("Frame Material")]
                                          ),
                                          _c("v-select", {
                                            staticClass:
                                              "\n                        font-size-input\n                        input-style\n                        placeholder-light\n                        border-radius-md\n                        select-style\n                        mt-0\n                      ",
                                            attrs: {
                                              items: _vm.materials,
                                              value: "Wood",
                                              color: "#e91e63",
                                              height: "36",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { lg: "5" } },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "text-sm text-body mb-0",
                                            },
                                            [_vm._v("Color")]
                                          ),
                                          _c("v-select", {
                                            staticClass:
                                              "\n                        font-size-input\n                        input-style\n                        placeholder-light\n                        border-radius-md\n                        select-style\n                        mt-0\n                      ",
                                            attrs: {
                                              items: _vm.colors,
                                              value: "Black",
                                              color: "#e91e63",
                                              height: "36",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { lg: "2" } },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "text-sm text-body mb-0",
                                            },
                                            [_vm._v("Quantity")]
                                          ),
                                          _c("v-select", {
                                            staticClass:
                                              "\n                        font-size-input\n                        input-style\n                        placeholder-light\n                        border-radius-md\n                        select-style\n                        mt-0\n                      ",
                                            attrs: {
                                              items: _vm.numbers,
                                              value: "1",
                                              color: "#e91e63",
                                              height: "36",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { staticClass: "mt-0" },
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "\n                        font-weight-bold\n                        text-uppercase\n                        btn-default\n                        bg-gradient-primary\n                        shadow-primary\n                        py-2\n                        px-6\n                        me-2\n                      ",
                                              attrs: {
                                                elevation: "0",
                                                ripple: false,
                                                height: "43",
                                                color: "#5e72e4",
                                                small: "",
                                              },
                                            },
                                            [_vm._v("Add to cart")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { staticClass: "mt-8" },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "h5",
                                    {
                                      staticClass:
                                        "text-h5 text-typo font-weight-bold ms-4",
                                    },
                                    [_vm._v(" Other Products ")]
                                  ),
                                  _c("table-products"),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }