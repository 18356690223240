var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "px-6 py-6", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { staticClass: "mb-6" },
            [
              _c("v-col", { attrs: { md: "6" } }, [
                _c(
                  "h5",
                  { staticClass: "text-h5 text-typo font-weight-bold mb-0" },
                  [_vm._v("Charts")]
                ),
                _c(
                  "p",
                  { staticClass: "text-sm text-body font-weight-light mb-0" },
                  [
                    _vm._v(
                      " Charts on this page use Chart.js - Simple yet flexible JavaScript charting for designers & developers. "
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mb-6" },
            [
              _c(
                "v-col",
                { staticClass: "mb-lg-0 mb-6", attrs: { md: "6", cols: "12" } },
                [_c("line-chart")],
                1
              ),
              _c(
                "v-col",
                { attrs: { md: "6", cols: "12" } },
                [_c("line-chart-without-dots")],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mb-6" },
            [
              _c(
                "v-col",
                { staticClass: "mb-lg-0 mb-6", attrs: { md: "6", cols: "12" } },
                [_c("bar-chart")],
                1
              ),
              _c(
                "v-col",
                { attrs: { md: "6", cols: "12" } },
                [_c("bar-chart-horizontal")],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mb-6" },
            [
              _c(
                "v-col",
                { staticClass: "mb-lg-0 mb-6", attrs: { md: "6", cols: "12" } },
                [_c("mixed-chart")],
                1
              ),
              _c(
                "v-col",
                { attrs: { md: "6", cols: "12" } },
                [_c("bubble-chart")],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mb-6" },
            [
              _c(
                "v-col",
                { staticClass: "mb-lg-0 mb-6", attrs: { md: "6", cols: "12" } },
                [_c("doughnut-chart")],
                1
              ),
              _c(
                "v-col",
                { attrs: { md: "6", cols: "12" } },
                [_c("pie-chart")],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "mb-lg-0 mb-6", attrs: { md: "6", cols: "12" } },
                [_c("radar-chart")],
                1
              ),
              _c(
                "v-col",
                { attrs: { md: "6", cols: "12" } },
                [_c("polar-chart")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }