var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "px-6 py-6", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "text-center mx-auto", attrs: { cols: "12" } },
                [
                  _c(
                    "h3",
                    { staticClass: "text-h3 text-typo font-weight-bold mt-9" },
                    [_vm._v(" Build Your Profile ")]
                  ),
                  _c(
                    "h5",
                    {
                      staticClass: "text-h5 text-secondary font-weight-normal",
                    },
                    [
                      _vm._v(
                        " This information will let us know more about you. "
                      ),
                    ]
                  ),
                ]
              ),
              _c(
                "v-col",
                { staticClass: "mx-auto", attrs: { lg: "8" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "shadow border-radius-xl my-10 mx-auto" },
                    [
                      _c(
                        "v-stepper",
                        {
                          staticClass: "bg-transparent overflow-visible",
                          attrs: { "alt-labels": "", elevation: "0" },
                          model: {
                            value: _vm.e1,
                            callback: function ($$v) {
                              _vm.e1 = $$v
                            },
                            expression: "e1",
                          },
                        },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "mx-auto pt-1",
                                  attrs: { cols: "12" },
                                },
                                [
                                  _c(
                                    "v-stepper-header",
                                    {
                                      staticClass:
                                        "\n                    bg-gradient-primary\n                    shadow-primary\n                    border-radius-lg\n                    mx-4\n                    mt-n4\n                  ",
                                    },
                                    [
                                      _c(
                                        "v-stepper-step",
                                        {
                                          attrs: {
                                            step: "1",
                                            color: "#344767",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "\n                        text-white\n                        opacity-7\n                        text-xs\n                        font-weight-normal\n                        text-uppercase\n                      ",
                                            },
                                            [_vm._v("About")]
                                          ),
                                        ]
                                      ),
                                      _c("v-divider"),
                                      _c(
                                        "v-stepper-step",
                                        {
                                          attrs: {
                                            step: "2",
                                            color: "#344767",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "\n                        text-white\n                        opacity-7\n                        text-xs\n                        font-weight-normal\n                        text-uppercase\n                      ",
                                            },
                                            [_vm._v("Account")]
                                          ),
                                        ]
                                      ),
                                      _c("v-divider"),
                                      _c(
                                        "v-stepper-step",
                                        {
                                          attrs: {
                                            step: "3",
                                            color: "#344767",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "\n                        text-white\n                        opacity-7\n                        text-xs\n                        font-weight-normal\n                        text-uppercase\n                      ",
                                            },
                                            [_vm._v("Address")]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "mx-auto",
                                  attrs: { cols: "12" },
                                },
                                [
                                  _c(
                                    "v-stepper-items",
                                    {
                                      staticClass:
                                        "border-radius-xl overflow-hidden mt-5",
                                    },
                                    [
                                      _c(
                                        "v-stepper-content",
                                        {
                                          staticClass:
                                            "bg-white border-radius-xl px-4 pt-4",
                                          attrs: { step: "1" },
                                        },
                                        [
                                          _c("v-card", [
                                            _c(
                                              "div",
                                              [
                                                _c(
                                                  "v-row",
                                                  {
                                                    staticClass: "text-center",
                                                  },
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticClass: "mx-auto",
                                                        attrs: { cols: "10" },
                                                      },
                                                      [
                                                        _c(
                                                          "h5",
                                                          {
                                                            staticClass:
                                                              "\n                                text-h5 text-typo\n                                font-weight-normal\n                                mb-2\n                              ",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Let's start with the basic information "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "text-body font-weight-light",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " One thing I love about the later sunsets is the chance to go for a walk through the neighborhood woods before dinner "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-row",
                                                  { staticClass: "mt-2" },
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticClass:
                                                          "text-center",
                                                        attrs: {
                                                          sm: "4",
                                                          cols: "12",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-badge",
                                                          {
                                                            attrs: {
                                                              bottom: "",
                                                              color:
                                                                "bg-gradient-primary accent-4 text-white py-0 text-xxs border-radius-md shadow material-icons-round",
                                                              "offset-x": "12",
                                                              "offset-y": "12",
                                                              icon: "edit",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-avatar",
                                                              {
                                                                attrs: {
                                                                  rounded: "",
                                                                  width: "110",
                                                                  height: "110",
                                                                },
                                                              },
                                                              [
                                                                _c("img", {
                                                                  staticClass:
                                                                    "border-radius-lg",
                                                                  attrs: {
                                                                    src: require("@/assets/img/team-2.jpg"),
                                                                    alt: "Avatar",
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          sm: "8",
                                                          cols: "12",
                                                        },
                                                      },
                                                      [
                                                        _c("v-text-field", {
                                                          staticClass:
                                                            "font-size-input input-style",
                                                          attrs: {
                                                            label: "First Name",
                                                            color: "#e91e63",
                                                            required: "",
                                                          },
                                                        }),
                                                        _c("v-text-field", {
                                                          staticClass:
                                                            "font-size-input input-style",
                                                          attrs: {
                                                            label: "Last Name",
                                                            color: "#e91e63",
                                                            required: "",
                                                          },
                                                        }),
                                                        _c("v-text-field", {
                                                          staticClass:
                                                            "font-size-input input-style",
                                                          attrs: {
                                                            label:
                                                              "Email Address",
                                                            color: "#e91e63",
                                                            required: "",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "text-end" },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    staticClass:
                                                      "\n                            font-weight-bold\n                            text-xs\n                            btn-default\n                            bg-gradient-default\n                            py-5\n                            px-6\n                            mt-2\n                            mb-2\n                            me-2\n                          ",
                                                    attrs: {
                                                      elevation: 0,
                                                      color: "primary",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.e1 = 2
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" Next ")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-stepper-content",
                                        {
                                          staticClass:
                                            "bg-white border-radius-xl px-4 pt-4",
                                          attrs: { step: "2" },
                                        },
                                        [
                                          _c("v-card", [
                                            _c(
                                              "div",
                                              [
                                                _c(
                                                  "v-row",
                                                  {
                                                    staticClass: "text-center",
                                                  },
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticClass: "mx-auto",
                                                        attrs: { cols: "10" },
                                                      },
                                                      [
                                                        _c(
                                                          "h5",
                                                          {
                                                            staticClass:
                                                              "\n                                text-h5 text-typo\n                                font-weight-normal\n                                mb-2\n                              ",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " What are you doing? (checkboxes) "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "text-body font-weight-light",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Give us more details about you. What do you enjoy doing in your spare time? "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-row",
                                                  { staticClass: "mt-2" },
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticClass:
                                                          "text-center mx-auto",
                                                        attrs: { cols: "12" },
                                                      },
                                                      [
                                                        _c(
                                                          "v-btn-toggle",
                                                          {
                                                            staticClass:
                                                              "d-sm-inline-flex d-block",
                                                            attrs: {
                                                              tile: "",
                                                              color:
                                                                "deep-purple accent-3 mx-auto",
                                                              group: "",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.toggle_exclusive,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.toggle_exclusive =
                                                                    $$v
                                                                },
                                                              expression:
                                                                "toggle_exclusive",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                staticClass:
                                                                  "\n                                  border\n                                  border-2\n                                  border-secondary\n                                  border-radius-md\n                                  pa-15\n                                  mb-12\n                                ",
                                                                attrs: {
                                                                  value: "left",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    staticClass:
                                                                      "material-icons-round",
                                                                    attrs: {
                                                                      size: "32",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " brush "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "mt-16 position-absolute",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "h6",
                                                                      {
                                                                        staticClass:
                                                                          "\n                                      text-h6 text-typo\n                                      font-weight-bold\n                                      mb-n16\n                                      mt-12\n                                      text-capitalize\n                                    ",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " Design "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                staticClass:
                                                                  "\n                                  border\n                                  border-2\n                                  border-secondary\n                                  border-radius-md\n                                  pa-15\n                                  mb-12\n                                ",
                                                                attrs: {
                                                                  value: "left",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    staticClass:
                                                                      "material-icons-round",
                                                                    attrs: {
                                                                      size: "32",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " integration_instructions "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "mt-16 position-absolute",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "h6",
                                                                      {
                                                                        staticClass:
                                                                          "\n                                      text-h6 text-typo\n                                      font-weight-bold\n                                      mb-n16\n                                      mt-12\n                                      text-capitalize\n                                    ",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " Code "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                staticClass:
                                                                  "\n                                  border\n                                  border-2\n                                  border-secondary\n                                  border-radius-md\n                                  pa-15\n                                ",
                                                                attrs: {
                                                                  value: "left",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    staticClass:
                                                                      "material-icons-round",
                                                                    attrs: {
                                                                      size: "32",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " developer_mode "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "mt-16 position-absolute",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "h6",
                                                                      {
                                                                        staticClass:
                                                                          "\n                                      text-h6 text-typo\n                                      font-weight-bold\n                                      mb-n16\n                                      mt-12\n                                      text-capitalize\n                                    ",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " Develop "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "d-flex mt-10" },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    staticClass:
                                                      "\n                            font-weight-bold\n                            text-xs text-dark\n                            shadow-none\n                            bg-transparent\n                            btn-outline-secondary\n                            py-5\n                            px-6\n                            mt-6\n                            mb-2\n                            ms-2\n                          ",
                                                    attrs: { elevation: 0 },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.e1 = 1
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" Prev ")]
                                                ),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    staticClass:
                                                      "\n                            font-weight-bold\n                            text-xs\n                            btn-default\n                            bg-gradient-default\n                            py-5\n                            px-6\n                            mt-6\n                            mb-2\n                            me-2\n                            ms-auto\n                          ",
                                                    attrs: {
                                                      elevation: 0,
                                                      color: "primary",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.e1 = 3
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" Next ")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-stepper-content",
                                        {
                                          staticClass:
                                            "bg-white border-radius-xl px-4 pt-4",
                                          attrs: { step: "3" },
                                        },
                                        [
                                          _c("v-card", [
                                            _c(
                                              "div",
                                              [
                                                _c(
                                                  "v-row",
                                                  {
                                                    staticClass: "text-center",
                                                  },
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticClass: "mx-auto",
                                                        attrs: { cols: "10" },
                                                      },
                                                      [
                                                        _c(
                                                          "h5",
                                                          {
                                                            staticClass:
                                                              "\n                                text-h5 text-typo\n                                font-weight-normal\n                                mb-2\n                              ",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Are you living in a nice area? "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "text-body font-weight-light",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " One thing I love about the later sunsets is the chance to go for a walk through the neighborhood woods before dinner "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-row",
                                                  { staticClass: "mt-2" },
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          md: "8",
                                                          cols: "12",
                                                        },
                                                      },
                                                      [
                                                        _c("v-text-field", {
                                                          staticClass:
                                                            "font-size-input input-style",
                                                          attrs: {
                                                            label:
                                                              "Street Name",
                                                            placeholder:
                                                              "Eg. Str. Creative Tim",
                                                            color: "#e91e63",
                                                            required: "",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          md: "4",
                                                          cols: "12",
                                                        },
                                                      },
                                                      [
                                                        _c("v-text-field", {
                                                          staticClass:
                                                            "font-size-input input-style",
                                                          attrs: {
                                                            label: "Street No",
                                                            placeholder:
                                                              "Eg. 321",
                                                            color: "#e91e63",
                                                            required: "",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-row",
                                                  { staticClass: "mt-2" },
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          md: "7",
                                                          cols: "12",
                                                        },
                                                      },
                                                      [
                                                        _c("v-text-field", {
                                                          staticClass:
                                                            "font-size-input input-style mt-0",
                                                          attrs: {
                                                            label: "City",
                                                            placeholder:
                                                              "Eg. San Francisco",
                                                            color: "#e91e63",
                                                            required: "",
                                                            "hide-details": "",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          md: "5",
                                                          cols: "12",
                                                        },
                                                      },
                                                      [
                                                        _c("v-select", {
                                                          staticClass:
                                                            "\n                                font-size-input\n                                input-style\n                                text-light-input\n                                placeholder-light\n                                select-style\n                                mb-0\n                              ",
                                                          attrs: {
                                                            items: _vm.country,
                                                            placeholder:
                                                              "Country",
                                                            value: "Argentina",
                                                            color: "#e91e63",
                                                            "single-line": "",
                                                            height: "28",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "d-flex" },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    staticClass:
                                                      "\n                            font-weight-bold\n                            text-xs text-dark\n                            btn-light\n                            shadow-none\n                            bg-transparent\n                            btn-outline-secondary\n                            py-5\n                            px-6\n                            mt-2\n                            mb-2\n                            ms-2\n                          ",
                                                    attrs: { elevation: 0 },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.e1 = 2
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" Prev ")]
                                                ),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    staticClass:
                                                      "\n                            font-weight-bold\n                            text-xs\n                            btn-default\n                            bg-gradient-default\n                            py-5\n                            px-6\n                            mt-2\n                            mb-2\n                            me-2\n                            ms-auto\n                          ",
                                                    attrs: {
                                                      elevation: 0,
                                                      color: "primary",
                                                    },
                                                  },
                                                  [_vm._v(" Finish ")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }