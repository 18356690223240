var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "py-6", attrs: { fluid: "" } },
    [
      _c("v-row", [_c("v-col", { attrs: { lg: "4", sm: "8" } })], 1),
      _c(
        "v-row",
        { staticClass: "px-4" },
        [
          _c(
            "v-col",
            { attrs: { lg: "3" } },
            [
              _c(
                "v-card",
                {
                  staticClass:
                    "card-shadow border-radius-xl position-sticky top-1",
                },
                [
                  _c(
                    "div",
                    { staticClass: "px-4 pt-3 pb-0" },
                    [
                      _c(
                        "v-list",
                        [
                          _c(
                            "v-list-item-group",
                            { staticClass: "border-radius-sm" },
                            _vm._l(_vm.menu, function (item) {
                              return _c(
                                "v-list-item",
                                {
                                  key: item.icon,
                                  staticClass:
                                    "px-3 py-1 border-radius-lg mb-2",
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass:
                                        "material-icons-round me-2 text-dark",
                                      attrs: { size: "18" },
                                    },
                                    [_vm._v(_vm._s(item.icon))]
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    { staticClass: "py-0" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { text: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.navigateToSection(
                                                item.section
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "d-flex flex-column",
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "text-dark text-sm",
                                                },
                                                [_vm._v(_vm._s(item.text))]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { lg: "9" } },
            [
              _c(
                "v-card",
                {
                  staticClass:
                    "card-shadow px-4 py-4 overflow-hidden border-radius-xl",
                  attrs: { id: "profile" },
                },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-avatar",
                            {
                              staticClass: "shadow rounded-circle",
                              attrs: { width: "74", height: "74" },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "text-h5 text-typo font-weight-bold",
                                },
                                [_vm._v(_vm._s(_vm.initials))]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "my-auto", attrs: { cols: "auto" } },
                        [
                          _c("div", { staticClass: "h-100" }, [
                            _c(
                              "h5",
                              {
                                staticClass:
                                  "mb-1 text-h5 text-typo font-weight-bold",
                              },
                              [_vm._v(" " + _vm._s(_vm.currentName) + " ")]
                            ),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "mb-0 font-weight-light text-body text-sm",
                              },
                              [_vm._v(" " + _vm._s(_vm.userRole) + " ")]
                            ),
                          ]),
                        ]
                      ),
                      _c("v-col", {
                        staticClass:
                          "my-sm-auto ms-sm-auto me-sm-0 mx-auto mt-3",
                        attrs: { lg: "4", md: "6" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("basic-info"),
              _c("app-settings"),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }