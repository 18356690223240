var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "quill mt-3",
      class: this.$route.name == "NewProduct" ? "h-100" : " ",
    },
    [
      _c("div", { attrs: { id: _vm.toolbarId } }, [_vm._m(0)]),
      _c("div", { ref: "editor", attrs: { id: _vm.editorId, name: _vm.name } }),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ql-formats" }, [
      _c("button", { staticClass: "ql-bold" }),
      _c("button", { staticClass: "ql-italic" }),
      _c("button", { staticClass: "ql-underline" }),
      _c("button", { staticClass: "ql-link" }),
      _c("button", { staticClass: "ql-blockquote" }),
      _c("button", { staticClass: "ql-code" }),
      _c("button", { staticClass: "ql-image" }),
      _c("button", {
        staticClass: "ql-list",
        attrs: { type: "button", value: "ordered" },
      }),
      _c("button", {
        staticClass: "ql-list",
        attrs: { type: "button", value: "bullet" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }