var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "card-shadow border-radius-xl mt-6",
      attrs: { id: "notifications" },
    },
    [
      _c("div", { staticClass: "px-6 pt-6" }, [
        _c("h5", { staticClass: "text-h5 font-weight-bold text-typo" }, [
          _vm._v("Notifications"),
        ]),
        _c("p", { staticClass: "text-sm text-body font-weight-light" }, [
          _vm._v(
            " Choose how you receive notifications. These notification settings apply to the things you’re watching. "
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "px-6 pb-6 pt-4" },
        [
          _c("v-simple-table", {
            staticClass: "table border-radius-xl",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function () {
                  return [
                    _c("thead", [
                      _c("tr", [
                        _c(
                          "th",
                          { staticClass: "ps-1", attrs: { colspan: "4" } },
                          [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "\n                  mb-0\n                  text-body text-capitalize text-md\n                  font-weight-light\n                ",
                              },
                              [_vm._v(" Activity ")]
                            ),
                          ]
                        ),
                        _c("th", { staticClass: "text-center" }, [
                          _c(
                            "p",
                            {
                              staticClass:
                                "\n                  mb-0\n                  text-body text-capitalize text-md\n                  font-weight-light\n                ",
                            },
                            [_vm._v(" Email ")]
                          ),
                        ]),
                        _c("th", { staticClass: "text-center" }, [
                          _c(
                            "p",
                            {
                              staticClass:
                                "\n                  mb-0\n                  text-body text-capitalize text-md\n                  font-weight-light\n                ",
                            },
                            [_vm._v(" Push ")]
                          ),
                        ]),
                        _c("th", { staticClass: "text-center" }, [
                          _c(
                            "p",
                            {
                              staticClass:
                                "\n                  mb-0\n                  text-body text-capitalize text-md\n                  font-weight-light\n                ",
                            },
                            [_vm._v(" SMS ")]
                          ),
                        ]),
                      ]),
                    ]),
                    _c(
                      "tbody",
                      _vm._l(_vm.notifications, function (item, i) {
                        return _c("tr", { key: item.title }, [
                          _c(
                            "td",
                            {
                              staticClass:
                                "text-sm text-secondary mb-0 py-3 ps-1",
                              class: {
                                "border-bottom":
                                  i != _vm.notifications.length - 1,
                              },
                              attrs: { colspan: "4" },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "text-dark d-block text-sm",
                                  attrs: { "v-if": item.title },
                                },
                                [_vm._v(_vm._s(item.title))]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "text-xs font-weight-light",
                                  attrs: { "v-if": item.description },
                                },
                                [_vm._v(_vm._s(item.description))]
                              ),
                              _c(
                                "p",
                                {
                                  staticClass: "text-sm font-weight-light mb-0",
                                  attrs: { "v-if": item.text },
                                },
                                [_vm._v(" " + _vm._s(item.text) + " ")]
                              ),
                            ]
                          ),
                          _c(
                            "td",
                            [
                              _c("v-switch", {
                                staticClass: "mt-0 pt-0 ms-8 switch",
                                attrs: { ripple: false, "hide-details": "" },
                                model: {
                                  value: item.switch1,
                                  callback: function ($$v) {
                                    _vm.$set(item, "switch1", $$v)
                                  },
                                  expression: "item.switch1",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            [
                              _c("v-switch", {
                                staticClass: "mt-0 pt-0 ms-5 switch",
                                attrs: { ripple: false, "hide-details": "" },
                                model: {
                                  value: item.switch2,
                                  callback: function ($$v) {
                                    _vm.$set(item, "switch2", $$v)
                                  },
                                  expression: "item.switch2",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            [
                              _c("v-switch", {
                                staticClass: "mt-0 pt-0 ms-5 switch",
                                attrs: { ripple: false, "hide-details": "" },
                                model: {
                                  value: item.switch3,
                                  callback: function ($$v) {
                                    _vm.$set(item, "switch3", $$v)
                                  },
                                  expression: "item.switch3",
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      }),
                      0
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }