<template>
<v-card class="card-shadow border-radius-xl mt-6" id="appsettings">
  <div class="px-6 py-6">
    <h5 class="text-h5 font-weight-bold text-typo">App Settings</h5>
    <p class="text-sm text-body font-weight-light">
      This section contains the application settings related to the site. Adjust these settings to configure the behavior and preferences of the application specific to your site.
    </p>
  </div>
  <div class="px-6 pb-6 pt-0 overflow-scroll">
    <v-row>
      <v-col cols="12">
        <h6 class="text-h6 font-weight-bold mb-4">High alert settings for the monitoring portal Dashboard</h6>
      </v-col>
      <v-col cols="12" md="4">
        <v-row align="center">
          <v-col cols="12">
            <v-text-field
              v-model="highThresholdSites"
              label="Threshold for Sites"
              readonly
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-slider
              v-model="highThresholdSites"
              min="0"
              max="100"
              @change="handleSliderChange('highThresholdSites')"
            ></v-slider>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="4">
        <v-row align="center">
          <v-col cols="12">
            <v-text-field
              v-model="highThresholdInverters"
              label="Threshold for Inverters"
              readonly
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-slider
              v-model="highThresholdInverters"
              min="0"
              max="100"
              @change="handleSliderChange('highThresholdInverters')"
            ></v-slider>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="4">
        <v-row align="center">
          <v-col cols="12">
            <v-text-field
              v-model="highThresholdInsite"
              label="Threshold for Insite Inverter Comparison"
              readonly
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-slider
              v-model="highThresholdInsite"
              min="0"
              max="100"
              @change="handleSliderChange('highThresholdInsite')"
            ></v-slider>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <h6 class="text-h6 font-weight-bold mb-4">Medium alert settings for the monitoring portal Dashboard</h6>
      </v-col>
      <v-col cols="12" md="4">
        <v-row align="center">
          <v-col cols="12">
            <v-text-field
              v-model="mediumThresholdSites"
              label="Threshold for Sites"
              readonly
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-slider
              v-model="mediumThresholdSites"
              min="0"
              max="100"
              @change="handleSliderChange('mediumThresholdSites')"
            ></v-slider>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="4">
        <v-row align="center">
          <v-col cols="12">
            <v-text-field
              v-model="mediumThresholdInverters"
              label="Threshold for Inverters"
              readonly
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-slider
              v-model="mediumThresholdInverters"
              min="0"
              max="100"
              @change="handleSliderChange('mediumThresholdInverters')"
            ></v-slider>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="4">
        <v-row align="center">
          <v-col cols="12">
            <v-text-field
              v-model="mediumThresholdInsite"
              label="Threshold for Insite Inverter Comparison"
              readonly
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-slider
              v-model="mediumThresholdInsite"
              min="0"
              max="100"
              @change="handleSliderChange('mediumThresholdInsite')"
            ></v-slider>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</v-card>
</template>
<script>
export default {
  name: "sessions",
  data() {
    return {
      highThresholdSites: 0,
      highThresholdInverters: 0,
      highThresholdInsite: 0,
      mediumThresholdSites: 0,
      mediumThresholdInverters: 0,
      mediumThresholdInsite: 0,
      sessions: [],
    };
  },
  created: function () {
    if(this.$store.state.alerts.highAlerts.length > 0) {
      this.$store.state.alerts.highAlerts.forEach(element => {
        this.highThresholdSites = element.highThresholdSites;
        this.highThresholdInverters = element.highThresholdInverters;
        this.highThresholdInsite = element.highThresholdInsite;
        console.log(element);
      });
    }
    if(this.$store.state.alerts.mediumAlerts.length > 0) {
      this.$store.state.alerts.mediumAlerts.forEach(element => {
        this.mediumThresholdSites = element.mediumThresholdSites;
        this.mediumThresholdInverters = element.mediumThresholdInverters;
        this.mediumThresholdInsite = element.mediumThresholdInsite;
        console.log(element);
      });
    }
  },
  methods: {
    handleSliderChange(sliderType) {
      // Perform the desired action based on the slider type
      console.log(`Slider "${sliderType}" changed:`, this[sliderType]);
      // build high alert obj and save it... 
      let hAlert = 
      [{
        'highThresholdSites': this.highThresholdSites,
        'highThresholdInverters': this.highThresholdInverters,
        'highThresholdInsite': this.highThresholdInsite,
      }];
      let mAlert = [
      {
        'mediumThresholdSites': this.mediumThresholdSites,
        'mediumThresholdInverters': this.mediumThresholdInverters,
        'mediumThresholdInsite': this.mediumThresholdInsite
      }];
      this.$store.dispatch('setHighAlerts', hAlert);
      this.$store.dispatch('setMediumAlerts', mAlert);
    },
  }
};
</script>
<style>

</style>